import { useNavigate, useParams } from 'react-router-dom';
import { FormLayout } from '../../shared/form-layout';
import { ResignDirectorForm } from './resign-director-form';

export const ResignDirector = () => {
  const navigate = useNavigate();
  const { id, directorId } = useParams<{ id: string; directorId: string }>();
  return (
    <FormLayout title="Resign director">
      <ResignDirectorForm
        id={directorId}
        companyId={id}
        onComplete={() => navigate(`/companies/${id}`)}
      />
    </FormLayout>
  );
};
