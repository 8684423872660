import useSWR from 'swr';
import { ShareAllocation } from '../../types';

export const useShareholderAllocations = (companyId: string, shareholderId: string) => {
  const { data, isLoading, mutate, error } = useSWR<{
    data: ShareAllocation[];
  }>(companyId ? `/companies/${companyId}/shareholders/${shareholderId}/share-allocations` : null);
  return {
    shareAllocations: data?.data,
    isLoading,
    error,
    mutate,
  };
};
