import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { validation } from '../../utils/validation';
import { DynamicForm, FieldType } from '../../shared/dynamic-form';
import { FormLoader } from '../../shared/form-loader';
import { Company } from '../../types';
import { useChange } from './use-change';
import { useChangeTypes } from '../../shared/use-change-types';
import { useActiveUser } from '../users/use-active-user';
import { useBasket } from '../../shared/use-basket';
import { ChangeTypeDescription } from '../../types/enums';

export function ChangeCompanyNameForm({
  company,
  nameChange,
  onComplete,
  onCreate,
}: {
  company?: Company | null;
  nameChange?: {
    FirstProposedName: string;
    SecondProposedName?: string;
    ThirdProposedName?: string;
    FourthProposedName?: string;
  };
  onComplete?: () => void;
  onCreate?: (NameChange: {
    FirstProposedName: string;
    SecondProposedName?: string;
    ThirdProposedName?: string;
    FourthProposedName?: string;
  }) => void;
}) {
  const isCreating = onCreate;
  const { store } = useChange();
  const { data: changeTypes } = useChangeTypes();
  const { claims } = useActiveUser();
  const { basket, isLoading, addProduct, mutate: mutateBasket } = useBasket();

  const nameChangeType = changeTypes?.data.find(
    (changeType) => changeType.Description === ChangeTypeDescription.Name
  );

  const schema = useMemo(() => {
    if (!company && !isCreating) return null;
    if (!nameChangeType) return null;
    if (isLoading) return null;

    return [
      {
        type: FieldType.Text,
        name: 'proposedName1',
        label: '1st Proposed Name',
        initialValue: nameChange?.FirstProposedName ?? '',
        validate: validation.companyName,
        required: true,
      },
      {
        type: FieldType.Text,
        name: 'proposedName2',
        label: '2nd Proposed Name',
        initialValue: nameChange?.SecondProposedName ?? '',
        validate: validation.companyName,
      },
      {
        type: FieldType.Text,
        name: 'proposedName3',
        label: '3rd Proposed Name',
        initialValue: nameChange?.ThirdProposedName ?? '',
        validate: validation.companyName,
      },
      {
        type: FieldType.Text,
        name: 'proposedName4',
        label: '4th Proposed Name',
        initialValue: nameChange?.FourthProposedName ?? '',
        validate: validation.companyName,
      },
    ];
  }, [company, nameChangeType, isLoading]);

  if (!schema) {
    return <FormLoader />;
  }

  return (
    <>
      <DynamicForm
        schema={schema}
        buttonText={!isCreating ? 'Save' : 'Next'}
        onSubmit={async (values) => {
          if (!isCreating) {
            try {
              const res = await store({
                ChangeTypeID: nameChangeType!.ID,
                CompanyID: company!.ID,
                DirectorID: claims!.id,
                Data: {
                  FirstProposedName: values.proposedName1,
                  SecondProposedName: values.proposedName2,
                  ThirdProposedName: values.proposedName3,
                  FourthProposedName: values.proposedName4,
                },
              });

              try {
                addProduct({
                  ChangeID: res.data.ID,
                  ProductType: 3,
                  Quantity: 1,
                });
              } catch (error) {
                notifications.show({
                  title: 'Error',
                  message: 'Failed to add name change to basket',
                  color: 'red',
                });
                return;
              } finally {
                mutateBasket();
              }
            } catch (error) {
              notifications.show({
                title: 'Error',
                message: 'Failed to submit company name change request',
                color: 'red',
              });
              return;
            }

            notifications.show({
              title: 'Success',
              message: 'Company name change request submitted',
              color: 'green',
            });
            if (onComplete) onComplete();
          } else {
            onCreate?.({
              FirstProposedName: values.proposedName1,
              SecondProposedName: values.proposedName2,
              ThirdProposedName: values.proposedName3,
              FourthProposedName: values.proposedName4,
            });
          }
        }}
      />
    </>
  );
}
