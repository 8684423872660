import { Anchor, Breadcrumbs, Text, Title } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';
import { Table } from '../../shared/table';
import { useCompany } from './use-company';
import { useCompanyShareSummary } from './use-company-share-summary';
import { ErrorAlert } from '../../shared/error-alert';

export function ShareSummary() {
  const { id } = useParams<{ id: string }>();
  const { company } = useCompany(id);
  const { shareSummary, isLoading, error } = useCompanyShareSummary(id);

  return (
    <>
      <Breadcrumbs mb="xl">
        <Anchor component={Link} to="/companies">
          Companies
        </Anchor>
        <Anchor component={Link} to={`/companies/${company?.ID}`}>
          {company?.Name ?? '-'}
        </Anchor>
        <Text>Share summary</Text>
      </Breadcrumbs>
      <Title order={3} mb="lg">
        Share summary
      </Title>
      {error ? (
        <ErrorAlert error={error} />
      ) : (
        <Table
          isLoading={isLoading}
          isEmpty={shareSummary?.ShareClasses?.length === 0}
          data={shareSummary?.ShareClasses}
          columns={[
            {
              key: 'Name',
              name: 'Name',
            },
            {
              key: 'ShareType',
              name: 'Type',
              render: (row) => row.ShareType.Description ?? '-',
            },
            {
              key: 'Description',
              name: 'Description',
            },
            {
              key: 'TotalShares',
              name: 'Total shares',
            },
            {
              key: 'ParValue',
              name: 'Per value',
            },
            {
              key: 'ShareValue',
              name: 'Share value',
            },
            {
              key: 'Premium',
              name: 'Premium',
            },
          ]}
        />
      )}
    </>
  );
}
