import { Box, Table } from '@mantine/core';
import { ReactNode } from 'react';

import classes from './table-wrapper.module.css';

export function TableWrapper({ children }: { children: ReactNode }) {
  return (
    <Box className={classes.wrapper}>
      <Table striped verticalSpacing="md">
        {children}
      </Table>
    </Box>
  );
}
