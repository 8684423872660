import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { useUser } from './use-user';
import { validation } from '../../utils/validation';
import { useUserAddresses } from './use-user-addresses';
import { AddressType } from '../../types/enums';
import { FormLoader } from '../../shared/form-loader';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { useCountries } from '../../shared/use-countries';
import { Country } from '../../types';

export const UserForm = ({ onComplete, userId }: { onComplete?: () => void; userId?: string }) => {
  const { isLoading, user, update } = useUser(userId);
  const { data: countries, isLoading: isCountriesLoading } = useCountries();
  const { update: updateAddresses, userAddresses } = useUserAddresses(userId);

  const email = user?.Contacts?.find(
    (contact) => contact.System === 'regdirect.com/contacts/email'
  );
  const phoneNumber = user?.Contacts?.find(
    (contact) => contact.System === 'regdirect.com/contacts/phone-number'
  );
  const idNumber = user?.Identifiers?.find(
    (identifier) => identifier.System === 'regdirect.com/identifiers/id-number'
  );

  const residentialAddress = userAddresses?.find(
    (current) => current.Type === AddressType.Residential
  );
  const postalAddress = userAddresses?.find((current) => current.Type === AddressType.Postal);

  const schema = useMemo(() => {
    if (isLoading || isCountriesLoading) return null;

    const isSame =
      residentialAddress &&
      postalAddress &&
      residentialAddress?.Line1 === postalAddress?.Line1 &&
      residentialAddress?.Line2 === postalAddress?.Line2 &&
      residentialAddress?.Line3 === postalAddress?.Line3 &&
      residentialAddress?.Line4 === postalAddress?.Line4 &&
      residentialAddress?.Postcode === postalAddress?.Postcode &&
      residentialAddress?.Country === postalAddress?.Country;
    const schema: Schema = [
      {
        name: 'firstName',
        type: FieldType.Text,
        label: 'First name',
        initialValue: user?.Name.Given ?? '',
        required: true,
      },
      {
        name: 'lastName',
        type: FieldType.Text,
        label: 'Last name',
        initialValue: user?.Name.Family ?? '',
        required: true,
      },
      {
        name: 'emailAddress',
        type: FieldType.Email,
        label: 'Email address',
        initialValue: email?.Value ?? '',
        validate: validation.requiredEmail,
        required: true,
      },
      {
        name: 'phoneNumber',
        type: FieldType.Text,
        label: 'Phone number',
        initialValue: phoneNumber?.Value ?? '',
        validate: validation.requiredPhoneNumber,
        required: true,
      },
      {
        name: 'countryId',
        type: FieldType.Select,
        label: 'Country of ID',
        initialValue: idNumber?.CountryID ?? '',
        options: countries?.data.map((country) => ({
          value: country.ID,
          label: country.Name,
        })),
        required: true,
      },
      {
        name: 'idNumber',
        type: FieldType.Text,
        label: 'ID number',
        initialValue: idNumber?.Value ?? '',
        validate: validation.requiredIdNumber,
        required: true,
      },
      {
        type: 'fieldset',
        label: 'Residential Address',
        fields: [
          {
            name: 'residentialLine1',
            type: FieldType.Text,
            label: 'Street Address',
            initialValue: residentialAddress?.Line1 ?? '',
            required: true,
          },
          {
            name: 'residentialLine2',
            type: FieldType.Text,
            label: 'Street Address 2',
            initialValue: residentialAddress?.Line2 ?? '',
          },
          {
            name: 'residentialLine3',
            type: FieldType.Text,
            label: 'Street Address 3',
            initialValue: residentialAddress?.Line3 ?? '',
          },
          {
            name: 'residentialLine4',
            type: FieldType.Text,
            label: 'Street Address 4',
            initialValue: residentialAddress?.Line4 ?? '',
          },
          {
            name: 'residentialPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: residentialAddress?.Postcode ?? '',
            required: true,
          },
          {
            name: 'residentialCountry',
            type: FieldType.Select,
            label: 'Country',
            initialValue:
              (residentialAddress?.Country as Country)?.ID ??
              countries?.data.find((country) => country.Name === residentialAddress?.Country)?.ID,
            options: countries?.data.map((country) => ({
              value: country.ID,
              label: country.Name,
            })),
            required: true,
          },
        ],
      },
      {
        name: 'isResidentialPostalSame',
        type: FieldType.Checkbox,
        label: 'Same as residential address?',
        initialValue: !!isSame,
      },
      {
        type: 'fieldset',
        label: 'Postal Address',
        show: (values: any) => !values.isResidentialPostalSame,
        fields: [
          {
            name: 'postalLine1',
            type: FieldType.Text,
            label: 'Street Address',
            initialValue: postalAddress?.Line1 ?? '',
            required: true,
          },
          {
            name: 'postalLine2',
            type: FieldType.Text,
            label: 'Street Address 2',
            initialValue: postalAddress?.Line2 ?? '',
          },
          {
            name: 'postalLine3',
            type: FieldType.Text,
            label: 'Street Address 3',
            initialValue: postalAddress?.Line3 ?? '',
          },
          {
            name: 'postalLine4',
            type: FieldType.Text,
            label: 'Street Address 4',
            initialValue: postalAddress?.Line4 ?? '',
          },
          {
            name: 'postalPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: postalAddress?.Postcode ?? '',
            required: true,
          },
          {
            name: 'postalCountry',
            type: FieldType.Select,
            label: 'Country',
            initialValue:
              (postalAddress?.Country as Country)?.ID ??
              countries?.data.find((country) => country.Name === postalAddress?.Country)?.ID,
            options: countries?.data.map((country) => ({
              value: country.ID,
              label: country.Name,
            })),
          },
        ],
      },
    ];
    return schema;
  }, [user, isLoading, isCountriesLoading, countries, userAddresses]);

  if (!schema) {
    return <FormLoader />;
  }

  return (
    <DynamicForm
      schema={schema}
      onSubmit={async (values) => {
        try {
          await update({
            UserName: `${values.firstName} ${values.lastName}`,
            Name: {
              Use: 'usual',
              Given: values.firstName,
              Family: values.lastName,
            },
            Contacts: [
              {
                System: 'regdirect.com/contacts/email',
                Value: values.emailAddress,
                ID: email?.ID ?? '',
                Rank: email?.Rank ?? 0,
                Use: email?.Use ?? '',
              },
              {
                System: 'regdirect.com/contacts/phone-number',
                Value: values.phoneNumber,
                ID: phoneNumber?.ID ?? '',
                Rank: phoneNumber?.Rank ?? 0,
                Use: phoneNumber?.Use ?? '',
              },
            ],
            Identifiers: [
              {
                System: 'regdirect.com/identifiers/id-number',
                Value: values.idNumber,
                ID: idNumber?.ID ?? '',
                Type: idNumber?.Type ?? '',
                CountryID: values.countryId,
              },
            ],
          });

          const updatedResidentialAddress = {
            Type: AddressType.Residential,
            Line1: values.residentialLine1,
            Line2: values.residentialLine2,
            Line3: values.residentialLine3,
            Line4: values.residentialLine4,
            Postcode: values.residentialPostcode,
            Subdivision: null,
            CountryID: values.residentialCountry,
            Country: countries?.data.find((country) => country.ID === values.residentialCountry)
              ?.Name,
            ID: residentialAddress?.ID ?? '',
          };

          const updatedPostalAddress = values.isResidentialPostalSame
            ? {
                ...updatedResidentialAddress,
                Type: AddressType.Postal,
                ID: postalAddress?.ID ?? '',
              }
            : {
                Type: AddressType.Postal,
                Line1: values.postalLine1,
                Line2: values.postalLine2,
                Line3: values.postalLine3,
                Line4: values.postalLine4,
                Postcode: values.postalPostcode,
                Subdivision: null,
                CountryID: values.postalCountry,
                Country: countries?.data.find((country) => country.ID === values.postalCountry)
                  ?.Name,
                ID: postalAddress?.ID ?? '',
              };

          await updateAddresses([updatedResidentialAddress, updatedPostalAddress]);

          notifications.show({
            message: 'User saved successfully',
            color: 'green',
          });
          if (onComplete) onComplete();
        } catch (error) {
          notifications.show({
            message: 'Oops! Failed to save user. Please try again.',
            color: 'red',
          });
        }
      }}
    />
  );
};
