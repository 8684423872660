import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { validation } from '../../utils/validation';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { FormLoader } from '../../shared/form-loader';
import { Company, Address, Country } from '../../types';
import { useChangeTypes } from '../../shared/use-change-types';
import { useChange } from './use-change';
import { useActiveUser } from '../users/use-active-user';
import { useBasket } from '../../shared/use-basket';
import { addressesEqual } from '../../utils/addresses';
import { AddressType, ChangeTypeDescription } from '../../types/enums';
import { useCountries } from '../../shared/use-countries';

export function UpdateCompanyAddressesForm({
  company,
  onComplete,
}: {
  // The company to populate the form with, else empty for creation
  company: Company | null;
  // Action to eecute upon submission and completion of form
  onComplete: () => void;
}) {
  const { store } = useChange();
  const { data: changeTypes } = useChangeTypes();
  const { data: countries } = useCountries();
  const { claims } = useActiveUser();
  const { basket, isLoading, addProduct, mutate: mutateBasket } = useBasket();

  // Loop through the company addresses and find the registered address
  // and postal address based on the type of address
  const registeredAddress = company?.Addresses.find(
    (address) => address.Type === AddressType.Registered
  );
  const registeredAddressChangeType = changeTypes?.data.find(
    (changeType) => changeType.Description === ChangeTypeDescription.RegisteredAddress
  );

  const postalAddress = company?.Addresses.find((address) => address.Type === AddressType.Postal);
  const postalAddressChangeType = changeTypes?.data.find(
    (changeType) => changeType.Description === ChangeTypeDescription.PostalAddress
  );

  const schema = useMemo(() => {
    if (!changeTypes || !company || !countries || isLoading) return null;

    const schema: Schema = [
      {
        type: 'fieldset',
        label: 'Registered Address',
        fields: [
          {
            name: 'registeredLine1',
            type: FieldType.Text,
            label: 'Street Address',
            initialValue: registeredAddress?.Line1 ?? '',
            required: true,
          },
          {
            name: 'registeredLine2',
            type: FieldType.Text,
            label: 'Street Address 2',
            initialValue: registeredAddress?.Line2 ?? '',
          },
          {
            name: 'registeredLine3',
            type: FieldType.Text,
            label: 'Street Address 3',
            initialValue: registeredAddress?.Line3 ?? '',
          },
          {
            name: 'registeredLine4',
            type: FieldType.Text,
            label: 'Street Address 4',
            initialValue: registeredAddress?.Line4 ?? '',
          },
          {
            name: 'registeredPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: registeredAddress?.Postcode ?? '',
            required: true,
          },
          {
            name: 'registeredCountryID',
            type: FieldType.Select,
            label: 'Country',
            initialValue: (registeredAddress?.Country as Country)?.ID ?? '',
            options: countries?.data.map((country) => ({
              value: country.ID,
              label: country.Name,
            })),
            required: true,
          },
        ],
      },
      {
        type: 'fieldset',
        label: 'Postal Address',
        fields: [
          {
            name: 'postalLine1',
            type: FieldType.Text,
            label: 'Street Address',
            initialValue: postalAddress?.Line1 ?? '',
            required: true,
          },
          {
            name: 'postalLine2',
            type: FieldType.Text,
            label: 'Street Address 2',
            initialValue: postalAddress?.Line2 ?? '',
          },
          {
            name: 'postalLine3',
            type: FieldType.Text,
            label: 'Street Address 3',
            initialValue: postalAddress?.Line3 ?? '',
          },
          {
            name: 'postalLine4',
            type: FieldType.Text,
            label: 'Street Address 4',
            initialValue: postalAddress?.Line4 ?? '',
          },
          {
            name: 'postalPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: postalAddress?.Postcode ?? '',
            required: true,
          },
          {
            name: 'postalCountryID',
            type: FieldType.Select,
            label: 'Country',
            initialValue: (postalAddress?.Country as Country)?.ID ?? '',
            options: countries?.data.map((country) => ({
              value: country.ID,
              label: country.Name,
            })),
            required: true,
          },
        ],
      },
    ];
    return schema;
  }, [company, changeTypes, countries, isLoading]);

  if (!schema) {
    return <FormLoader />;
  }

  return (
    <>
      <DynamicForm
        schema={schema}
        onSubmit={async (values) => {
          const newRegisteredAddress: Address = {
            Type: AddressType.Registered,
            Subdivision: registeredAddress!.Subdivision,
            Line1: values.registeredLine1,
            Line2: values.registeredLine2,
            Line3: values.registeredLine3,
            Line4: values.registeredLine4,
            Postcode: values.registeredPostcode,
            CountryID: values.registeredCountryID,
            Country: {
              ID: values.registeredCountryID ?? '',
            },
            ID: registeredAddress!.ID,
          };

          if (!addressesEqual(registeredAddress!, newRegisteredAddress)) {
            try {
              const res = await store({
                ChangeTypeID: registeredAddressChangeType!.ID,
                CompanyID: company!.ID,
                DirectorID: claims!.id,
                Data: {
                  Address: newRegisteredAddress,
                  ID: registeredAddress!.ID,
                },
              });
              try {
                addProduct({
                  ChangeID: res.data.ID,
                  ProductType: 4,
                  Quantity: 1,
                });
              } catch (error) {
                notifications.show({
                  title: 'Error',
                  message: 'Failed to add registered address change to basket',
                  color: 'red',
                });
                return;
              } finally {
                mutateBasket();
              }
            } catch (error) {
              notifications.show({
                title: 'Error',
                message: 'Failed to submit registered address change request',
                color: 'red',
              });
              return;
            }

            notifications.show({
              title: 'Success',
              message: 'Company registered address change request submitted',
              color: 'green',
            });
          }

          const newPostalAddress: Address = {
            Type: AddressType.Postal,
            Subdivision: postalAddress!.Subdivision,
            Line1: values.postalLine1 ?? '',
            Line2: values.postalLine2 ?? '',
            Line3: values.postalLine3 ?? '',
            Line4: values.postalLine4 ?? '',
            Postcode: values.postalPostcode ?? '',
            CountryID: values.postalCountryID,
            Country: {
              ID: values.postalCountryID ?? '',
            },
            ID: postalAddress!.ID,
          };

          // Check if the postal address has changed by comparing the new postal address
          // with the existing postal address. You cannot compare objects directly in JavaScript
          // so you need to compare the properties of the objects
          if (!addressesEqual(postalAddress!, newPostalAddress)) {
            try {
              const res = await store({
                ChangeTypeID: postalAddressChangeType!.ID,
                CompanyID: company!.ID,
                DirectorID: claims!.id,
                Data: {
                  Address: newPostalAddress,
                  ID: postalAddress!.ID,
                },
              });
              try {
                addProduct({
                  ChangeID: res.data.ID,
                  ProductType: 4,
                  Quantity: 1,
                });
              } catch (error) {
                notifications.show({
                  title: 'Error',
                  message: 'Failed to add registered address change to basket',
                  color: 'red',
                });
                return;
              } finally {
                mutateBasket();
              }
            } catch (error) {
              notifications.show({
                title: 'Error',
                message: 'Failed to submit postal address change request',
                color: 'red',
              });
              return;
            }

            notifications.show({
              title: 'Success',
              message: 'Company postal address change request submitted',
              color: 'green',
            });
          }

          onComplete();
        }}
      />
    </>
  );
}
