import useSWR from 'swr';
import { User } from '../../types';
import { fetcher } from '../../lib/api';

export const useUsers = () => {
  const { data, isLoading, mutate } = useSWR<{
    data: User[];
  }>('/users');

  const searchUserByEmail = async (email: string) => {
    // Make a hit to the URL `/users/email-search?query=${email}` which returns a list of users with the given email
    // in the "data" field of the response
    const response = await fetcher(`/users/email-search?query=${email}`);
    return response as any;
  };

  return {
    users: data?.data,
    isLoading,
    mutate,
    searchUserByEmail,
  };
};
