import React from 'react';
import { Box, Card, Stack, Text, TextInput, rem } from '@mantine/core';
import { NameChange, PersonChange } from '../../../types';

interface PersonChangeViewProps {
  personChange: PersonChange;
}

export function PersonChangeView(props: PersonChangeViewProps) {
  const { personChange } = props;

  const person =
    personChange.Director ||
    personChange.Officer ||
    personChange.Auditor ||
    personChange.CompanySecretary;
  const firstName = personChange.Auditor ? person?.Name.split(' ')[0] : person?.Name?.Given;
  const lastName = personChange.Auditor ? person?.Name.split(' ')[1] : person?.Name?.Family;
  const type = person?.Type?.Description;
  // ignore ts error below
  // Property 'ResignedAt' does not exist on type 'Partial<Director>'.
  const resignation = person?.ResignedAt ? 'Resignation' : 'Appointment';

  const idNumber = person?.Identifiers?.find(
    (i) => i.System === 'regdirect.com/identifiers/id-number'
  )?.Value;

  return (
    <Box pos="relative">
      <Text fw="500" c="primary" mb="md">
        {resignation}
      </Text>
      <Stack gap="sm" maw={rem(360)}>
        <TextInput readOnly label="First name" value={firstName} />
        <TextInput readOnly label="Last name" value={lastName} />
        <TextInput readOnly label="ID number" value={idNumber} />
        <TextInput readOnly label="Position" value={type} />
      </Stack>
    </Box>
  );
}
