import { patch, post } from '../../lib/api';

export const useShareClass = () => {
  const add = async (
    companyID: string,
    body: {
      ShareID: string;
      TotalShares: number;
      ParValue: number;
      ShareValue: number;
      Premium: number;
      Name: string;
      Description: string;
      DateCreated: string;
    }
  ) => post(`/companies/${companyID}/share-classes`, body);
  const update = async (shareClassId: string, totalShares: number) =>
    patch(`/share-classes/${shareClassId}`, { TotalShares: totalShares });
  return {
    add,
    update,
  };
};
