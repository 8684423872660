import { useMemo } from 'react';
import { validation } from '../../utils/validation';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { FormLoader } from '../../shared/form-loader';
import { useCompanyTypes } from './use-company-types';
import { useCountries } from '../../shared/use-countries';
import { FinancialYearEndMonths } from '../../types/enums';
import { Address, Country } from '../../types';
import { addressesEqual } from '../../utils/addresses';

export function CreateCompanyInfoForm({
  companyInfo,
  authorisedShareCount,
  onCreate,
}: {
  companyInfo?: {
    EntityTypeID: string;
    Addresses: {
      Type: string;
      Line1: string;
      Line2: string;
      Line3: string;
      Line4: string;
      Postcode: string;
      CountryID: string;
    }[];
    FinancialYearEndMonth: number;
  };
  authorisedShareCount?: number;
  onCreate?: (
    CompanyInfo: {
      EntityTypeID: string;
      Addresses: {
        Type: string;
        Line1: string;
        Line2: string;
        Line3: string;
        Line4: string;
        Postcode: string;
        CountryID: string;
      }[];
      FinancialYearEndMonth: number;
    },
    AuthorisedShareCount: number
  ) => void;
}) {
  const { data: companyTypes } = useCompanyTypes();
  const { data: countries } = useCountries();

  const registeredAddress = companyInfo?.Addresses?.find((a) => a.Type === 'Registered');
  const postalAddress = companyInfo?.Addresses?.find((a) => a.Type === 'Postal');

  const addressesEqual =
    registeredAddress?.Line1 === postalAddress?.Line1 &&
    registeredAddress?.Line2 === postalAddress?.Line2 &&
    registeredAddress?.Line3 === postalAddress?.Line3 &&
    registeredAddress?.Line4 === postalAddress?.Line4 &&
    registeredAddress?.Postcode === postalAddress?.Postcode &&
    registeredAddress?.CountryID === postalAddress?.CountryID;

  type Value = string | boolean | number | Date;

  const schema = useMemo(() => {
    if (!companyTypes || !countries) return null;

    const schema: Schema = [
      {
        type: FieldType.Select,
        name: 'entityType',
        label: 'Entity Type',
        initialValue: companyInfo?.EntityTypeID || '',
        validate: validation.requiredCompanyName,
        options: companyTypes.data.map((companyType) => ({
          value: companyType.ID,
          label: companyType.Description,
        })).filter(a => a.label === 'Private Company' || a.label === 'Non Profit Company'),
        required: true,
      },
      {
        type: 'fieldset',
        label: 'Registered Address',
        fields: [
          {
            name: 'registeredLine1',
            type: FieldType.Text,
            label: 'Street Address',
            initialValue: registeredAddress?.Line1 || '',
            required: true,
          },
          {
            name: 'registeredLine2',
            type: FieldType.Text,
            label: 'Street Address 2',
            initialValue: registeredAddress?.Line2 || '',
          },
          {
            name: 'registeredLine3',
            type: FieldType.Text,
            label: 'Street Address 3',
            initialValue: registeredAddress?.Line3 || '',
          },
          {
            name: 'registeredLine4',
            type: FieldType.Text,
            label: 'Street Address 4',
            initialValue: registeredAddress?.Line4 || '',
          },
          {
            name: 'registeredPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: registeredAddress?.Postcode || '',
            required: true,
          },
          {
            name: 'registeredCountry',
            type: FieldType.Select,
            label: 'Country',
            initialValue: registeredAddress?.CountryID || '',
            options: countries?.data.map((country) => ({
              value: country.ID,
              label: country.Name,
            })),
            required: true,
          },
        ],
      },
      {
        type: FieldType.Checkbox,
        name: 'isResidentialPostalSame',
        label: 'Same as registered address?',
        initialValue: addressesEqual,
      },
      {
        type: 'fieldset',
        label: 'Postal Address',
        show: (values: any) => !values.isResidentialPostalSame,
        fields: [
          {
            name: 'postalLine1',
            type: FieldType.Text,
            label: 'Street Address',
            initialValue: postalAddress?.Line1 || '',
            required: true,
          },
          {
            name: 'postalLine2',
            type: FieldType.Text,
            label: 'Street Address 2',
            initialValue: postalAddress?.Line2 || '',
          },
          {
            name: 'postalLine3',
            type: FieldType.Text,
            label: 'Street Address 3',
            initialValue: postalAddress?.Line3 || '',
          },
          {
            name: 'postalLine4',
            type: FieldType.Text,
            label: 'Street Address 4',
            initialValue: postalAddress?.Line4 || '',
          },
          {
            name: 'postalPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: postalAddress?.Postcode || '',
            required: true,
          },
          {
            name: 'postalCountry',
            type: FieldType.Select,
            label: 'Country',
            initialValue: postalAddress?.CountryID || 'South Africa',
            options: countries?.data.map((country) => ({
              value: country.ID,
              label: country.Name,
            })),
            required: true,
          },
        ],
      },
      {
        type: FieldType.Select,
        name: 'authorisedShareCount',
        label: 'Authorised Share Count',
        initialValue: authorisedShareCount || '1000',
        options: ['100', '1000']?.map((c) => ({
          value: c,
          label: c,
        })),
        required: true,
      },
      {
        type: FieldType.Select,
        name: 'financialYearEndMonth',
        label: 'Financial Year End Month',
        description: 'February recommended',
        initialValue: '2',
        options: FinancialYearEndMonths.map((month) => ({
          value: month.value.toString(),
          // Get the name of each month from the const
          label: month.month.toString(),
        })),
        required: true,
      },
    ];
    return schema;
  }, [companyTypes, countries]);

  if (!schema) {
    return <FormLoader />;
  }

  return (
    <DynamicForm
      schema={schema}
      buttonText={'Next'}
      onSubmit={async (values) => {
        onCreate?.(
          {
            EntityTypeID: values.entityType,
            Addresses: [
              {
                Type: 'Registered',
                Line1: values.registeredLine1,
                Line2: values.registeredLine2,
                Line3: values.registeredLine3,
                Line4: values.registeredLine4,
                Postcode: values.registeredPostcode,
                CountryID: values.registeredCountry,
              },
              {
                Type: 'Postal',
                Line1: values.isResidentialPostalSame ? values.registeredLine1 : values.postalLine1,
                Line2: values.isResidentialPostalSame ? values.registeredLine2 : values.postalLine2,
                Line3: values.isResidentialPostalSame ? values.registeredLine3 : values.postalLine3,
                Line4: values.isResidentialPostalSame ? values.registeredLine4 : values.postalLine4,
                Postcode: values.isResidentialPostalSame
                  ? values.registeredPostcode
                  : values.postalPostcode,
                CountryID: values.isResidentialPostalSame
                  ? values.registeredCountry
                  : values.postalCountry,
              },
            ],
            FinancialYearEndMonth: parseInt(values.financialYearEndMonth),
          },
          parseInt(values.authorisedShareCount)
        );
      }}
    />
  );
}
