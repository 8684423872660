import { Button, Group, Tabs, TextInput, Title, rem } from '@mantine/core';
import { useState } from 'react';
import { LuSearch } from 'react-icons/lu';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { ListingModal } from './listing-modal';
import { useActiveUser } from '../users/use-active-user';
import { ListingStatus } from '../../types';
import { useListings } from './use-listings';
import { Column, Table } from '../../shared/table';

const columns: Column[] = [
  {
    key: 'Name',
    name: 'Name',
  },
  {
    key: 'Description',
    name: 'Description',
  },
  {
    key: 'Price',
    name: 'Price',
    render: (row: any) => (row.Price ? `${row.Currency} ${row.Price}` : ''),
  },
  {
    key: 'Address',
    name: 'Address',
  },
];

export function ManageListings() {
  const navigate = useNavigate();
  const { isAdmin } = useActiveUser();
  const [search, setSearch] = useState('' as string);
  const [status, setStatus] = useState<ListingStatus>('approved');
  const [showListingModal, setShowListingModal] = useState(false);
  const { listings, isLoading, mutate, updateListing } = useListings(search, status);

  return (
    <>
      <Title order={2} mb="lg">
        Manage Listings
      </Title>
      <Tabs
        value={status}
        onChange={(value) => {
          if (value) setStatus(value as ListingStatus);
        }}
        variant="pills"
        color="grey"
      >
        <Group justify="space-between" align="center" mb="md">
          <TextInput
            placeholder="Search"
            aria-label="search"
            leftSection={<LuSearch size={20} />}
            miw={rem(300)}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />

          <Button onClick={() => setShowListingModal(true)}>Add listing</Button>
        </Group>

        <Tabs.List mb="md">
          <Tabs.Tab value="approved">Approved and published</Tabs.Tab>
          <Tabs.Tab value="pending">{isAdmin ? 'Ready for review' : 'In review'}</Tabs.Tab>
          <Tabs.Tab value="closed">Closed</Tabs.Tab>
          <Tabs.Tab value="rejected">Rejected</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="approved">
          <Table
            isLoading={isLoading}
            isEmpty={listings?.length === 0}
            data={listings}
            columns={columns}
            rowActions={(row) => [
              {
                label: 'Close',
                onClick: async () => {
                  try {
                    await updateListing(row.ID, {
                      ...row,
                      Status: 'closed',
                    });
                    await mutate();
                    notifications.show({
                      title: 'Success',
                      message: 'Listing closed',
                      color: 'green',
                    });
                  } catch (error) {
                    console.error(error);
                    notifications.show({
                      title: 'Error',
                      message: 'Failed to close listing',
                      color: 'red',
                    });
                  }
                },
              },
              {
                label: 'View enquiries',
                onClick: () => {
                  navigate(`/marketplace/manage/${row.ID}/enquiries`);
                },
              },
            ]}
          />
        </Tabs.Panel>
        <Tabs.Panel value="pending">
          <Table
            isLoading={isLoading}
            isEmpty={listings?.length === 0}
            data={listings}
            columns={columns}
            rowActions={(row) => [
              {
                label: 'Approve',
                onClick: async () => {
                  try {
                    await updateListing(row.ID, {
                      ...row,
                      Status: 'approved',
                    });
                    await mutate();
                    notifications.show({
                      title: 'Success',
                      message: 'Listing approved and published',
                      color: 'green',
                    });
                  } catch (error) {
                    console.error(error);
                    notifications.show({
                      title: 'Error',
                      message: 'Failed to approve listing',
                      color: 'red',
                    });
                  }
                },
              },
              {
                label: 'Reject',
                onClick: async () => {
                  try {
                    await updateListing(row.ID, {
                      ...row,
                      Status: 'rejected',
                    });
                    await mutate();
                    notifications.show({
                      title: 'Success',
                      message: 'Listing rejected',
                      color: 'green',
                    });
                  } catch (error) {
                    console.error(error);
                    notifications.show({
                      title: 'Error',
                      message: 'Failed to reject listing',
                      color: 'red',
                    });
                  }
                },
              },
            ]}
          />
        </Tabs.Panel>
        <Tabs.Panel value="closed">
          <Table
            isLoading={isLoading}
            isEmpty={listings?.length === 0}
            data={listings}
            columns={columns}
          />
        </Tabs.Panel>
        <Tabs.Panel value="rejected">
          <Table
            isLoading={isLoading}
            isEmpty={listings?.length === 0}
            data={listings}
            columns={columns}
          />
        </Tabs.Panel>
      </Tabs>
      <ListingModal
        onComplete={() => {
          setShowListingModal(false);
          mutate();
        }}
        opened={showListingModal}
        close={() => setShowListingModal(false)}
      />
    </>
  );
}
