import { isValidEmail } from './is-valid-email';

const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const regex = /^((\+27|0)[1-9])\d{8}$/;
  return regex.test(phoneNumber);
};

const isValidIdNumber = (idNumber: string): boolean => {
  const regex = /^[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[0-9]{6}[0-9]{1}$/;
  return regex.test(idNumber);
};

const isValidCardNumber = (cardNumber: string): boolean => {
  const regex = /^[0-9]{16}$/;
  return regex.test(cardNumber);
};

const isValidCvv = (cvv: string): boolean => {
  const regex = /^[0-9]{3}$/;
  return regex.test(cvv);
};

const isValidCompanyName = (companyName: string): boolean => {
  // Advice around acceptable character's and punctuation in company names
  // can be found here: https://www.swiftreg.co.za/avoid-name-rejections.aspx
  const regex = /^[A-Za-z0-9\s()\-&']*$/;
  return regex.test(companyName);
};

export const validation = {
  required: (value: string | number) =>
    value && value.toString().length > 0 ? null : 'Field is required',
  requiredEmail: (value: string) => (value && isValidEmail(value) ? null : 'Invalid email'),
  requiredPhoneNumber: (value: string) =>
    value && isValidPhoneNumber(value) ? null : 'Invalid phone number',
  requiredIdNumber: (value: string) =>
    value && isValidIdNumber(value) ? null : 'Invalid ID number',
  requiredCardNumber: (value: string) =>
    value && isValidCardNumber(value) ? null : 'Invalid card number: must be 16 digits',
  requiredCvv: (value: string) =>
    value && isValidCvv(value) ? null : 'Invalid CVV: must be 3 digits',
  requiredCompanyName: (value: string) =>
    value && isValidCompanyName(value)
      ? null
      : 'Invalid company name: must be alphanumeric, and can use brackets, hyphens, apostrophes and ampersands',
  companyName: (value: string) =>
    !value
      ? null
      : isValidCompanyName(value)
        ? null
        : 'Invalid company name: must be alphanumeric, and can use brackets, hyphens, apostrophes and ampersands',
};
