import useSWR from 'swr';

export const useDirectorStatuses = () =>
  useSWR<{
    data: {
      Code: string;
      Description: string;
      ID: string;
    }[];
  }>('/director-statuses');
