import { MantineColorsTuple, createTheme } from '@mantine/core';

const primary: MantineColorsTuple = [
  '#ffe9e9',
  '#ffd1d1',
  '#f9a1a1',
  '#f56f6f',
  '#f14342',
  '#f02a27',
  '#f01b19',
  '#d60d0d',
  '#bf040a',
  '#a70005',
];

export const theme = createTheme({
  fontFamily: '"Open Sans Variable", sans-serif',
  headings: { fontFamily: '"Open Sans Variable", sans-serif' },
  primaryColor: 'primary',
  colors: {
    primary,
  },
  cursorType: 'pointer',
  components: {
    Th: {
      defaultProps: {
        style: { color: 'red' },
      },
    },
  },
});
