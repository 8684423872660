import { Anchor, Breadcrumbs, Text, Title } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';
import { Table } from '../../shared/table';
import { useCompany } from './use-company';
import { useCompanyDocuments } from './use-company-documents';
import { ErrorAlert } from '../../shared/error-alert';

export function Documents() {
  const { id } = useParams<{ id: string }>();
  const { company } = useCompany(id);
  const { isLoading, data, error } = useCompanyDocuments(id);

  const documents = data?.data;

  return (
    <>
      <Breadcrumbs mb="xl">
        <Anchor component={Link} to="/companies">
          Companies
        </Anchor>
        <Anchor component={Link} to={`/companies/${company?.ID}`}>
          {company?.Name ?? '-'}
        </Anchor>
        <Text>Documents</Text>
      </Breadcrumbs>
      <Title order={3} mb="lg">
        Documents
      </Title>
      {error ? (
        <ErrorAlert error={error} />
      ) : (
        <Table
          isLoading={isLoading}
          isEmpty={documents?.length === 0 || documents === null}
          data={documents}
          columns={[
            {
              key: 'DisplayName',
              name: 'Name',
            },
            {
              key: 'DateCreated',
              name: 'Created at',
              render: (current) => new Date(current.DateCreated).toLocaleDateString(),
            },
          ]}
          rowActions={(row) => [
            {
              label: 'Download',
              onClick: () => {
                window.open(row.SignedURL, '_blank');
              },
            },
          ]}
        />
      )}
    </>
  );
}
