import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { v4 as uuidv4 } from 'uuid';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { validation } from '../../utils/validation';
import { FormLoader } from '../../shared/form-loader';
import { useCompanyTypes } from '../../shared/use-company-types';
import { useShareholder } from './use-shareholder';
import { Country } from '../../types';
import { useCountries } from '../../shared/use-countries';

export const CompanyShareholderForm = ({
  onComplete,
  companyId,
  shareholderId,
}: {
  onComplete?: () => void;
  companyId?: string;
  shareholderId?: string | null;
}) => {
  const { data: companyTypes } = useCompanyTypes();
  const { create, shareholder } = useShareholder(companyId, shareholderId);
  const { data: countries } = useCountries();

  const address = shareholder?.Addresses?.find((current) => current.Type === 'Business');

  const registrationNumber = shareholder?.Identifiers?.find(
    (current) => current.System === 'regdirect.com/identifiers/enterprise-number'
  );

  const phoneNumber = shareholder?.Contacts?.[0];

  const schema = useMemo(() => {
    if (!companyTypes) return null;
    if (shareholderId && !shareholder) return null;
    if (!countries) return null;

    const schema: Schema = [
      {
        name: 'companyName',
        type: FieldType.Text,
        label: 'Company Name',
        initialValue: shareholder?.CompanyShareholderFields.Name ?? '',
        validate: validation.required,
      },
      {
        name: 'companyType',
        type: FieldType.Select,
        label: 'Company Type',
        initialValue: shareholder?.CompanyShareholderFields.EntityType.ID ?? '',
        options: companyTypes.data.map((current) => ({
          value: current.ID,
          label: current.Description,
        })),
        validate: validation.required,
      },
      {
        name: 'registrationNumber',
        type: FieldType.Text,
        label: 'Registration Number',
        initialValue: registrationNumber?.Value ?? '',
        validate: validation.required,
      },
      {
        name: 'phoneNumber',
        type: FieldType.Text,
        label: 'Phone number',
        placeholder: '+27744190174',
        initialValue: phoneNumber?.Value ?? '',
        validate: validation.requiredPhoneNumber,
      },
      {
        type: 'fieldset',
        label: 'Physical Address',
        fields: [
          {
            name: 'physicalLine1',
            type: FieldType.Text,
            label: 'Street address line 1',
            initialValue: address?.Line1 ?? '',
            validate: validation.required,
          },
          {
            name: 'physicalLine2',
            type: FieldType.Text,
            label: 'Street address line 2',
            initialValue: address?.Line2 ?? '',
          },
          {
            name: 'physicalPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: address?.Postcode ?? '',
            validate: validation.required,
          },
          {
            name: 'physicalCountry',
            type: FieldType.Select,
            label: 'Country',
            initialValue:
              (address?.Country as Country)?.ID ??
              (countries.data.find((country) => country.Name === address?.Country) as Country)
                ?.ID ??
              '',
            options: countries.data.map((current) => ({
              value: current.ID,
              label: current.Name,
            })),
            required: true,
            validate: validation.required,
          },
        ],
      },
    ];
    return schema;
  }, [companyTypes, shareholder, shareholderId]);

  if (!schema) {
    return <FormLoader />;
  }
  return (
    <DynamicForm
      schema={schema}
      onSubmit={async (values) => {
        const businessAddress = {
          ID: address?.ID ? address?.ID : uuidv4.Nil,
          Type: 'Business',
          Line1: values.physicalLine1,
          Line2: values.physicalLine2,
          Country: {
            ID: values.physicalCountry,
          },
          Subdivision: null,
          Postcode: values.physicalPostcode,
        };
        let body : any;
        if (shareholder?.ID) {
          body = {
            ID: shareholder.ID,
            ShareholderType: 'company_shareholder',
            Identifiers: [
              {
                System: 'regdirect.com/identifiers/enterprise-number',
                Value: values.registrationNumber,
                Type: 'Enterprise Number',
                ID: registrationNumber?.ID ? registrationNumber?.ID : uuidv4.Nil,
              },
            ],
            Contacts: [
              {
                System: 'regdirect.com/contacts/phone-number',
                Value: values.phoneNumber,
                ID: phoneNumber?.ID ? phoneNumber?.ID : uuidv4.Nil,
              },
            ],
            Addresses: [businessAddress],
            CompanyShareholderFields: {
              Name: values.companyName,
              EntityTypeID: values.companyType,
            },
          };
        } else {
          body = {
            ShareholderType: 'company_shareholder',
            Identifiers: [
              {
                System: 'regdirect.com/identifiers/enterprise-number',
                Value: values.registrationNumber,
                Type: 'Enterprise Number',
                ID: registrationNumber?.ID ? registrationNumber?.ID : uuidv4.Nil,
              },
            ],
            Contacts: [
              {
                System: 'regdirect.com/contacts/phone-number',
                Value: values.phoneNumber,
                ID: phoneNumber?.ID ? phoneNumber?.ID : uuidv4.Nil,
              },
            ],
            Addresses: [businessAddress],
            CompanyShareholderFields: {
              Name: values.companyName,
              EntityTypeID: values.companyType,
            },
          };
        }
        await create(values.registrationNumber, body);
        notifications.show({
          message: 'Company shareholder saved successfully',
          color: 'green',
        });
        onComplete && onComplete();
      }}
    />
  );
};
