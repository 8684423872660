import useSWR from 'swr';
import { User } from '../../types';
import { patch } from '../../lib/api';

export const useUser = (userId?: string) => {
  const { data, isLoading, mutate, error } = useSWR<{
    data: User;
  }>(userId ? `/users/${userId}` : null);

  const update = async (values: Partial<User>) => {
    await patch(`/users/${userId}`, values);
    mutate();
  };

  return {
    user: data?.data,
    isLoading,
    error,
    mutate,
    update,
  };
};
