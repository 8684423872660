import useSWR from 'swr';
import { put } from '../../lib/api';
import { Nullable, Shareholder } from '../../types';

interface Country {
  Name: string;
  Alpha2Code: string;
  Alpha3Code: string;
  NumericCode: number;
  Currency: string;
  CallingCode: string;
  ID: string;
}

interface Address {
  ID: string | null;
  Type: number | string;
  Line1: string;
  Line2?: Nullable<string>;
  Line3?: Nullable<string>;
  Line4?: Nullable<string>;
  Country: Partial<Country> | string;
  Subdivision?: Nullable<string>;
  Postcode: Nullable<string>;
}

export const useShareholder = (companyId?: string, shareholderId?: string | null) => {
  const { data, isLoading, mutate } = useSWR<{
    data: Shareholder;
  }>(shareholderId ? `/shareholders/${shareholderId}` : null);

  const create = (
    identifier: string,
    body: {
      ID: string | null;
      ShareholderType: string;
      Identifiers: {
        ID: string | null;
        System: string;
        Value: string;
        Type: string;
      }[];
      Contacts: {
        ID: string | null;
        System: string;
        Value: string;
      }[];
      Addresses: Address[];
      IndividualShareholderFields?: {
        Name: {
          ID: string | null;
          Use: string;
          Given: string;
          Family: string;
          Prefix: string | null;
          Suffix: string | null;
        };
        UserID: string | null;
      };
      CompanyShareholderFields?: {
        Name: string;
        EntityTypeID: string;
      };
      TrustShareholderFields?: {
        Name: string;
      };
    }
  ) => {
    if (!companyId) return null;
    return put(`/companies/${companyId}/shareholders?identifier=${identifier}`, body);
  };

  const shareholder = data?.data;
  const name =
    shareholder?.ShareholderType === 'individual_shareholder'
      ? `${shareholder.IndividualShareholderFields.Name.Given} ${shareholder.IndividualShareholderFields.Name.Family}`
      : shareholder?.CompanyShareholderFields.Name || shareholder?.TrustShareholderFields.Name;

  return {
    shareholder,
    name,
    isLoading,
    mutate,
    create,
  };
};
