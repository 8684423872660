import { Button, Group, Modal, Text } from '@mantine/core';
import { useState } from 'react';

export function ConfirmationModal({
  isOpen,
  onClose,
  title,
  description,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  title: string;
  description: string;
}) {
  const [isLoading, setLoading] = useState(false);

  async function handleConfirm() {
    setLoading(true);
    await onConfirm();
    setLoading(false);
    onClose();
  }

  return (
    <Modal size="sm" opened={isOpen} onClose={onClose} title={title} centered>
      <Text>{description}</Text>
      <Group mt="xl" justify="right">
        <Button disabled={isLoading} variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={isLoading} onClick={handleConfirm}>
          Confirm
        </Button>
      </Group>
    </Modal>
  );
}
