import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import { useUser } from './use-user';

export function useActiveUser() {
  const { user: authUser } = useAuth0();
  const claims = useMemo(
    () =>
      authUser
        ? {
            active: authUser['https://app.regdirect.dev/user_metadata.active'],
            id: authUser['https://app.regdirect.dev/user_metadata.id'],
            roles: authUser['https://app.regdirect.dev/user_metadata.roles'],
          }
        : undefined,
    [authUser]
  );
  const { user, mutate, error } = useUser(claims?.id);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (user) {
      setIsInitialized(true);
    }
  }, [user]);

  const email = user?.Contacts?.find(
    (contact) => contact.System === 'regdirect.com/contacts/email'
  )?.Value;
  const phoneNumber = user?.Contacts?.find(
    (contact) => contact.System === 'regdirect.com/contacts/phone-number'
  )?.Value;
  const idNumber = user?.Identifiers?.find(
    (identifier) => identifier.System === 'regdirect.com/identifiers/id-number'
  )?.Value;

  const isIncompleteProfile = useMemo(
    () => !user?.Name.Given || !user.Name.Family || !email || !phoneNumber || !idNumber,
    [user]
  );

  const isInvaidAccount = (authUser && claims?.id === undefined) || !!error;

  const isAdmin = claims?.roles && claims.roles.includes('admin');
  const isUser = claims?.roles && claims.roles.includes('user');
  const isActive = claims?.active ?? false;

  return {
    isInitialized,
    user,
    claims,
    mutate,
    error,
    isIncompleteProfile,
    isAdmin,
    isUser,
    isActive,
    isInvaidAccount,
  };
}
