import { useMemo, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { Button, TextInput, Group } from '@mantine/core'; // Assuming you are using mantine, adjust according to your UI library
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { validation } from '../../utils/validation';
import { FormLoader } from '../../shared/form-loader';
import { useDirectors } from './use-directors';
import { useDirectorStatuses } from '../../shared/use-director-statuses';
import { useDirectorTypes } from '../../shared/use-director-types';
import { useCountries } from '../../shared/use-countries';
import { useOccupationLevels } from '../../shared/use-occupation-levels';
import { useBasket } from '../../shared/use-basket';
import { useUsers } from '../users/use-users';
import { Director, User } from '../../types';
import { AddressType } from '../../types/enums';
import { formatDirectorOptions } from './format-director-options';

export const AppointDirectorForm = ({
  onComplete,
  companyId,
  onCreate,
}: {
  onComplete?: (changeID: string) => void;
  companyId?: string;
  onCreate?: (DirectorChange: {
    Director: Partial<Director>;
    Documents: {
      DisplayName: string;
      File: File;
    }[];
  }) => void;
}) => {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState<User | null>(null);
  const [formData, setFormData] = useState({
    Email: '',
    DirectorInfo: {}, // Placeholder for director information
  });

  // Hooks for loading necessary data
  const { data: countries } = useCountries();
  const { data: directorTypes } = useDirectorTypes();
  const { data: occupationLevels } = useOccupationLevels();
  const { data: directorStatuses } = useDirectorStatuses();
  const { addProduct, mutate: mutateBasket } = useBasket();
  const { searchUserByEmail } = useUsers();
  const { appoint, data: directors } = useDirectors(companyId);
  const isCreating = onCreate;

  const fetchAndCheckUserExists = async (email: string) => {
    // Fetch user details based on ID number and country of origin
    const user = await searchUserByEmail(email);
    // is the length of user.data > 0
    if (user.data.length > 0) {
      // Notificaiton to say user exists
      notifications.show({
        title: 'User Exists',
        message: 'A User with the desired email already exists',
        color: 'green',
      });
      setUser(user.data[0]);
    } else {
      setUser(null);
    }
    setStep((prev) => prev + 1);
  };

  const schema = useMemo(() => {
    const isLoaded = !!(occupationLevels && directorTypes && directorStatuses && countries);
    if (!isLoaded) return null;
    if (!isCreating) {
      if (!directors) return null;
    }

    // Get the user's email and phone number from their contacts array
    // Get the user's ID number from their identifiers array
    // Get the user's address from their addresses array
    // Get the user's name from their name object
    const email = user?.Contacts?.find(
      (contact) => contact.System === 'regdirect.com/contacts/email'
    );
    const phoneNumber = user?.Contacts?.find(
      (contact) => contact.System === 'regdirect.com/contacts/phone-number'
    );
    const idNumber = user?.Identifiers?.find(
      (identifier) => identifier.System === 'regdirect.com/identifiers/id-number'
    );
    const address = user?.Addresses?.find((address) => address.Type === 'Residential');

    const schema: Schema = [
      {
        type: 'fieldset',
        label: 'Director details',
        fields: [
          {
            name: 'GivenName',
            type: FieldType.Text,
            label: 'First name',
            initialValue: user?.Name?.Given || '',
            required: true,
          },
          {
            name: 'FamilyName',
            type: FieldType.Text,
            label: 'Second name',
            initialValue: user?.Name?.Family || '',
            required: true,
          },
          {
            type: FieldType.Date,
            name: 'BirthDate',
            label: 'Birth date',
            initialValue: new Date(),
            required: true,
          },
          {
            name: 'CountryOfOriginID',
            type: FieldType.Select,
            label: 'Country of origin',
            initialValue: idNumber?.CountryID || '',
            options: countries.data.map((current) => ({
              value: current.ID,
              label: current.Name,
            })),
            required: true,
          },
          {
            name: 'IDNumber',
            type: FieldType.Text,
            label: 'ID number',
            initialValue: idNumber?.Value || '',
            validate: validation.requiredIdNumber,
            required: true,
          },
          {
            name: 'Email',
            type: FieldType.Email,
            label: 'Email',
            initialValue: email ? email.Value : formData.Email,
            validate: validation.requiredEmail,
            required: true,
          },
          {
            name: 'ContactNumber',
            type: FieldType.Text,
            label: 'Contact Number',
            initialValue: phoneNumber?.Value || '',
            validate: validation.requiredPhoneNumber,
            required: true,
          },
        ],
      },
      // {
      //   type: 'fieldset',
      //   label: 'Appointment details',
      //   fields: [
      //     {
      //       name: 'DirectorTypeID',
      //       type: FieldType.Select,
      //       label: 'Appointment',
      //       initialValue: '',
      //       options: directorTypes.data.map((current) => ({
      //         value: current.ID,
      //         label: `${current.Description} (${current.Code})`,
      //       })),
      //       required: true,
      //     },
      //     {
      //       name: 'DirectorTitle',
      //       type: FieldType.Text,
      //       label: 'Title',
      //       initialValue: '',
      //       placeholder: 'e.g. Chief Executive Officer',
      //       required: true,
      //     },
      //     {
      //       name: 'DirectorOccupationLevelID',
      //       type: FieldType.Select,
      //       label: 'Occupation level',
      //       initialValue: '',
      //       options: occupationLevels.data.map((current) => ({
      //         value: current.ID,
      //         label: `${current.Description}`,
      //       })),
      //       required: true,
      //     },
      //     {
      //       name: 'DirectorStatusID',
      //       type: FieldType.Select,
      //       label: 'Status',
      //       initialValue: '',
      //       options: directorStatuses.data.map((current) => ({
      //         value: current.ID,
      //         label: `${current.Description} (${current.Code})`,
      //       })),
      //       required: true,
      //     },
      //     {
      //       type: FieldType.Date,
      //       name: 'AppointedAt',
      //       label: 'Appointed at',
      //       initialValue: new Date(),
      //       required: true,
      //     },
      //   ],
      // },
      {
        type: 'fieldset',
        label: 'Address',
        fields: [
          {
            name: 'AddressLine1',
            type: FieldType.Text,
            label: 'Street address line 1',
            initialValue: address?.Line1 || '',
            required: true,
          },
          {
            name: 'AddressLine2',
            type: FieldType.Text,
            label: 'Street address line 2',
            initialValue: address?.Line2 || '',
          },
          {
            name: 'AddressPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: address?.Postcode || '',
            required: true,
          },
          {
            name: 'AddressCountryID',
            type: FieldType.Select,
            label: 'Country',
            initialValue:
              countries.data.find((country) => country.Name === address?.Country)?.ID || '',
            options: countries.data.map((current) => ({
              value: current.ID,
              label: current.Name,
            })),
            required: true,
          },
        ],
      },
    ];

    if (!isCreating) {
      schema.unshift({
        name: 'DirectorID',
        type: FieldType.Select,
        label: 'Director actioning the appointment',
        initialValue: '',
        options: formatDirectorOptions(directors!),
        required: true,
      });
    }

    return schema;
  }, [directors, occupationLevels, directorTypes, directorStatuses, countries, user]);

  const handleNext = () => {
    const { Email } = formData;
    fetchAndCheckUserExists(Email);
  };

  const handleBack = () => setStep((prev) => prev - 1);

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <Group align="end" mb="lg">
            <TextInput
              autoComplete="email"
              type="email"
              label="Director Email"
              placeholder="john.smith@gmail.com"
              style={{ width: '50%' }}
              value={formData.Email}
              onChange={(event) => setFormData({ ...formData, Email: event.target.value })}
              required
            />
            <Button onClick={handleNext}>Next</Button>
          </Group>
        );
      case 2:
        // The actual form where you input director information
        // For simplicity, I'm directly calling DynamicForm as a placeholder
        // You should replace this with your actual form fields based on whether userExists
        if (!schema) {
          return <FormLoader />;
        }
        return (
          <DynamicForm
            schema={schema}
            onSubmit={async (values) => {
              if (!isCreating) {
                let changeID: string;
                try {
                  const res = await appoint({
                    CompanyID: companyId!,
                    DirectorID: values.DirectorID,
                    GivenName: values.GivenName,
                    FamilyName: values.FamilyName,
                    DirectorTypeID: values.DirectorTypeID,
                    DirectorStatusID: values.DirectorStatusID,
                    TypeID: values.DirectorTypeID,
                    StatusID: values.DirectorStatusID,
                    CountryOfOriginID: values.CountryOfOriginID,
                    OccupationLevelID: values.DirectorOccupationLevelID,
                    Occupation: values.DirectorTitle,
                    AppointedAt: values.AppointedAt,
                    BirthDate: values.BirthDate,
                    IDNumber: values.IDNumber,
                    Email: values.Email,
                    PhoneNumber: values.ContactNumber,
                    AddressLine1: values.AddressLine1,
                    AddressLine2: values.AddressLine2,
                    AddressCountryID: values.AddressCountryID,
                    AddressPostcode: values.AddressPostcode,
                    UserID: user?.ID || null,
                    Documents: [],
                  });

                  try {
                    addProduct({
                      ChangeID: res.data.ID,
                      ProductType: 4,
                      Quantity: 1,
                    });
                  } catch (error) {
                    notifications.show({
                      title: 'Error',
                      message: 'Failed to add director appointment to basket',
                      color: 'red',
                    });
                    return;
                  } finally {
                    mutateBasket();
                  }
                  changeID = res.data.ID;
                } catch (error) {
                  console.error(error);
                  notifications.show({
                    title: 'Error',
                    message: 'Failed to submit director appointment request',
                    color: 'red',
                  });
                  return;
                }
                notifications.show({
                  Title: 'Success',
                  message: 'Director appointment request created successfully',
                  color: 'green',
                });
                onComplete && onComplete(changeID);
              } else {
                onCreate?.({
                  Director: {
                    Name: {
                      Use: 'usual',
                      Given: values.GivenName,
                      Family: values.FamilyName,
                    },
                    OccupationLevelID: values.DirectorOccupationLevelID,
                    AppointedAt: values.AppointedAt,
                    TypeID: values.DirectorTypeID,
                    StatusID: values.DirectorStatusID,
                    UserID: user?.ID || null,
                    Contacts: [
                      {
                        System: 'regdirect.com/contacts/email',
                        Value: values.Email,
                      },
                      {
                        System: 'regdirect.com/contacts/phone-number',
                        Value: values.ContactNumber,
                      },
                    ],
                    Addresses: [
                      {
                        Type: AddressType.Residential,
                        Line1: values.AddressLine1,
                        Line2: values.AddressLine2,
                        Country: values.AddressCountry,
                        Postcode: values.AddressPostcode,
                      },
                    ],
                    Identifiers: [
                      {
                        System: 'regdirect.com/identifiers/id-number',
                        Value: values.IDNumber,
                      },
                    ],
                  },
                  Documents: [],
                });
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {step > 1 && (
        <Button justify="end" style={{ marginBottom: '20px' }} onClick={handleBack}>
          Back to email
        </Button>
      )}
      {renderStepContent()}
      {/* Depending on your form handling, you might include submission inside the form or here */}
    </div>
  );
};
