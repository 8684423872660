import { useNavigate, useParams } from 'react-router-dom';
import { FormLayout } from '../../shared/form-layout';
import { AppointDirectorForm } from './appoint-director-form';

export const AppointDirector = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  return (
    <FormLayout title="Appoint Director">
      <AppointDirectorForm
        companyId={id}
        onComplete={(changeID: string) => navigate(`/requested-changes/${changeID}`)}
      />
    </FormLayout>
  );
};
