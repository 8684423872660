import { ReactNode } from 'react';
import {
  LuBookPlus,
  LuBuilding,
  LuShoppingBasket,
  LuShoppingCart,
  LuUser,
  LuUserCheck,
} from 'react-icons/lu';
import { useActiveUser } from '../features/users/use-active-user';

type Item = {
  to: string;
  icon: ReactNode;
  title: string;
  description: string;
};

export function useQuickLinks() {
  const { isAdmin } = useActiveUser();

  return [
    {
      icon: <LuBuilding size={20} color="inherit" />,
      to: '/companies',
      title: 'Companies',
      description: 'View and manage all your companies',
    },
    {
      icon: <LuBookPlus size={20} color="inherit" />,
      to: '/companies/create',
      title: 'Create a company',
      description: 'Create a new company',
    },
    isAdmin && {
      icon: <LuUser size={20} color="inherit" />,
      to: '/users',
      title: 'Users',
      description: 'Manage users of Reg Direct',
    },
    isAdmin && {
      icon: <LuUserCheck size={20} color="inherit" />,
      to: '/users/unverified',
      title: 'Unverified users',
      description: 'Approve users that have requested access',
    },
    {
      icon: <LuShoppingCart size={20} color="inherit" />,
      to: '/marketplace',
      title: 'Marketplace listings',
      description: 'View companies listed on the marketplace',
    },
    {
      icon: <LuShoppingBasket size={20} color="inherit" />,
      to: '/marketplace/manage',
      title: 'Marketplace listings',
      description: 'Manage your marketplace listings',
    },
  ].filter((current) => !!current) as Item[];
}
