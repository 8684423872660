import { Modal, Select, Stack } from '@mantine/core';
import { useState } from 'react';
import { IndividualShareholderForm } from './individual-shareholder-form';
import { CompanyShareholderForm } from './company-shareholder-form';
import { CompanyTrustForm } from './company-trust-form';

const shareholderTypes = [
  { value: 'individual_shareholder', label: 'Individual' },
  { value: 'company_shareholder', label: 'Company' },
  { value: 'trust_shareholder', label: 'Trust' },
];

export const ShareholderModal = ({
  isOpen,
  companyId,
  onClose,
  onComplete,
  shareholderId,
  overrideType,
}: {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  companyId?: string;
  shareholderId?: string | null;
  overrideType?: string | null;
}) => {
  const [type, setType] = useState(shareholderTypes[0].value);
  const activeType = overrideType ?? type;
  return (
    <Modal
      size="lg"
      opened={isOpen}
      onClose={onClose}
      title={shareholderId ? 'Update shareholder' : 'Create shareholder'}
      centered
    >
      <Stack gap="md">
        <Select
          label="Shareholder type"
          data={shareholderTypes}
          value={activeType}
          disabled={!!overrideType}
          onChange={(value) => value && setType(value)}
        />
        {activeType === 'individual_shareholder' && (
          <IndividualShareholderForm
            shareholderId={shareholderId ?? undefined}
            companyId={companyId}
            onComplete={onComplete}
          />
        )}
        {activeType === 'company_shareholder' && (
          <CompanyShareholderForm
            shareholderId={shareholderId}
            companyId={companyId}
            onComplete={onComplete}
          />
        )}
        {activeType === 'trust_shareholder' && (
          <CompanyTrustForm
            shareholderId={shareholderId}
            companyId={companyId}
            onComplete={onComplete}
          />
        )}
      </Stack>
    </Modal>
  );
};
