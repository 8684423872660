import useSWR from 'swr';

export const useCountries = () =>
  useSWR<{
    data: {
      Name: string;
      Alpha2Code: string;
      Alpha3Code: string;
      NumericCode: number;
      Currency: string;
      CallingCode: string;
      ID: string;
    }[];
  }>('/countries?name=South Africa');
