import '@fontsource-variable/open-sans';
import '@mantine/core/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { MantineProvider } from '@mantine/core';
import { SWRConfig } from 'swr';
import { Notifications } from '@mantine/notifications';
import { Routes } from './routes';
import { theme } from './theme';
import { fetcher } from './lib/api';

import './providers.css';

export default function App() {
  return (
    <SWRConfig
      value={{
        fetcher,
        shouldRetryOnError: false,
      }}
    >
      <Auth0Provider
        domain="regdirect-develop.eu.auth0.com"
        clientId="biMfCft3tRbcs1KzZtNoM9MmCYyulAbU"
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <MantineProvider theme={theme}>
          <Notifications />
          <Routes />
        </MantineProvider>
      </Auth0Provider>
    </SWRConfig>
  );
}
