import { Modal } from '@mantine/core';
import { ListingForm } from './listing-form';

export function ListingModal({
  opened,
  close,
  onComplete,
}: {
  opened: boolean;
  close: () => void;
  onComplete: () => void;
}) {
  return (
    <Modal size="lg" opened={opened} onClose={close} title="Add listing" centered>
      <ListingForm onComplete={onComplete} />
    </Modal>
  );
}
