import { useNavigate, useParams } from 'react-router-dom';
import { FormLayout } from '../../shared/form-layout';
import { ResignSecretaryForm } from './resign-secretary-form';

export const ResignSecretary = () => {
  const navigate = useNavigate();
  const { id, directorId } = useParams<{ id: string; directorId: string }>();
  return (
    <FormLayout title="Resign Secretary">
      <ResignSecretaryForm
        id={directorId}
        companyId={id}
        onComplete={() => navigate(`/companies/${id}`)}
      />
    </FormLayout>
  );
};
