import React from 'react';
import {Box, Card, Stack, Text, TextInput, rem, Title, Group} from '@mantine/core';
import { NameChange } from '../../../types';

interface NameChangeViewProps {
  nameChange: NameChange;
  companyName: string;
  onCompanyNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function NameChangeView(props: NameChangeViewProps) {
  const { nameChange } = props;

  return (
    <>
      <Group align="start">
        <Stack w="50%">
          <Title order={5}>Required values</Title>
          <Box pos="relative" mb="lg">
            <Stack gap="sm" maw={rem(360)}>
              <TextInput
                required
                label="New Company Name"
                value={props.companyName}
                onChange={props.onCompanyNameChange}
              />
            </Stack>
          </Box>
        </Stack>
      </Group>
      <Title order={4} mb="md">
        Proposed Name changes
      </Title>
      <Box pos="relative">
        <Stack gap="sm" maw={rem(360)}>
          <TextInput readOnly label="First proposed name" value={nameChange.FirstProposedName} />
          <TextInput readOnly label="Second proposed name" value={nameChange.SecondProposedName} />
          <TextInput readOnly label="Third proposed name" value={nameChange.ThirdProposedName} />
          <TextInput readOnly label="Fourth proposed name" value={nameChange.FourthProposedName} />
        </Stack>
      </Box>
    </>
  );
}
