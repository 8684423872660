import { Modal, Text } from '@mantine/core';
import { UserForm } from './user-form';

export function CompleteProfileModal({
  isOpen,
  onComplete,
  userId,
}: {
  isOpen: boolean;
  onComplete: () => void;
  userId?: string | null;
}) {
  if (!userId) return null;
  return (
    <Modal
      size="lg"
      opened={isOpen}
      title="Complete user details"
      centered
      onClose={onComplete}
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <Text mb="sm">
        In order for you to use the Reg Direct platform we need some additional details.
      </Text>
      <UserForm userId={userId} onComplete={onComplete} />
    </Modal>
  );
}
