import { Avatar, Group, Title } from '@mantine/core';
import { useState } from 'react';
import { useUsers } from './use-users';
import { Table } from '../../shared/table';
import { UserModal } from './user-modal';
import { useAdminRedirectGuard } from '../../shared/use-admin-redirect-guard';
import { Contact, Identifier, User } from '../../types';

export function ActiveUsers() {
  useAdminRedirectGuard();
  const [activeUserId, setActiveUserId] = useState<string | null>(null);
  const { users, isLoading, mutate } = useUsers();

  const isEmpty = users?.length === 0 || !users;
  return (
    <>
      <Title order={2} mb="lg">
        Active users
      </Title>
      <Table
        isLoading={isLoading}
        isEmpty={isEmpty}
        columns={[
          {
            key: 'fullname',
            name: 'Full name',
            render: (row) => (
              <Group gap="xs" align="center">
                <Avatar
                  size="md"
                  radius="xl"
                  src={row.Name.Given ?? ''}
                  alt={row.Name.Family ?? ''}
                />
                <span>
                  {row.Name.Given ?? '-'} {row.Name.Family ?? ''}
                </span>
              </Group>
            ),
          },
          {
            key: 'id-number',
            name: 'ID/Passport number',
            render: (row) =>
              row.Identifiers?.find(
                (i: Identifier) => i.System === 'regdirect.com/identifiers/id-number'
              )?.Value ??
              row.Identifiers?.find(
                (i: Identifier) => i.System === 'regdirect.com/identifiers/passport-number'
              )?.Value ??
              '-',
          },
          {
            key: 'contact-number',
            name: 'Contact number',
            render: (row) =>
              row.Contacts?.find((c: Contact) => c.System === 'regdirect.com/contacts/phone-number')
                ?.Value ?? '-',
          },
          {
            key: 'email',
            name: 'Email',
            render: (row) =>
              row.Contacts?.find((c: Contact) => c.System === 'regdirect.com/contacts/email')
                ?.Value ?? '-',
          },
          {
            key: 'created-at',
            name: 'Created at',
            render: (row) => new Date(row.CreatedAt).toDateString(),
          },
          {
            key: 'updated-at',
            name: 'Updated at',
            render: (row) => new Date(row.UpdatedAt).toDateString(),
          },
        ]}
        data={users as User[]}
        rowActions={(row) => [
          {
            label: 'Edit',
            onClick: () => setActiveUserId(row.ID),
          },
        ]}
      />

      <UserModal
        userId={activeUserId}
        isOpen={!!activeUserId}
        onClose={() => setActiveUserId(null)}
        onComplete={() => {
          mutate();
          setActiveUserId(null);
        }}
      />
    </>
  );
}
