import { Modal } from '@mantine/core';
import { Company } from '../../types';
import { ChangeCompanyNameForm } from './change-company-name-form';

export function ChangeCompanyNameModal({
  isOpen,
  onClose,
  onAdd,
  company,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAdd: () => void;
  company: Company | null;
}) {
  return (
    <Modal size="md" opened={isOpen} onClose={onClose} title="Change Company Name" centered>
      {isOpen && (
        <ChangeCompanyNameForm
          company={company}
          onComplete={() => {
            onAdd();
            onClose();
          }}
        />
      )}
    </Modal>
  );
}
