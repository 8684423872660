import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { validation } from '../../utils/validation';
import { FormLoader } from '../../shared/form-loader';
import { useListing } from './use-listing';
import { countries } from '../../utils/countries';

export const ListingForm = ({
  onComplete,
  listingId,
}: {
  listingId?: string | null;
  onComplete?: () => void;
}) => {
  const { listing, create } = useListing(listingId);

  const schema = useMemo(() => {
    if (listingId && !listing) return null;

    const schema: Schema = [
      {
        name: 'name',
        type: FieldType.Text,
        label: 'Company Name',
        initialValue: listing?.Name ?? '',
        validate: validation.required,
      },
      {
        name: 'description',
        type: FieldType.Textarea,
        label: 'Company description',
        initialValue: listing?.Description ?? '',
        validate: validation.required,
      },
      {
        name: 'address',
        type: FieldType.Textarea,
        label: 'Company address',
        initialValue: listing?.Address ?? '',
        validate: validation.required,
      },
      {
        name: 'country',
        type: FieldType.Select,
        label: 'Country',
        initialValue: listing?.CountryCode ?? '',
        options: Object.keys(countries).map((code) => ({
          value: code,
          label: countries[code as keyof typeof countries],
        })),
        validate: validation.required,
      },
      {
        name: 'price',
        type: FieldType.Number,
        label: 'Asking price',
        initialValue: listing?.Price ?? '',
        validate: validation.required,
      },
      {
        name: 'currency',
        type: FieldType.Select,
        label: 'Currency',
        initialValue: listing?.Currency ?? '',
        options: [
          {
            value: 'ZAR',
            label: 'South African Rand (ZAR)',
          },
        ],
        validate: validation.required,
      },
      {
        name: 'businessStatus',
        type: FieldType.Text,
        label: 'Business status',
        description: 'Current status of the business e.g. active or dormant',
        initialValue: listing?.BusinessStatus ?? '',
      },
      {
        name: 'type',
        type: FieldType.Text,
        label: 'Type',
        description: 'Type of business e.g. private or public',
        initialValue: listing?.Type ?? '',
      },
      {
        name: 'industry',
        type: FieldType.Text,
        label: 'Industry',
        description: 'Industry the business operates in',
        initialValue: listing?.Industry ?? '',
      },
      {
        name: 'yearsTrading',
        type: FieldType.Number,
        label: 'Years trading',
        description: 'Number of years the business has been trading',
        initialValue: listing?.YearsTrading ?? '',
      },
      {
        name: 'monitization',
        type: FieldType.Text,
        label: 'Monitization',
        description: 'How the business makes money',
        initialValue: listing?.Monitization ?? '',
      },
    ];
    return schema;
  }, [listing]);

  if (!schema) {
    return <FormLoader />;
  }
  return (
    <DynamicForm
      schema={schema}
      onSubmit={async (values) => {
        await create({
          Name: values.name,
          Description: values.description,
          CountryCode: values.country,
          Address: values.address,
          Currency: values.currency,
          Price: values.price,
          Status: 'pending',
          BusinessStatus: values.businessStatus,
          Type: values.type,
          Industry: values.industry,
          YearsTrading: values.yearsTrading || 0,
          Monitization: values.monitization,
        });
        notifications.show({
          message: 'Listing saved successfully and is pending review',
          color: 'green',
        });
        onComplete && onComplete();
      }}
    />
  );
};
