import { Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useChange } from './use-change';
import { useCompany } from '../companies/use-company';
import {
  Box,
  Title,
  Text,
  Loader,
  Select,
  Group,
  Anchor,
  Stack,
  FileButton,
  Button,
  TextInput,
  Modal,
  Alert,
  Breadcrumbs,
  rem,
} from '@mantine/core';
import {
  AddressChange,
  CompanyRegistration,
  NameChange,
  Document,
  PersonChange,
} from '../../types';
import { notifications } from '@mantine/notifications';
import { NameChangeView } from './change-types/name-change';
import { Documents } from '../../shared/documents-list';
import { CompanyRegistrationView } from './change-types/company-registration';
import { PersonChangeView } from './change-types/person-change';
import { AddressChangeView } from './change-types/address-change';
import { post } from '../../lib/api';

import { LuAlertCircle } from 'react-icons/lu';
import { useActiveUser } from '../users/use-active-user';

export function RequestedChange() {
  const { id } = useParams<{ id: string }>();
  const { changeId } = useParams<{ changeId: string }>();
  const {
    isLoading,
    change,
    mutate,
    updateStatus,
    applyCompanyRegistrationChange,
    applyCompanyNameChange,
    applyPostalAddressChange,
    applyRegisteredAddressChange,
    applyDirectorChange,
    applyAuditorChange,
  } = useChange(changeId ? changeId : id);
  const [file, setFile] = useState<File | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const { isLoading: companyLoading, company } = useCompany(
    change?.CompanyID ?? (changeId ? id : '')
  );
  const [initialLoading, setInitialLoading] = useState(true);
  const resetRef = useRef<() => void>(null);
  const { isAdmin } = useActiveUser();
  const [status, setStatus] = useState<string>('');
  const [newCompanyName, setNewCompanyName] = useState('');
  const [enterpriseNumber, setEnterpriseNumber] = useState('');
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    // If both useChange and useCompany are not loading anymore, set initialLoading to false
    if (!isLoading && !companyLoading) {
      setInitialLoading(false);
      setStatus(change?.Status || '');
    }
  }, [isLoading, companyLoading]);

  // @ts-ignore
  const handleNewCompanyNameChange = (event) => {
    setNewCompanyName(event.target.value);
  };

  // @ts-ignore
  const handleEnterpriseNumberChange = (event) => {
    setEnterpriseNumber(event.target.value);
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const displayCompanyName = () => {
    if (change?.ChangeType.Description === 'Application To Proceed as a Business') {
      return (
        <Text style={{ display: 'inline-block' }} fw={700}>
          {(change?.Data as CompanyRegistration).NameChange.FirstProposedName}
        </Text>
      );
    }
    return (
      <Anchor component={Link} to={`/companies/${change?.CompanyID}`}>
        {company?.Name}
      </Anchor>
    );
  };

  const displayChangeDetails = () => {
    switch (change?.ChangeType.Description) {
      case 'Name Change':
        const nameChangeData = change?.Data as NameChange;
        return (
          <>
            <NameChangeView
              nameChange={nameChangeData}
              companyName={companyName}
              onCompanyNameChange={handleCompanyNameChange}
            />
          </>
        );
      case 'Postal Address Change':
        const postalAddressChangeData = change?.Data as AddressChange;
        return (
          <>
            <Title order={4} mb="md">
              Updated postal address
            </Title>
            <AddressChangeView addressChange={postalAddressChangeData} />
          </>
        );
      case 'Registered Address Change':
        const registeredAddressChangeData = change?.Data as AddressChange;
        return (
          <>
            <Title order={4} mb="md">
              Updated registered address
            </Title>
            <AddressChangeView addressChange={registeredAddressChangeData} />
          </>
        );
      case 'Application To Proceed as a Business':
        const registrationData = change?.Data as CompanyRegistration;
        return (
          <>
            <CompanyRegistrationView
              companyRegistration={registrationData}
              newCompanyName={newCompanyName}
              enterpriseNumber={enterpriseNumber}
              onNewCompanyNameChange={handleNewCompanyNameChange}
              onEnterpriseNumberChange={handleEnterpriseNumberChange}
            />
          </>
        );
      case 'Director/Member/Secretaty/Trus/Both Dir and Office':
        const dirData = change?.Data as PersonChange;
        return (
          <>
            <Title order={4} mb="md">
              Personnel change details
            </Title>
            <PersonChangeView personChange={dirData} />
          </>
        );
      case 'Auditor/Acc Officer':
        const auditorData = change?.Data as PersonChange;
        return (
          <>
            <Title order={4} mb="md">
              Person change details
            </Title>
            <PersonChangeView personChange={auditorData} />
          </>
        );
      default:
        return null;
    }
  };

  const displayDocuments = () => {
    let documents: Partial<Document>[] = [];
    // This will hold a Warning component if the number of documents does not match the expected
    let warning: JSX.Element | null = null;
    const icon = <LuAlertCircle />;
    switch (change?.ChangeType.Description) {
      case 'Application To Proceed as a Business':
        const registrationData = change?.Data as CompanyRegistration;
        documents = registrationData.Documents;
        if (documents) {
          documents = documents.filter((document) => document);
        } else {
          documents = [];
        }
        if (documents.length < registrationData.Directors.length * 2 || documents.length === 0) {
          warning = (
              <Alert variant="light" color="red" title="Documents missing" icon={icon}>
                The number of documents present does not match the expected. Please ensure both a
                ceritifed copy of the directors' IDs as well as proof of residence are uploaded.
              </Alert>
          );
        }
        break;
      case 'Director/Member/Secretaty/Trus/Both Dir and Office':
        const data = change?.Data as PersonChange;
        documents = data.Documents;
        if (documents.length < 4) {
          warning = (
            <Alert variant="light" color="red" title="Documents missing" icon={icon}>
              The number of documents present does not match the expected. Please ensure both a
              ceritifed copy of the director's ID as well as proof of residence are uploaded.
            </Alert>
          );
        }
        break;
      case 'Auditor/Acc Officer':
        const auditorData = change?.Data as PersonChange;
        documents = auditorData.Documents;
        if (documents.length < 4) {
          warning = (
            <Alert variant="light" color="red" title="Documents missing" icon={icon}>
              The number of documents present does not match the expected. Please ensure both a
              ceritifed copy of the auditor's ID as well as proof of residence are uploaded.
            </Alert>
          );
        }
        break;
      default:
        return null;
    }

    return (
      <>
        <Title order={4} mt="lg">
          Documents
        </Title>
        {warning}
        <Documents documents={documents} isLoading={false} />
      </>
    );
  };

  const handleStatusChange = async (
    value: 'Pending Review' | 'At CIPC' | 'Rejected' | 'Approved'
  ) => {
    setStatus(value);
    try {
      if (value === 'Approved') {
        switch (change?.ChangeType.Description) {
          case 'Name Change':
            if (companyName === '') {
              throw new Error('Company Name required.');
            }
            await applyCompanyNameChange(companyName);
            break;
          case 'Application To Proceed as a Business':
            if (newCompanyName === '' || enterpriseNumber === '') {
              throw new Error('Company Name and Enterprise number required.');
            }
            await applyCompanyRegistrationChange(enterpriseNumber, newCompanyName);
            break;
          case 'Postal Address Change':
            await applyPostalAddressChange();
            break;
          case 'Registered Address Change':
            await applyRegisteredAddressChange();
            break;
          case 'Director/Member/Secretaty/Trus/Both Dir and Office':
            await applyDirectorChange();
            break;
          case 'Auditor/Acc Officer':
            await applyAuditorChange();
            break;
          // eslint-disable-next-line no-fallthrough
          default:
            throw new Error('invalid change type');
          // return null;
        }
      } else {
        await updateStatus(value);
      }
      notifications.show({
        message: 'Change status updated successfully',
        color: 'green',
      });
    } catch (error) {
      setStatus(change?.Status || '');
      notifications.show({
        title: 'Error',
        message: 'Failed to update status',
        color: 'red',
      });
    }

    mutate();
  };

  const uploadDocument = useCallback(async () => {
    if (!file || !displayName) {
      notifications.show({
        title: 'Error',
        message: 'File and display name are required',
        color: 'red',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('display_name', displayName);

    try {
      const res = await post(`/changes/${changeId ? changeId : id}/document`, formData);
      notifications.show({
        title: 'Success',
        message: 'Document uploaded successfully',
        color: 'green',
      });
      setModalOpen(false);
      setDisplayName('');
      setFile(null);
      resetRef.current?.();
      mutate();
    } catch (e) {
      notifications.show({
        title: 'Error',
        message: 'Failed to upload document, please try again or contact an admin',
        color: 'red',
      });
    }
  }, [file, displayName, id]);

  return (
    <>
      {initialLoading ? (
        <Loader />
      ) : (
        <>
          {!changeId ? (
            <Breadcrumbs mb="xl">
              <Anchor component={Link} to="/requested-changes">
                Requested Changes
              </Anchor>
              <Text>{change?.ChangeType.Description ?? change?.ID ?? '-'}</Text>
            </Breadcrumbs>
          ) : (
            <Breadcrumbs mb="xl">
              <Anchor component={Link} to="/companies">
                Companies
              </Anchor>
              <Anchor component={Link} to={`/companies/${id}`}>
                {company?.Name ?? '-'}
              </Anchor>
              <Anchor component={Link} to={`/companies/${id}/requested-changes`}>
                Requested Changes
              </Anchor>
              <Text>{change?.ChangeType.Description ?? change?.ID ?? '-'}</Text>
            </Breadcrumbs>
          )}
          <Group justify="space-between">
            <Title order={3} mb="md">
              Change request
            </Title>
            <FileButton
              resetRef={resetRef}
              onChange={(file) => {
                setFile(file);
                setModalOpen(true);
              }}
            >
              {(props) => <Button {...props}>Upload document</Button>}
            </FileButton>
          </Group>
          <Group align="start">
            <Stack gap="sm" w="50%" mb="lg">
              <Text>
                {change?.ChangeType.Description} request for {displayCompanyName()}
              </Text>
              {change?.CreatedAt && (
                <Text>Created at: {new Date(change.CreatedAt).toLocaleDateString()}</Text>
              )}
              {change?.UpdatedAt && (
                <Text>Last updated at: {new Date(change.UpdatedAt).toLocaleDateString()}</Text>
              )}
            </Stack>
            <Stack>
              <Text>
                {status === 'Awaiting Payment' || !isAdmin ? (
                  <>
                    <strong>Status:</strong> <span>{status}</span>
                  </>
                ) : (
                  <>
                    <strong>Update status:</strong>{' '}
                    <Select
                      value={status}
                      onChange={(value) =>
                        handleStatusChange(
                          value as 'Pending Review' | 'At CIPC' | 'Rejected' | 'Approved'
                        )
                      }
                      data={[
                        { value: 'Pending Review', label: 'Pending Review' },
                        { value: 'At CIPC', label: 'At CIPC' },
                        { value: 'Rejected', label: 'Rejected' },
                        { value: 'Approved', label: 'Approved' },
                      ]}
                    />
                  </>
                )}
              </Text>
            </Stack>
          </Group>
          {displayChangeDetails()}
          {displayDocuments()}
        </>
      )}

      <Modal
        opened={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          setFile(null);
          setDisplayName('');
          resetRef.current?.();
        }}
        title="Document Details"
      >
        <TextInput
          label="Document Name"
          placeholder="Enter document name"
          value={displayName}
          onChange={(event) => setDisplayName(event.currentTarget.value)}
        />
        <Button onClick={uploadDocument} mt="md">
          Upload Document
        </Button>
      </Modal>
    </>
  );
}
