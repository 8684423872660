import React from 'react';
import { Box, Card, Stack, Text, TextInput, rem } from '@mantine/core';
import { AddressChange, Country } from '../../../types';
import { useCountries } from '../../../shared/use-countries';

interface AddressChangeViewProps {
  addressChange: AddressChange;
}

export function AddressChangeView(props: AddressChangeViewProps) {
  const { addressChange } = props;
  const { data: countries } = useCountries();

  return (
    <Box pos="relative">
      <Stack gap="sm" maw={rem(360)}>
        <TextInput readOnly label="Type" value={addressChange.Address?.Type} />
        <TextInput readOnly label="Line 1" value={addressChange.Address?.Line1} />
        <TextInput readOnly label="Line 2" value={addressChange.Address?.Line2 ?? ''} />
        <TextInput readOnly label="Line 3" value={addressChange.Address?.Line3 ?? ''} />
        <TextInput readOnly label="Line 4" value={addressChange.Address?.Line4 ?? ''} />
        <TextInput readOnly label="Postcode" value={addressChange.Address?.Postcode ?? ''} />
        <TextInput
          readOnly
          label="Country"
          value={
            (addressChange.Address?.Country as Country)?.Name ??
            countries?.data.find(
              (country) => country.ID === (addressChange.Address?.Country as Country)?.ID
            )?.Name ??
            addressChange.Address?.Country
          }
        />
      </Stack>
    </Box>
  );
}
