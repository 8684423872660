import { useForm } from '@mantine/form';
import { useMemo, useState } from 'react';
import {
  Alert,
  Box,
  BoxProps,
  Button,
  Checkbox,
  Fieldset,
  FileInput,
  Group,
  NumberInput,
  Select,
  Stack,
  TextInput,
  Textarea,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { DatePickerInput } from '@mantine/dates';
import { LuFile } from 'react-icons/lu';

export enum FieldType {
  Text = 'text',
  Email = 'email',
  Number = 'number',
  Checkbox = 'checkbox',
  Select = 'select',
  Textarea = 'textarea',
  Date = 'date',
  FileUpload = 'file-upload',
}

type Value = string | boolean | number | Date;

type Field = {
  name: string;
  type: FieldType;
  label: string;
  initialValue: Value;
  description?: string;
  placeholder?: string;
  show?: (values: any) => boolean;
  validate?: (value: string, allValues?: { [name: string]: Value }) => string | null;
  required?: boolean;
  options?: { value: string; label: string }[];
  prefix?: string;
  disabled?: boolean;
  multiple?: boolean;
};

type Fieldset = {
  type: 'fieldset';
  label: string;
  fields: Field[];
  show?: (values: any) => boolean;
};

export type Schema = (Fieldset | Field)[];

const getInitialValues = (schema: Schema) =>
  schema.reduce(
    (initialValues, field) => {
      if (field.type === 'fieldset') {
        field.fields.forEach((field) => {
          initialValues[field.name] = field.initialValue;
        });
      } else {
        initialValues[field.name] = field.initialValue;
      }
      return initialValues;
    },
    {} as { [name: string]: Value }
  );

const getCurrentValues = (schema: Schema) => {
  return schema.reduce(
    (values, field) => {
      if (field.type === 'fieldset') {
        field.fields.forEach((subField) => {
          values[subField.name] = subField.initialValue;
        });
      } else {
        values[field.name] = field.initialValue;
      }
      return values;
    },
    {} as { [name: string]: Value }
  );
};

const getValidation = (schema: Schema, values: { [name: string]: Value }) =>
  schema.reduce((validation, field) => {
    if (field.type === 'fieldset') {
      field.fields.forEach((subField) => {
        if (subField.validate || subField.required) {
          validation[subField.name] = (value: any, values: any) => {
            if (field.show && !field.show(values)) return null;
            if (subField.show && !subField.show(values)) return null;
            if (subField.type === FieldType.FileUpload) {
              if (subField.required && (!value || value.length === 0))
                return 'This field is required';
            }
            return subField.required && (!value || value === '')
              ? 'This field is required'
              : subField.validate
                ? subField.validate(value)
                : null;
          };
        }
      });
    } else if (field.validate || field.required) {
      validation[field.name] = (value: any, values: any) => {
        if (field.show && !field.show(values)) return null;
        if (field.type === FieldType.FileUpload) {
          if (field.required && (!value || value.length === 0)) return 'This field is required';
        }
        return field.required && (!value || value === '')
          ? 'This field is required'
          : field.validate
            ? field.validate(value)
            : null;
      };
    }
    return validation;
  }, {} as any);

export const DynamicForm = ({
  schema,
  onSubmit,
  errorMessage = 'Oops! Failed to save. Please try again.',
  buttonText = 'Save',
  ...rest
}: {
  schema: Schema;
  onSubmit: (values: any) => Promise<void>;
  // NOTE: Can be removed once we have a proper error structure on API
  errorMessage?: string;
  buttonText?: string;
} & BoxProps) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const initialValues = useMemo(() => getInitialValues(schema), []);
  const currentValues = useMemo(() => getCurrentValues(schema), []);
  const validate = useMemo(() => getValidation(schema, currentValues), []);

  const fileIcon = <LuFile style={{ color: 'var(--mantine-color-primary)' }} size={20} />;

  const form = useForm({
    initialValues,
    validate,
    validateInputOnBlur: true,
  });

  const errors = Object.values(form.errors);

  if (errors.length > 0) {
    console.log(errors);
  }

  return (
    <Box {...rest}>
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            setSubmitting(true);
            await onSubmit(values);
          } catch (error) {
            notifications.show({
              message: errorMessage,
              color: 'red',
            });
          } finally {
            setSubmitting(false);
          }
        })}
      >
        <Stack gap="md">
          {schema.map((field) => {
            if (field.type === 'fieldset') {
              if (field.show && !field.show(form.values)) return null;
              return (
                <Fieldset legend={field.label} key={field.label}>
                  <Stack gap="sm">
                    {field.fields.map((field) => {
                      if (field.show && !field.show(form.values)) return null;
                      if ([FieldType.Text, FieldType.Email].includes(field.type)) {
                        return (
                          <TextInput
                            disabled={field.disabled}
                            description={field.description}
                            key={field.name}
                            withAsterisk={!!field.required}
                            type={field.type}
                            label={field.label}
                            placeholder={field.placeholder}
                            prefix={field.prefix}
                            {...form.getInputProps(field.name)}
                          />
                        );
                      }

                      if (field.type === FieldType.Number) {
                        return (
                          <NumberInput
                            description={field.description}
                            key={field.name}
                            disabled={field.disabled}
                            withAsterisk={!!field.required}
                            label={field.label}
                            placeholder={field.placeholder}
                            prefix={field.prefix}
                            {...form.getInputProps(field.name)}
                          />
                        );
                      }

                      if (field.type === FieldType.Date) {
                        return (
                          <DatePickerInput
                            description={field.description}
                            key={field.name}
                            disabled={field.disabled}
                            withAsterisk={!!field.required}
                            label={field.label}
                            prefix={field.prefix}
                            {...form.getInputProps(field.name)}
                          />
                        );
                      }

                      if (field.type === FieldType.Textarea) {
                        return (
                          <Textarea
                            description={field.description}
                            key={field.name}
                            disabled={field.disabled}
                            withAsterisk={!!field.required}
                            label={field.label}
                            placeholder={field.placeholder}
                            prefix={field.prefix}
                            {...form.getInputProps(field.name)}
                          />
                        );
                      }

                      if (field.type === FieldType.Checkbox) {
                        return (
                          <Checkbox
                            description={field.description}
                            key={field.name}
                            disabled={field.disabled}
                            type={field.type}
                            label={field.label}
                            prefix={field.prefix}
                            {...form.getInputProps(field.name, { type: 'checkbox' })}
                          />
                        );
                      }

                      if (field.type === FieldType.Select) {
                        return (
                          <Select
                            description={field.description}
                            key={field.name}
                            searchable
                            disabled={field.disabled}
                            {...form.getInputProps(field.name)}
                            label={field.label}
                            placeholder={field.placeholder}
                            data={field.options || []}
                            prefix={field.prefix}
                            withAsterisk={!!field.required}
                          />
                        );
                      }

                      if (field.type === FieldType.FileUpload) {
                        return (
                          <FileInput
                            leftSection={fileIcon}
                            description={field.description}
                            key={field.name}
                            disabled={field.disabled}
                            label={field.label}
                            placeholder={field.placeholder}
                            prefix={field.prefix}
                            // if the option for "multiple" is true then we pass the value of the field.options
                            multiple={field.multiple}
                            {...form.getInputProps(field.name)}
                          />
                        );
                      }

                      return null;
                    })}
                  </Stack>
                </Fieldset>
              );
            }
            if (field.show && !field.show(form.values)) return null;

            if ([FieldType.Text, FieldType.Email].includes(field.type)) {
              return (
                <TextInput
                  description={field.description}
                  key={field.name}
                  disabled={field.disabled}
                  withAsterisk={!!field.required}
                  type={field.type}
                  label={field.label}
                  prefix={field.prefix}
                  placeholder={field.placeholder}
                  {...form.getInputProps(field.name)}
                />
              );
            }

            if (field.type === FieldType.Number) {
              return (
                <NumberInput
                  description={field.description}
                  key={field.name}
                  disabled={field.disabled}
                  withAsterisk={!!field.required}
                  label={field.label}
                  placeholder={field.placeholder}
                  prefix={field.prefix}
                  {...form.getInputProps(field.name)}
                />
              );
            }

            if (field.type === FieldType.Date) {
              return (
                <DatePickerInput
                  description={field.description}
                  key={field.name}
                  disabled={field.disabled}
                  withAsterisk={!!field.required}
                  label={field.label}
                  prefix={field.prefix}
                  {...form.getInputProps(field.name)}
                />
              );
            }

            if (field.type === FieldType.Textarea) {
              return (
                <Textarea
                  description={field.description}
                  key={field.name}
                  disabled={field.disabled}
                  withAsterisk={!!field.required}
                  label={field.label}
                  placeholder={field.placeholder}
                  prefix={field.prefix}
                  {...form.getInputProps(field.name)}
                />
              );
            }

            if (field.type === FieldType.Checkbox) {
              return (
                <Checkbox
                  description={field.description}
                  key={field.name}
                  disabled={field.disabled}
                  type={field.type}
                  label={field.label}
                  prefix={field.prefix}
                  {...form.getInputProps(field.name, { type: 'checkbox' })}
                />
              );
            }

            if (field.type === FieldType.Select) {
              return (
                <Select
                  description={field.description}
                  key={field.name}
                  searchable
                  disabled={field.disabled}
                  {...form.getInputProps(field.name)}
                  label={field.label}
                  placeholder={field.placeholder}
                  data={field.options || []}
                  prefix={field.prefix}
                  withAsterisk={!!field.required}
                />
              );
            }
            return null;
          })}
        </Stack>

        {errors.length > 0 && (
          <Alert title="Oops! This form has errors" p="sm" mt="lg">
            Please check the form and try again.
          </Alert>
        )}

        <Group justify="flex-end" mt="lg">
          <Button type="submit" loading={isSubmitting}>
            {buttonText}
          </Button>
        </Group>
      </form>
    </Box>
  );
};
