import { Link } from 'react-router-dom';
import { SimpleGrid, Title, Text, ThemeIcon, rem, Stack, Box, Alert, Paper } from '@mantine/core';
import classes from './home.module.css';
import { useQuickLinks } from '../../shared/use-quick-links';

export function Home() {
  const cards = useQuickLinks();
  const hasNoOptions = cards.length === 0;

  return (
    <>
      <Title mb={rem(20)}>Welcome to Reg Direct!</Title>
      {hasNoOptions ? (
        <Alert title="No quick links available" color="orange" variant="light">
          You do not have sufficient permissions to view any of the quick links.
        </Alert>
      ) : (
        <SimpleGrid
          spacing="sm"
          cols={{
            sm: 1,
            md: 2,
            lg: 3,
          }}
        >
          {cards.map((current) => (
            <Box key={current.to} component={Link} to={current.to} className={classes.card}>
              <Paper withBorder p="lg">
                <Stack gap={0} align="center" ta="center">
                  <ThemeIcon variant="light" size={40} radius={40} mb="sm">
                    {current.icon}
                  </ThemeIcon>
                  <Title size="h3">{current.title}</Title>
                  <Text size="sm">{current.description}</Text>
                </Stack>
              </Paper>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </>
  );
}
