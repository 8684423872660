import { useState } from 'react';
import { Button, Group, Paper } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../shared/table';
import { post } from '../../lib/api';
import { CreateCompanyInfoForm } from './create-company-info-form';
import { Company, Director, Identifier } from '../../types';
import { ChangeCompanyNameForm } from './change-company-name-form';
import { useChangeTypes } from '../../shared/use-change-types';
import { useBasket } from '../../shared/use-basket';
import { useActiveUser } from '../users/use-active-user';
import { AppointDirectorForm } from './appoint-director-form';
import { FormLayout } from '../../shared/form-layout';

type CompanyRegistraionData = {
  UserID: string;
  CompanyInfo: {
    EntityTypeID: string;
    Addresses: {
      Type: string;
      Line1: string;
      Line2: string;
      Line3: string;
      Line4: string;
      Postcode: string;
      CountryID: string;
    }[];
    FinancialYearEndMonth: number;
  };
  AuthorisedShareCount: number;
  NameChange: {
    FirstProposedName?: string;
    SecondProposedName?: string;
    ThirdProposedName?: string;
    FourthProposedName?: string;
  };
  Directors: {
    Director: Partial<Director>;
    Documents: {
      DisplayName: string;
      File: File;
    }[];
  }[];
};

export const CreateCompany = () => {
  const navigate = useNavigate();
  const { data: changeTypes } = useChangeTypes();
  const { user, claims } = useActiveUser();
  const { basket, checkout, addProduct, mutate: mutateBasket } = useBasket();
  const [step, setStep] = useState<'companyName' | 'companyDetails' | 'companyDirectors'>(
    'companyName'
  );
  const [showForm, setShowForm] = useState(false);

  const [formData, setFormData] = useState<CompanyRegistraionData>({
    UserID: user?.ID ?? '',
    CompanyInfo: {
      EntityTypeID: '',
      Addresses: [],
      FinancialYearEndMonth: 0,
    },
    AuthorisedShareCount: 0,
    NameChange: {},
    Directors: [
      {
        Director: {},
        Documents: [],
      },
    ],
  });

  const regCompanyChangeType = changeTypes?.data.find(
    (changeType) => changeType.Description === 'Application To Proceed as a Business'
  );

  const handleNext = () => {
    setStep((prev) => {
      if (prev === 'companyName') return 'companyDetails';
      if (prev === 'companyDetails') return 'companyDirectors';
      return prev;
    });
  };

  const handlePrev = () => {
    setStep((prev) => {
      if (prev === 'companyDirectors') return 'companyDetails';
      if (prev === 'companyDetails') return 'companyName';
      return prev;
    });
  };

  const handleCancel = () => {
    setShowForm(false);
  };

  const handleSubmit = async () => {
    // Remove any empty {} from the Directors array of the formData
    // and separate the documents for each director aggregated into a separate array
    const data = { ...formData, Directors: formData.Directors.filter((d) => d.Director?.Name) };
    const directorsDocuments = data.Directors.map((director) => director.Documents);
    let changeID: string;
    try {
      const res = await post('/companies/changes', {
        ChangeTypeID: regCompanyChangeType?.ID,
        ObjectType: 'company',
        CompanyID: null,
        Data: data,
      });

      try {
        addProduct({
          ChangeID: res.data.ID,
          ProductType: 0,
          Quantity: 1,
        });
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to add company registration request to basket',
          color: 'red',
        });
        return;
      } finally {
        mutateBasket();
      }
      // changeID = res.data.ID;
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to submit company registration request',
        color: 'red',
      });
      return;
    }

    notifications.show({
      title: 'Success',
      message: 'Company registraion request submitted',
      color: 'green',
    });

    const checkoutUrl = await checkout();
    // redirect the user to the checkout page
    window.location.href = checkoutUrl;
    // navigate(`/requested-changes/${changeID}`);
  };

  const renderStep = () => {
    switch (step) {
      case 'companyName':
        return (
          <FormLayout title="Company Name" description="Enter the proposed names for your company">
            <ChangeCompanyNameForm
              nameChange={{
                FirstProposedName: formData.NameChange?.FirstProposedName ?? '',
                SecondProposedName: formData.NameChange?.SecondProposedName ?? '',
                ThirdProposedName: formData.NameChange?.ThirdProposedName ?? '',
                FourthProposedName: formData.NameChange?.FourthProposedName ?? '',
              }}
              onCreate={(data) => {
                setFormData({ ...formData, NameChange: data });
                handleNext();
              }}
            />
          </FormLayout>
        );
      case 'companyDetails':
        return (
          <FormLayout
            title="Company Details"
            description="Enter the details of your company"
            onBack={handlePrev}
          >
            <CreateCompanyInfoForm
              companyInfo={formData.CompanyInfo}
              authorisedShareCount={formData.AuthorisedShareCount}
              onCreate={(companyInfo, authorisedShareCount) => {
                setFormData({
                  ...formData,
                  CompanyInfo: companyInfo,
                  AuthorisedShareCount: authorisedShareCount,
                });
                handleNext();
              }}
            />
          </FormLayout>
        );
      case 'companyDirectors':
        return (
          <>
            {showForm ? (
              <div style={{ width: '50%' }}>
                <h1>Appoint Company Directors</h1>
                <AppointDirectorForm
                  onCreate={(data) => {
                    setFormData({
                      ...formData,
                      Directors: [data, ...formData.Directors],
                    });
                    setShowForm(false);
                  }}
                />
                <Group justify="flex-end" mt="lg">
                  <Button onClick={handleCancel}>Cancel</Button>
                </Group>
              </div>
            ) : (
              <>
                <h1>Directors</h1>
                <Table
                  isEmpty={formData.Directors?.length === 0}
                  data={
                    formData?.Directors.map((director) => ({
                      ...(director.Director as Partial<Director>),
                    })) as Director[]
                  }
                  columns={[
                    {
                      key: 'Name',
                      name: 'Name',
                      render: (director: Director) => (
                        <>
                          {director.Name ? (
                            <>
                              {director.Name.Given} {director.Name.Family} ({director.Name.Use})
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ),
                    },
                    {
                      key: 'Add/Remove',
                      name: 'Add/Remove',
                      render: (director: Director) => (
                        <>
                          {director.Name ? (
                            <Button
                              onClick={() => {
                                // Loop through formData.Directors and remove the director with the matching Name
                                const updatedDirectors = formData.Directors.filter(
                                  (d) => d.Director?.Name !== director.Name
                                );
                                setFormData({ ...formData, Directors: updatedDirectors });
                              }}
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button onClick={() => setShowForm(true)}>Add</Button>
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Paper>
      {renderStep()}
      <div>
        {!showForm && step === 'companyDirectors' && (
          <>
            <Button onClick={handlePrev} style={{ marginRight: '8px' }}>
              Prev
            </Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </>
        )}
      </div>
    </Paper>
  );
};
