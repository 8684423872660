import { Modal } from '@mantine/core';
import { ShareClassForm } from './share-class-form';
import { ShareClass } from '../../types';

export function AddShareClassModal({
  isOpen,
  onClose,
  onAdd,
  companyId,
  shareClass,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAdd: () => void;
  companyId?: string | null;
  shareClass: null | ShareClass;
}) {
  if (!companyId) return null;
  return (
    <Modal size="md" opened={isOpen} onClose={onClose} title="Add share class" centered>
      {isOpen && (
        <ShareClassForm
          companyId={companyId}
          shareClass={shareClass}
          onComplete={() => {
            onAdd();
            onClose();
          }}
        />
      )}
    </Modal>
  );
}
