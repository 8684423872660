import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { validation } from '../../utils/validation';
import { useShareClass } from './use-share-class';
import { useShareTypes } from '../../shared/use-share-types';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { FormLoader } from '../../shared/form-loader';
import { ShareClass } from '../../types';

export function ShareClassForm({
  companyId,
  shareClass,
  onComplete,
}: {
  companyId?: string;
  shareClass: ShareClass | null;
  onComplete?: () => void;
}) {
  const { add, update } = useShareClass();
  const { data: shareTypes } = useShareTypes();

  const schema = useMemo(() => {
    if (!shareTypes) return null;
    const isEdit = !!shareClass;

    const shareTypeOptions = shareTypes.data.map((shareType) => ({
      label: shareType.Description,
      value: shareType.ID,
    }));
    const schema: Schema = [
      {
        type: FieldType.Date,
        name: 'dateCreated',
        label: 'Date Created',
        initialValue: shareClass?.DateCreated ? new Date(shareClass.DateCreated) : new Date(),
        validate: validation.required,
        disabled: isEdit,
      },
      {
        type: FieldType.Text,
        name: 'name',
        label: 'Name',
        initialValue: shareClass?.Name || '',
        validate: isEdit ? undefined : validation.required,
        disabled: isEdit,
      },
      {
        type: FieldType.Select,
        name: 'shareId',
        label: 'Type of Share',
        placeholder: 'Select share type',
        initialValue: shareTypeOptions[0].value,
        validate: validation.required,
        show: () => !isEdit,
        options: shareTypeOptions,
      },
      {
        type: FieldType.Textarea,
        name: 'description',
        label: 'Description',
        initialValue: shareClass?.Description || '',
        validate: isEdit ? undefined : validation.required,
        disabled: isEdit,
      },
      {
        type: FieldType.Number,
        name: 'totalShares',
        label: 'Total Authorised',
        initialValue: shareClass?.TotalShares ?? 0,
        validate: validation.required,
      },
      {
        type: FieldType.Number,
        name: 'parValue',
        label: 'Par Value',
        initialValue: shareClass?.ParValue ?? 0,
        validate: isEdit ? undefined : validation.required,
        disabled: isEdit,
        prefix: 'R',
      },
      {
        type: FieldType.Number,
        name: 'shareValue',
        label: 'Share Value',
        initialValue: shareClass?.ShareValue ?? 0,
        disabled: isEdit,
        validate: isEdit ? undefined : validation.required,
      },
      {
        type: FieldType.Number,
        name: 'premium',
        label: 'Premium',
        initialValue: shareClass?.Premium ?? 0,
        disabled: isEdit,
        validate: isEdit ? undefined : validation.required,
      },
    ];
    return schema;
  }, [shareTypes, shareClass]);

  if (!schema) {
    return <FormLoader />;
  }

  return (
    <DynamicForm
      schema={schema}
      onSubmit={async (values) => {
        if (shareClass) {
          await update(shareClass.ID, values.totalShares);
        } else {
          await add(companyId!, {
            ShareID: values.shareId,
            TotalShares: values.totalShares,
            ParValue: values.parValue,
            ShareValue: values.shareValue,
            Premium: values.premium,
            Name: values.name,
            Description: values.description,
            DateCreated: values.dateCreated.toISOString(),
          });
        }

        notifications.show({
          message: 'Share class saved successfully',
          color: 'green',
        });
        if (onComplete) onComplete();
      }}
    />
  );
}
