import useSWR from 'swr';
import { useDirectorStatuses } from '../../shared/use-director-statuses';
import { post } from '../../lib/api';
import { useChangeTypes } from '../../shared/use-change-types';
import { Director } from '../../types';

const CHANGE_CODE = '35';

interface AppointPayload {
  ChangeTypeID: string;
  ObjectType: string;
  CompanyID: string;
  DirectorID: string;
  ActionType: string;
  Data: {
    Director: {
      Name: {
        Use: string;
        Given: string;
        Family: string;
      };
      AppointedAt: string;
      CountryOfOriginID: string;
      TypeID: string;
      StatusID: string;
      OccupationLevelID: string;
      DesignationID: string;
      Occupation: string;
      UserID: string | null;
      Contacts: {
        System: string;
        Value: string;
      }[];
      Addresses: {
        Type: string;
        Line1: string;
        Line2: string;
        CountryID: string;
        Postcode: string;
      }[];
      Identifiers: {
        System: string;
        Value: string;
        Type: string;
      }[];
    };
    Documents: any[];
  };
}

export const useDirectors = (
  companyId?: string,
  typeFilter: 'officer' | 'companySecretary' | 'director' = 'director'
) => {
  const { data: changeTypes } = useChangeTypes();
  const { data: directorStatuses } = useDirectorStatuses();

  const { data, isLoading, mutate } = useSWR<{
    data: Director[];
  }>(companyId && typeFilter ? `/companies/${companyId}/directors?filter=${typeFilter}` : null);

  const appoint = async (data: {
    CompanyID: string;
    DirectorID: string;
    GivenName: string;
    FamilyName: string;
    DirectorTypeID: string;
    OccupationLevelID: string;
    TypeID: string;
    StatusID: string;
    Occupation: string;
    DirectorStatusID: string;
    UserID: string | null;
    CountryOfOriginID: string;
    AppointedAt: string;
    BirthDate: string;
    IDNumber: string;
    Email: string;
    PhoneNumber: string;
    AddressLine1: string;
    AddressLine2: string;
    AddressCountryID: string;
    AddressPostcode: string;
    Documents: {
      DisplayName: string;
      File: File;
    }[];
  }) => {
    const changeType = changeTypes?.data.find((current) => current.Code === CHANGE_CODE);
    const body: AppointPayload = {
      ActionType: 'Appointment',
      ObjectType: 'company',
      ChangeTypeID: changeType?.ID!,
      CompanyID: data.CompanyID,
      DirectorID: data.DirectorID,
      Data: {
        Director: {
          Name: {
            Use: 'usual',
            Given: data.GivenName,
            Family: data.FamilyName,
          },
          TypeID: data.DirectorTypeID,
          StatusID: data.DirectorStatusID,
          AppointedAt: data.AppointedAt,
          CountryOfOriginID: data.CountryOfOriginID,
          OccupationLevelID: data.OccupationLevelID,
          // TODO: what is this?
          DesignationID: '3c7d612f-e09d-4b13-b35c-2ab4c57a214f',
          Occupation: data.Occupation,
          UserID: data.UserID,
          Contacts: [
            {
              System: 'regdirect.com/contacts/email',
              Value: data.Email,
            },
            {
              System: 'regdirect.com/contacts/phone-number',
              Value: data.PhoneNumber,
            },
          ],
          Addresses: [
            {
              Type: 'Residential',
              Line1: data.AddressLine1,
              Line2: data.AddressLine2,
              CountryID: data.AddressCountryID,
              Postcode: data.AddressPostcode,
            },
          ],
          Identifiers: [
            {
              System: 'regdirect.com/identifiers/id-number',
              Value: data.IDNumber,
              Type: 'ID Number',
            },
          ],
        },
        Documents: [],
      },
    };
    const result = await post('/companies/changes', body);

    if (result?.data?.ID) {
      for (const document of data.Documents) {
        const formData = new FormData();
        formData.append('file', document.File);
        formData.append('display_name', document.DisplayName);
        await post(`/changes/${result?.data?.ID}/document`, formData);
      }
    }
    return result;
  };

  const resign = async (data: {
    ID: string;
    CompanyID: string;
    DirectorID: string;
    EndAt: string;
  }) => {
    const changeType = changeTypes?.data.find((current) => current.Code === CHANGE_CODE);
    const directorStatusId = directorStatuses?.data.find((current) => current.Code === 'C')?.ID;
    const body = {
      ChangeTypeID: changeType?.ID!,
      ActionType: 'Resignation',
      CompanyID: data.CompanyID,
      DirectorID: data.DirectorID,
      Data: {
        Director: {
          ID: data.ID,
          StatusID: directorStatusId!,
          ResignedAt: data.EndAt,
        },
        Documents: [],
      },
    };
    return post('/companies/changes', body);
  };

  return {
    data: data?.data,
    isLoading,
    mutate,
    appoint,
    resign,
  };
};
