export enum AddressType {
  Residential = 'Residential',
  Physical = 'Physical',
  Postal = 'Postal',
  Registered = 'Registered',
  Business = 'Business',
  Other = 'Other',
}

// Keep a map of FinancialYearEndMonth to month name for use
// in a list of options where the month name should be displayed,
// but the value should be the month number.
export const FinancialYearEndMonths = [
  {
    month: 'January',
    value: 1,
  },
  {
    month: 'February',
    value: 2,
  },
  {
    month: 'March',
    value: 3,
  },
  {
    month: 'April',
    value: 4,
  },
  {
    month: 'May',
    value: 5,
  },
  {
    month: 'June',
    value: 6,
  },
  {
    month: 'July',
    value: 7,
  },
  {
    month: 'August',
    value: 8,
  },
  {
    month: 'September',
    value: 9,
  },
  {
    month: 'October',
    value: 10,
  },
  {
    month: 'November',
    value: 11,
  },
  {
    month: 'December',
    value: 12,
  },
];

export enum ChangeType {
  AuditorAccountOfficer = '08',
}

export enum ChangeTypeDescription {
  Name = 'Name Change',
  RegisteredAddress = 'Registered Address Change',
  PostalAddress = 'Postal Address Change',
}

export enum ChangeStatus {
  AtCIPC = 'At CIPC',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  ApprovedByCIPC = 'Approved By CIPC',
  RejectedByCIPC = 'Rejected By CIPC',
}

export enum Identifier {}
// Enums represent the system identifier strings:
// const enterpriseNumber = company?.Identifiers.find(
//   (current) => current.System === 'regdirect.com/identifiers/enterprise-number'
// );
// const oldEnterpriseNumber = company?.Identifiers.find(
//   (current) => current.System === 'regdirect.com/identifiers/old-enterprise-number'
// );
// const incomeTaxNumber = company?.Identifiers.find(
//   (current) => current.System === 'regdirect.com/identifiers/tax-number'
// );
// const payeNumber = company?.Identifiers.find(
//   (current) => current.System === 'regdirect.com/identifiers/paye-number'
// );
// const uifNumber = company?.Identifiers.find(
//   (current) => current.System === 'regdirect.com/identifiers/uif-number'
// );
// const uifReferenceNumber = company?.Identifiers.find(
//   (current) => current.System === 'regdirect.com/identifiers/uif-reference-number'
// );
// const vatNumber = company?.Identifiers.find(
//   (current) => current.System === 'regdirect.com/identifiers/vat-number'
// );
// const compensationFundNumber = company?.Identifiers.find(
//   (current) => current.System === 'regdirect.com/identifiers/compensation-fund-number'
// );
// Todo: copilot generation here

export enum AllocationStatus {
  AllocationCancelled = 'AllocationCancelled',
  AllocationActive = 'AllocationActive',
  AllocationRedeemed = 'AllocationRedeemed',
  AllocationRepurchased = 'AllocationRepurchased',
  AllocationInactive = 'AllocationInactive',
}
