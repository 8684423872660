import useSWR from 'swr';
import { ChangeType } from '../../types';
import { patch, post } from '../../lib/api';

export const useChange = (id: string | undefined) => {
  const {
    data: changeResult,
    isLoading,
    mutate: mutateChange,
  } = useSWR<{
    data: {
      ChangeTypeID: string;
      CompanyID: string;
      DirectorID: string;
      Data: ChangeType;
      CreatedAt: string;
      UpdatedAt: string;
      DeletedAt: string | null;
      ID: string;
      ChangeType: ChangeType;
      Status: string;
    };
  }>(id ? `/changes/${id}` : null);

  const applyCompanyRegistrationChange = async (enterpriseNumber: string, name: string) => {
    const payload = { enterpriseNumber, name };
    return post(`/companies/register/changes/${id}`, payload);
  };

  const applyCompanyNameChange = async (name: string) => {
    const payload = { name };
    return patch(`/companies/name/changes/${id}`, payload);
  };

  const applyPostalAddressChange = async () => patch(`/companies/address/changes/${id}`);

  const applyRegisteredAddressChange = async () => patch(`/companies/address/changes/${id}`);

  const applyDirectorChange = async () => post(`/directors/changes/${id}`);

  const applyAuditorChange = async () => post(`/auditors/changes/${id}`);

  const updateStatus = async (status: 'Pending Review' | 'At CIPC' | 'Rejected' | 'Approved') => {
    return patch(`/changes/${id}/change-status?status=${status}`);
  };

  return {
    change: changeResult?.data,
    isLoading,
    mutate: mutateChange,
    updateStatus,
    applyCompanyRegistrationChange,
    applyCompanyNameChange,
    applyRegisteredAddressChange,
    applyPostalAddressChange,
    applyDirectorChange,
    applyAuditorChange,
  };
};
