import { Link } from 'react-router-dom';
import { SimpleGrid, Title, Text, ThemeIcon, rem, Stack, Box, Paper, Button } from '@mantine/core';
import { LuXCircle } from 'react-icons/lu';
import classes from '../home/home.module.css';

export function StripeCancel() {
  return (
    <>
      <Title mb={rem(20)}>Payment Cancelled</Title>
      <SimpleGrid spacing="sm" cols={{ sm: 1, md: 1 }}>
        <Box component={Link} to="/" className={classes.card}>
          <Paper withBorder p="lg">
            <Stack gap={0} align="center" ta="center">
              <ThemeIcon variant="light" size={40} radius={40} mb="sm" color="red">
                <LuXCircle size={24} />
              </ThemeIcon>
              <Title size="h3">Payment Cancelled</Title>
              <Text size="sm">Your payment was unsuccessful.</Text>
              <Button component={Link} to="/" variant="outline" color="red" mt="md">
                Return to Home
              </Button>
            </Stack>
          </Paper>
        </Box>
      </SimpleGrid>
    </>
  );
}
