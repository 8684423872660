import { useNavigate, useParams } from 'react-router-dom';
import { FormLayout } from '../../shared/form-layout';
import { ResignAuditorForm } from './resign-auditor-form';

export const ResignAuditor = () => {
  const navigate = useNavigate();
  const { id, auditorId } = useParams<{ id: string; auditorId: string }>();
  return (
    <FormLayout title="Resign auditor">
      <ResignAuditorForm
        auditorId={auditorId}
        companyId={id}
        onComplete={() => navigate(`/companies/${id}`)}
      />
    </FormLayout>
  );
};
