import React, { useState } from 'react';
import { Modal, Radio, Stack, Button, Group, Stepper, ActionIcon, Title, Text } from '@mantine/core';
import { LuArrowLeft } from 'react-icons/lu';
import { CancelSharesForm } from './cancel-shares-form';
import { RedeemSharesForm} from './redeem-shares-form';
import {BuybackSharesForm} from "./buyback-shares-form";

export function ReorganiseSharesModal({
                                       isOpen,
                                       onClose,
                                       onAdd,
                                       companyId,
                                   }: {
    isOpen: boolean;
    onClose: () => void;
    onAdd: () => void;
    companyId?: string | null;
}) {
    if (!companyId) return null;

    // State to manage the selected radio button
    const [selectedOption, setSelectedOption] = useState('');
    const [currentStep, setCurrentStep] = useState(0); // 0 for original view, 1 for CancelSharesForm

    // Function to handle radio button change
    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
    };

    const handleNextClick = () => {
        if (selectedOption !== '') {
            setCurrentStep(1);
        }
    };

    const handleModalClose = () => {
      onClose();
      setCurrentStep(0);
    };

    const handleBackClick = () => {
      if (currentStep === 0) {
        handleModalClose();
      } else {
        setCurrentStep(0);
        setSelectedOption('');
      }
    };

  const options = [
    { value: 'cancelShares', label: 'Cancel Shares' },
    { value: 'redeemShares', label: 'Redeem Shares' },
    { value: 'purchaseOwnShares', label: 'Purchase Own Shares (Buyback)' },
  ];

  return (
    <Modal
      size="md"
      opened={isOpen}
      onClose={handleModalClose}
      title={
        <Modal.Header>
          <ActionIcon variant="default" mr={10} onClick={handleBackClick}>
            <LuArrowLeft />
          </ActionIcon>
          <Modal.Title>
            <Title order={3}>{selectedOption !== '' && currentStep === 1 ? options?.find(o => o.value === selectedOption)?.label ?? '' : 'Reorganise shares'}</Title>
          </Modal.Title>
        </Modal.Header>
      }
      centered
    >
      <Stepper active={currentStep} onStepClick={setCurrentStep}>
        <Stepper.Step label="Select">
          <Stack gap="md">
            <Radio.Group
              aria-label="reorganise-options"
              name="reorganise-options"
              value={selectedOption}
              onChange={handleOptionChange}
            >
                <Radio value={options[0].value} label={options[0].label} />
                <Radio value={options[1].value} label={options[1].label} />
                <Radio value={options[2].value} label={options[2].label} />
                {/* Add more Radio components as needed */}
            </Radio.Group>
            <Button onClick={handleNextClick}>
                Next
            </Button>
          </Stack>
        </Stepper.Step>
        <Stepper.Step label="Details" disabled={currentStep !== 1}>
            {selectedOption === 'cancelShares' &&
                  <CancelSharesForm companyId={companyId} onComplete={handleModalClose} />
            }
            {selectedOption === 'redeemShares' &&
                <RedeemSharesForm companyId={companyId} onComplete={handleModalClose} />
            }
          {selectedOption === 'purchaseOwnShares' &&
              <BuybackSharesForm companyId={companyId} onComplete={handleModalClose} />
          }
        </Stepper.Step>
      </Stepper>
    </Modal>
    );
}
