import { ActionIcon, Menu, Table } from '@mantine/core';
import { LuMoreVertical } from 'react-icons/lu';

export interface ActionCell {
  label: string;
  onClick: () => void;
}

export const TableActionsCell = ({ actions }: { actions: ActionCell[] }) => {
  if (actions.length === 0) return null;
  return (
    <Table.Td align="right">
      <Menu shadow="md">
        <Menu.Target>
          <ActionIcon variant="transparent" color="light">
            <LuMoreVertical size={20} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {actions.map((action) => (
            <Menu.Item key={action.label} onClick={action.onClick}>
              {action.label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Table.Td>
  );
};
