import { ActionIcon, Menu, Table, Text, Title } from '@mantine/core';
import { LuMoreVertical } from 'react-icons/lu';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { TableLoadingRows } from '../../shared/table-loading-rows';
import { TableWrapper } from '../../shared/table-wrapper';
import { useUnverifiedUsers } from './use-unverified-users';
import { ConfirmationModal } from '../../shared/confirmation-modal';
import { useAdminRedirectGuard } from '../../shared/use-admin-redirect-guard';

export function UnverifiedUsers() {
  useAdminRedirectGuard();
  const [activeUserId, setActiveUserId] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { unverifiedUsers, isLoading, activeUser } = useUnverifiedUsers();

  async function handleActivate() {
    try {
      if (!activeUserId) return;
      await activeUser(activeUserId);
      notifications.show({
        message: 'User activated. Check the active users section.',
        color: 'green',
      });
    } catch (error) {
      notifications.show({
        message: 'Oops! Failed to activate user. Please try again.',
        color: 'red',
      });
    }
  }

  const isEmpty = unverifiedUsers?.length === 0;
  return (
    <>
      <Title order={2} mb="lg">
        Unverified users
      </Title>
      <TableWrapper>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Email</Table.Th>
            <Table.Th>Created at</Table.Th>
            <Table.Th>Updated at</Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {isLoading ? (
            <TableLoadingRows columns={4} rows={5} />
          ) : isEmpty ? (
            <Table.Tr>
              <Table.Td colSpan={4} align="center" py="xl">
                <Text fw={500}>No unverified users</Text>
              </Table.Td>
            </Table.Tr>
          ) : (
            unverifiedUsers?.map((user) => (
              <Table.Tr key={user.ID}>
                <Table.Td>
                  {user.Contacts.find((c) => c.System === 'regdirect.com/contacts/email')?.Value}
                </Table.Td>
                <Table.Td>{new Date(user.CreatedAt).toDateString()}</Table.Td>
                <Table.Td>{new Date(user.UpdatedAt).toDateString()}</Table.Td>
                <Table.Td align="right">
                  <Menu shadow="md">
                    <Menu.Target>
                      <ActionIcon variant="transparent" color="light">
                        <LuMoreVertical size={20} />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        onClick={() => {
                          setActiveUserId(user.ID);
                          setShowConfirmation(true);
                        }}
                      >
                        Activate
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Table.Td>
              </Table.Tr>
            ))
          )}
        </Table.Tbody>
      </TableWrapper>
      <ConfirmationModal
        title="Activate user"
        isOpen={showConfirmation}
        onClose={() => {
          setActiveUserId(null);
          setShowConfirmation(false);
        }}
        onConfirm={handleActivate}
        description="Are you sure you want to activate this user?"
      />
    </>
  );
}
