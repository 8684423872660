import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useActiveUser } from '../features/users/use-active-user';

export const useAdminRedirectGuard = () => {
  const navigate = useNavigate();
  const { isAdmin, isInitialized } = useActiveUser();
  useEffect(() => {
    if (isInitialized && !isAdmin) navigate('/');
  }, [isAdmin, navigate, isInitialized]);
};
