import { Modal } from '@mantine/core';
import { UserForm } from './user-form';

export function UserModal({
  isOpen,
  onClose,
  userId,
  onComplete,
}: {
  isOpen: boolean;
  onClose: () => void;
  userId?: string | null;
  onComplete?: () => void;
}) {
  if (!userId) return null;
  return (
    <Modal size="lg" opened={isOpen} onClose={onClose} title="User details" centered>
      <UserForm userId={userId} onComplete={onComplete} />
    </Modal>
  );
}
