import useSWR from 'swr';

export const useProfessionalCodes = () =>
  useSWR<{
    data: {
      Code: string;
      Description: string;
      ID: string;
    }[];
  }>('/professional-codes');
