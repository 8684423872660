import useSWR from 'swr';
import { Company } from '../../types';
import { post } from '../../lib/api';

export const useUserCompanies = ({ companyIDs }: { companyIDs: string[] }) => {
  const { data, isLoading, mutate } = useSWR<{
    data: Company[];
  }>(
    companyIDs
      ? `/user-companies?page=1&page_size=100&companyIDs=${companyIDs.join('&companyIDs=')}`
      : null
  );

  return {
    companies: data?.data,
    isLoading: isLoading,
    mutate: mutate,
  };
};
