import React from 'react';
import { Box, Group, Loader, Stack, Text, TextInput, Title, rem } from '@mantine/core';
import { Address, CompanyRegistration, Country, Director, Identifier } from '../../../types';
import { formatAddress } from '../../../utils/format-address';
import { Table } from '../../../shared/table';
import { useCountries } from '../../../shared/use-countries';

interface CompanyRegistrationViewProps {
  companyRegistration: CompanyRegistration;
  newCompanyName: string;
  enterpriseNumber: string;
  onNewCompanyNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterpriseNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CompanyRegistrationView(props: CompanyRegistrationViewProps) {
  const { companyRegistration } = props;
  const { data: countries, isLoading } = useCountries();

  if (isLoading || !countries) {
    return <Loader />;
  }

  return (
    <>
      <Group align="start">
        <Stack w="50%">
          <Title order={5}>Required values</Title>
          <Box pos="relative" mb="lg">
            <Stack gap="sm" maw={rem(360)}>
              <TextInput
                required
                label="New Company Name"
                value={props.newCompanyName}
                onChange={props.onNewCompanyNameChange}
              />
              <TextInput
                required
                label="Enterprise Number"
                value={props.enterpriseNumber}
                onChange={props.onEnterpriseNumberChange}
              />
            </Stack>
          </Box>
        </Stack>
      </Group>
      <Title order={4} mb="md">
        Company registration details
      </Title>
      <Group align="start">
        <Stack w="50%">
          <Title order={5}>Proposed names</Title>
          <Box pos="relative" mb="lg">
            <Stack gap="sm" maw={rem(360)}>
              <TextInput
                readOnly
                label="First proposed name"
                value={companyRegistration.NameChange?.FirstProposedName || ''}
              />
              <TextInput
                readOnly
                label="Second proposed name"
                value={companyRegistration.NameChange?.SecondProposedName || ''}
              />
              <TextInput
                readOnly
                label="Third proposed name"
                value={companyRegistration.NameChange?.ThirdProposedName || ''}
              />
              <TextInput
                readOnly
                label="Fourth proposed name"
                value={companyRegistration.NameChange?.FourthProposedName || ''}
              />
            </Stack>
          </Box>
        </Stack>
        <Stack>
          <Title order={5}>Authorised shares</Title>
          <TextInput
            readOnly
            label="Authorised share count"
            value={companyRegistration.AuthorisedShareCount || ''}
          />
          <Title order={5}>Financial year end</Title>
          <TextInput
            readOnly
            label="Financial year end month"
            value={companyRegistration.CompanyInfo.FinancialYearEndMonth || ''}
          />
        </Stack>
      </Group>
      <Title order={5} mb="sm">
        Addresses
      </Title>
      <Table
        isLoading={!companyRegistration?.CompanyInfo.Addresses}
        isEmpty={companyRegistration?.CompanyInfo.Addresses?.length === 0}
        data={companyRegistration?.CompanyInfo.Addresses || []}
        columns={[
          {
            key: 'Type',
            name: 'Type',
          },
          {
            key: 'Address',
            name: 'Address',
            render: (address: Address) => formatAddress(address),
          },
          {
            key: 'Country',
            name: 'Country',
            render: (address) => {
              const country = countries.data.find((c: Country) => c.ID === address.CountryID);
              return country?.Name || '';
            },
          },
        ]}
      />
      <Title order={5} mb="sm">
        Directors
      </Title>
      <Table
        isLoading={!companyRegistration?.Directors}
        isEmpty={companyRegistration?.Directors?.length === 0}
        data={companyRegistration?.Directors || []}
        columns={[
          {
            key: 'Name',
            name: 'Name',
            render: (director) => (
              <>
                {director.Director.Name?.Given} {director.Director.Name?.Family} (
                {director.Director.Name?.Use})
              </>
            ),
          },
          {
            key: 'Type',
            name: 'Type',
            render: (director) => (
              <>
                {director.Director.Type?.Description} ({director.Director.Type?.CcCmInd})
              </>
            ),
          },
          {
            key: 'AppointedAt',
            name: 'Appointed at',
            render: (director) =>
              director.Director.AppointedAt
                ? new Date(director.Director.AppointedAt)?.toDateString()
                : '-',
          },
          {
            key: 'IDNumber',
            name: 'ID number',
            render: (director) =>
              director.Director.Identifiers?.find(
                (current: Identifier) => current.System === 'regdirect.com/identifiers/id-number'
              )?.Value ?? '-',
          },
        ]}
      />
    </>
  );
}
