import { Modal } from '@mantine/core';
import { EnquiryForm } from './enquiry-form';

export function EnquiryModal({
  title,
  opened,
  close,
  onComplete,
  listingId,
}: {
  title: string;
  opened: boolean;
  close: () => void;
  onComplete: () => void;
  listingId: string;
}) {
  return (
    <Modal size="md" opened={opened} onClose={close} title={`${title} inquire`} centered>
      <EnquiryForm listingId={listingId} onComplete={onComplete} />
    </Modal>
  );
}
