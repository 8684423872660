import useSWR from 'swr';

export const useShareTypes = () =>
  useSWR<{
    data: {
      Code: number;
      Description: string;
      ID: string;
    }[];
  }>('/share-types');
