import useSWR from 'swr';
import { Listing, ListingStatus } from '../../types';
import { post } from '../../lib/api';

export const useListing = (id?: string | null) => {
  const { data: listing, isLoading } = useSWR<{
    data: Listing;
  }>(id ? `/listings/${id}` : null);

  const create = (body: {
    Name: string;
    Description: string;
    CountryCode: string;
    Address: string;
    Currency: string;
    Price: number;
    Status: ListingStatus;
    BusinessStatus: string;
    Type: string;
    Industry: string;
    YearsTrading: number;
    Monitization: string;
  }) => post('/listings', body);

  return {
    listing: listing?.data,
    isLoading,
    create,
  };
};
