import { Director } from '../../types';

export const formatDirectorOptions = (directors: Director[]) => {
  return directors
    .filter((current) => current.Name.Given || current.Name.Family)
    .map((current) => ({
      value: current.ID,
      label: `${current.Name.Given ?? '-'} ${current.Name.Family ?? '-'} ${current.Occupation ? `(${current.Occupation})` : ''}`,
    }));
};
