import { Title } from '@mantine/core';
import { Table } from './table';
import { Document } from '../types';

interface DocumentsProps {
  documents: Partial<Document>[];
  isLoading: boolean;
}

export function Documents({ documents, isLoading }: DocumentsProps) {
  return (
    <>
      <Table
        isLoading={isLoading}
        isEmpty={documents?.length === 0}
        data={documents}
        columns={[
          {
            key: 'DisplayName',
            name: 'Name',
          },
          {
            key: 'DateCreated',
            name: 'Created at',
            render: (current) => new Date(current.DateCreated).toLocaleDateString(),
          },
        ]}
        rowActions={(row) => [
          {
            label: 'Download',
            onClick: () => {
              window.open(row.SignedURL, '_blank');
            },
          },
        ]}
      />
    </>
  );
}
