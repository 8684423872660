import { useNavigate, useParams } from 'react-router-dom';
import { FormLayout } from '../../shared/form-layout';
import { AppointSecretaryForm } from './appoint-secretary-form';

export const AppointSecretary = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  return (
    <FormLayout title="Appoint secretary">
      <AppointSecretaryForm
        companyId={id}
        onComplete={(changeID: string) => navigate(`/requested-changes/${changeID}`)}
      />
    </FormLayout>
  );
};
