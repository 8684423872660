import { Address, Country } from '../types';

// This function should accept 2 addresses and return true if they are equal, false otherwise
// In the context of addresses, two addresses are equal if all of their fields are equal
// where "", null and undefined are considered equal

export const addressesEqual = (a: Address, b: Address): boolean => {
  const normalizeValue = (value: number | string | null | undefined | Partial<Country>): string => {
    if (value === null || value === undefined) {
      return '';
    }
    // if the value if of type Country, just return the ID
    if (value instanceof Object && 'ID' in value) {
      return value.ID!;
    }
    return value.toString(); // Convert numbers to strings for uniform comparison
  };

  // List all the fields to be compared, including 'Type' which can be number or string
  const fields: (keyof Address)[] = [
    'Type',
    'Line1',
    'Line2',
    'Line3',
    'Line4',
    'Subdivision',
    'Country',
    'Postcode',
    'ID',
  ];

  for (const field of fields) {
    const aValue = normalizeValue(a[field]);
    const bValue = normalizeValue(b[field]);

    if (aValue !== bValue) {
      return false; // If any field doesn't match, return false
    }
  }

  return true; // If all fields match, return true
};
