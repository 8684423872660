import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { validation } from '../../utils/validation';
import { FormLoader } from '../../shared/form-loader';
import { useEnquiry } from './use-enquiry';

export const EnquiryForm = ({
  onComplete,
  listingId,
}: {
  onComplete?: () => void;
  listingId: string;
}) => {
  const { create } = useEnquiry();

  const schema = useMemo(() => {
    const schema: Schema = [
      {
        name: 'email',
        type: FieldType.Email,
        label: 'Email',
        initialValue: '',
        validate: validation.required,
      },
      {
        name: 'contactNumber',
        type: FieldType.Text,
        label: 'Contact number',
        initialValue: '',
        validate: validation.requiredPhoneNumber,
      },
      {
        name: 'message',
        type: FieldType.Textarea,
        label: 'Message',
        initialValue: '',
        validate: validation.required,
      },
    ];
    return schema;
  }, []);

  if (!schema) {
    return <FormLoader />;
  }
  return (
    <DynamicForm
      schema={schema}
      onSubmit={async (values) => {
        await create({
          ListingID: listingId,
          Email: values.email,
          Phone: values.contactNumber,
          Message: values.message,
        });
        notifications.show({
          message: 'Enquiry has been sent',
          color: 'green',
        });
        onComplete && onComplete();
      }}
    />
  );
};
