import useSWR from 'swr';
import { useMemo } from 'react';
import { Listing, ListingStatus } from '../../types';
import { put } from '../../lib/api';

export const useListings = (search = '', status?: ListingStatus) => {
  const key = useMemo(() => {
    let path = '/listings';
    if (search) {
      path += `?search=${search}`;
    }
    if (status) {
      path += search ? `&filter=status:${status}` : `?filter=status:${status}`;
    }
    return path;
  }, [search, status]);
  const { data, isLoading, mutate } = useSWR<{
    data: Listing[];
  }>(key);

  const updateListing = (id: string, body: Listing) => put(`/listings/${id}`, body);

  return {
    listings: data?.data,
    isLoading,
    mutate,
    updateListing,
  };
};
