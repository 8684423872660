import useSWR from 'swr';
import { Company } from '../../types';
import { post } from '../../lib/api';

export const useCompanies = ({ search }: { search?: string }) => {
  const hasSearch = !!search;

  const { data, isLoading, mutate } = useSWR<{
    data: Company[];
  }>(!hasSearch ? '/companies' : null);

  const {
    data: searchData,
    isLoading: searchIsLoading,
    mutate: searcMutate,
  } = useSWR<{
    data: Company[];
  }>(hasSearch ? `/companies/search?query=${search}` : null);

  const linkUserToCompany = async (companyID: string, userID: string) => {
    await post(`/users/${userID}/companies`, [companyID]);
    mutate();
  };

  return {
    companies: hasSearch ? searchData?.data : data?.data,
    isLoading: isLoading || searchIsLoading,
    mutate: mutate || searcMutate,
    linkUserToCompany,
  };
};
