import useSWR from 'swr';
import { ShareClass } from '../../types';
import { post, put } from '../../lib/api';

export const useShareClasses = (companyId?: string, type?: string) => {
  const { data, isLoading, mutate, error } = useSWR<{
    data: ShareClass[];
  }>(
    companyId
      ? `/companies/${companyId}/shares?status=AllocationActive${
        type ? `&type=${type}` : ''
      }`
      : null
  );

  const allot = (body: {
    ShareholderID: string;
    ShareClassID: string;
    CompanyID: string;
    TotalIssued: number;
    PricePerShare: number;
    IssueDate: string;
  }) => {
    if (!companyId) return null;
    return post(`/companies/${companyId}/share-classes/allot`, body);
  };

  const cancel = async (
    shareAllocationID: string,
    body: {
    NumberCancelling: number;
    DateCancelled: string;
  }) => {
    if (!companyId || !shareAllocationID) {
      return null;
    }
    return put(`/companies/${companyId}/share-allocations/${shareAllocationID}/cancel`, body);
  };

  const redeem = async (
    shareAllocationID: string,
    body: {
      NumberRedeeming: number;
      DateRedeemed: string;
      PricePerShare: number;
    }) => {
    if (!companyId || !shareAllocationID) {
      return null;
    }
    return put(`/companies/${companyId}/share-allocations/${shareAllocationID}/redeem`, body);
  };

  const buyback = async (
    shareAllocationID: string,
    body: {
      NumberPurchased: number;
      PurchaseDate: string;
      PricePerShare: number;
    }) => {
    if (!companyId || !shareAllocationID) {
      return null;
    }
    return put(`/companies/${companyId}/share-allocations/${shareAllocationID}/purchase`, body);
  };

  const transfer = async (body: {
    originalShareAllocation: string;
    toShareholderID: string;
    date: string;
    price: number;
    totalTransferring: number;
  }) => {
    if (!companyId) return null;
    return post(`/companies/${companyId}/share-classes/transfer`, body);
  };

  return {
    shareClasses: data?.data,
    isLoading,
    allot,
    cancel,
    redeem,
    buyback,
    transfer,
    error,
    mutate,
  };
};
