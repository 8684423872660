import { Anchor, Badge, Text, Title } from '@mantine/core';
import { useState, useEffect } from 'react';
import { Table } from '../../shared/table';
import { useAdminRedirectGuard } from '../../shared/use-admin-redirect-guard';
import { useChanges } from './use-changes';
import { Link } from 'react-router-dom';

export function RequestedChanges() {
  const [perPage, setPerPage] = useState('50');
  const [page, setPage] = useState(() => {
    const cachedPage = localStorage.getItem('requestedChangesPage');
    return cachedPage ? parseInt(cachedPage) : 1;
  });

  useEffect(() => {
    localStorage.setItem('requestedChangesPage', page.toString());
  }, [page]);

  useAdminRedirectGuard();
  const { data, isLoading, error } = useChanges({
    page,
    perPage,
  });

  const isEmpty = data?.changes.length === 0;

  return (
    <>
      <Title order={2} mb="sm">
        Requested changes
      </Title>
      <Text mb="lg" c="dimmed">
        View and manage company changes requested by users.
      </Text>
      <Table
        error={error}
        isLoading={isLoading}
        isEmpty={isEmpty}
        columns={[
          {
            key: 'Code',
            name: 'Code',
            render: (row) => row.ChangeType.Code,
          },
          {
            key: 'description',
            name: 'Description',
            render: (row) => (
              <Anchor component={Link} to={`/requested-changes/${row.ID}`}>
                {row.ChangeType.Description}
              </Anchor>
            ),
          },
          {
            key: 'createdAt',
            name: 'Created at',
            render: (row) => new Date(row.CreatedAt).toDateString(),
          },
          {
            key: 'UpdatedAt',
            name: 'Last updated',
            render: (row) => new Date(row.UpdatedAt).toDateString(),
          },
          {
            key: 'status',
            name: 'Status',
            render: (row) => <Badge variant="default">{row.Status}</Badge>,
          },
        ]}
        data={data?.changes}
        page={page}
        onPageChange={setPage}
        pageSize={perPage}
        totalPages={data?.pagination.TotalPages}
        onPageSizeChange={setPerPage}
        rowActions={(row) => {
          const actions = [];
          if (row.CompanyID) {
            actions.push({
              label: 'View company',
              onClick: () => {
                window.open(`/companies/${row.CompanyID}`);
              },
            });
          }
          return actions;
        }}
      />
    </>
  );
}
