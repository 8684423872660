import { post } from '../../lib/api';
import { ChangeType } from '../../types';

export const useChange = () => {
  const store = async (body: {
    ChangeTypeID: string;
    CompanyID: string;
    DirectorID: string;
    Data: ChangeType;
  }) => post('/companies/changes', body);

  return {
    store,
  };
};
