import { Button, Group, Modal, Text } from '@mantine/core';
import { RemoveShareholderForm } from './remove-shareholder-form';
import { useShareholders } from './use-shareholders';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

export const RemoveShareholderModal = ({
  isOpen,
  companyId,
  onClose,
  onComplete,
  shareholderId,
}: {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  companyId?: string;
  shareholderId: string | null;
}) => {
  const [isLoading, setLoading] = useState(false);
  const { removeShareholder } = useShareholders(companyId);
  if (!companyId || !shareholderId || !isOpen) return null;

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await removeShareholder(shareholderId, companyId);
      notifications.show({
        title: 'Success',
        message: 'Shareholder has been removed',
        color: 'green',
      });
      onComplete();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to remove shareholder. Please try again.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="md" opened={isOpen} onClose={onClose} title="Remove shareholder" centered>
      <Text c="dimmed" mb="lg">
        Are you sure you would like to remove this shareholder? This action can not be undone.
      </Text>
      <Group align="center" justify="flex-end">
        <Button disabled={isLoading} variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={isLoading} onClick={handleConfirm}>
          Confirm
        </Button>
      </Group>
    </Modal>
  );
};
