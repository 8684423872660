import { useMemo, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { Button, Group, Stack, Stepper, TextInput } from '@mantine/core';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { validation } from '../../utils/validation';
import { FormLoader } from '../../shared/form-loader';
import { useDirectors } from './use-directors';
import { useProfessionalCodes } from '../../shared/use-professional-codes';
import { useOfficers } from './use-officers';
import { useDirectorStatuses } from '../../shared/use-director-statuses';
import { useDirectorTypes } from '../../shared/use-director-types';
import { useCountries } from '../../shared/use-countries';
import { useBasket } from '../../shared/use-basket';
import { useUsers } from '../users/use-users';
import { User } from '../../types';
import { formatDirectorOptions } from './format-director-options';
import { useSecretaries } from './use-secretaries';

export const AppointSecretaryForm = ({
  onComplete,
  companyId,
}: {
  onComplete?: (changeID: string) => void;
  companyId?: string;
}) => {
  const [user, setUser] = useState<User | null>(null);
  const { appoint } = useSecretaries();
  const { data: countries } = useCountries();
  const { data: directors } = useDirectors(companyId);
  const { data: directorStatuses } = useDirectorStatuses();
  const { data: directorTypes } = useDirectorTypes();
  const { data: professionalCodes } = useProfessionalCodes();

  const { addProduct, mutate: mutateBasket } = useBasket();
  const { searchUserByEmail } = useUsers();

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    Email: '',
    AuditorInfo: {}, // Placeholder for director information
  });

  const schema = useMemo(() => {
    const isLoaded = !!(
      directors &&
      professionalCodes &&
      directorTypes &&
      directorStatuses &&
      countries
    );
    if (!isLoaded) return null;
    if (!user) return null;

    const email = user.Contacts?.find(
      (contact) => contact.System === 'regdirect.com/contacts/email'
    );
    const phoneNumber = user.Contacts?.find(
      (contact) => contact.System === 'regdirect.com/contacts/phone-number'
    );
    const idNumber = user.Identifiers?.find(
      (identifier) => identifier.System === 'regdirect.com/identifiers/id-number'
    );
    const address = user.Addresses?.find((address) => address.Type === 'Residential');

    const schema: Schema = [
      {
        name: 'DirectorID',
        type: FieldType.Select,
        label: 'Director actioning the appointment',
        initialValue: '',
        options: formatDirectorOptions(directors),
        required: true,
      },
      {
        name: 'GivenName',
        type: FieldType.Text,
        label: 'Given name',
        initialValue: user.Name?.Given || '',
        required: true,
      },
      {
        name: 'FamilyName',
        type: FieldType.Text,
        label: 'Family name',
        initialValue: user.Name?.Family || '',
        required: true,
      },
      {
        name: 'TypeID',
        type: FieldType.Select,
        label: 'Type',
        initialValue: '',
        options: directorTypes.data.map((current) => ({
          value: current.ID,
          label: `${current.Description} (${current.Code})`,
        })),
        required: true,
      },
      {
        name: 'StatusID',
        type: FieldType.Select,
        label: 'Status',
        initialValue: '',
        options: directorStatuses.data.map((current) => ({
          value: current.ID,
          label: `${current.Description} (${current.Code})`,
        })),
        required: true,
      },
      {
        name: 'CountryOfOriginID',
        type: FieldType.Select,
        label: 'Country of origin',
        initialValue: '',
        options: countries.data.map((current) => ({
          value: current.ID,
          label: current.Name,
        })),
        required: true,
      },
      {
        name: 'Occupation',
        type: FieldType.Text,
        label: 'Occupation',
        initialValue: '',
        placeholder: 'e.g. CEO',
        required: true,
      },
      {
        type: FieldType.Date,
        name: 'AppointedAt',
        label: 'Appointed at',
        initialValue: new Date(),
        required: true,
      },
      {
        type: FieldType.Date,
        name: 'BirthDate',
        label: 'Birth date',
        initialValue: new Date(),
        required: true,
      },
      {
        name: 'IDNumber',
        type: FieldType.Text,
        label: 'ID number',
        initialValue: idNumber?.Value || '',
        required: true,
      },
      {
        name: 'Email',
        type: FieldType.Email,
        label: 'Email',
        initialValue: email?.Value || '',
        required: true,
        validate: validation.requiredEmail,
      },
      {
        type: 'fieldset',
        label: 'Address',
        fields: [
          {
            name: 'AddressLine1',
            type: FieldType.Text,
            label: 'Street address line 1',
            initialValue: address?.Line1 || '',
            required: true,
          },
          {
            name: 'AddressLine2',
            type: FieldType.Text,
            label: 'Street address line 2',
            initialValue: address?.Line2 || '',
          },
          {
            name: 'AddressPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: address?.Postcode || '',
            required: true,
          },
          {
            name: 'AddressCountryID',
            type: FieldType.Select,
            label: 'Country',
            initialValue:
              countries.data.find((country) => country.Name === address?.Country)?.ID || '',
            options: countries.data.map((current) => ({
              value: current.ID,
              label: current.Name,
            })),
            required: true,
          },
        ],
      },
    ];
    return schema;
  }, [directors, professionalCodes, directorTypes, directorStatuses, countries, user]);

  const fetchAndCheckUserExists = async (email: string) => {
    // Fetch user details based on ID number and country of origin
    const user = await searchUserByEmail(email);
    if (user.data.length > 0) {
      // Notificaiton to say user exists
      notifications.show({
        title: 'User Exists',
        message: 'A User with the desired email already exists',
        color: 'green',
      });
      setUser(user.data[0]);
    } else {
      setUser({} as User);
    }
    setStep((prev) => prev + 1);
  };

  const handleNext = () => {
    const { Email } = formData;
    fetchAndCheckUserExists(Email);
  };

  return (
    <Stepper active={step} onStepClick={setStep}>
      <Stepper.Step label="Email">
        <Stack gap="md">
          <TextInput
            autoComplete="email"
            type="email"
            label="Officer email"
            description="Email address of the officer you want to appoint"
            placeholder="john.smith@gmail.com"
            value={formData.Email}
            onChange={(event) => setFormData({ ...formData, Email: event.target.value })}
            required
          />
          <Button onClick={handleNext}>Next</Button>
        </Stack>
      </Stepper.Step>
      <Stepper.Step label="Details" disabled={!user?.ID}>
        {!schema ? (
          <FormLoader />
        ) : (
          <DynamicForm
            schema={schema}
            onSubmit={async (values) => {
              let changeID: string;
              try {
                const res = await appoint({
                  UserID: user?.ID || null,
                  CompanyID: companyId!,
                  DirectorID: values.DirectorID,
                  GivenName: values.GivenName,
                  FamilyName: values.FamilyName,
                  TypeID: values.TypeID,
                  Occupation: values.Occupation,
                  StatusID: values.StatusID,
                  CountryOfOriginID: values.CountryOfOriginID,
                  AppointedAt: values.AppointedAt,
                  BirthDate: values.BirthDate,
                  IDNumber: values.IDNumber,
                  Email: values.Email,
                  AddressLine1: values.AddressLine1,
                  AddressLine2: values.AddressLine2,
                  AddressCountryID: values.AddressCountry,
                  AddressPostcode: values.AddressPostcode,
                  Documents: [],
                });
                changeID = res.data.ID;
                try {
                  addProduct({
                    ChangeID: res.data.ID,
                    ProductType: 4,
                    Quantity: 1,
                  });
                } catch (error) {
                  notifications.show({
                    title: 'Error',
                    message: 'Failed to add secretary appointment to basket',
                    color: 'red',
                  });
                  return;
                } finally {
                  mutateBasket();
                }
              } catch (error) {
                notifications.show({
                  title: 'Error',
                  message: 'Failed to submit secretary appointment request',
                  color: 'red',
                });
                return;
              }
              notifications.show({
                Title: 'Success',
                message: 'Secretary appointment request created successfully',
                color: 'green',
              });
              onComplete && onComplete(changeID);
            }}
          />
        )}
      </Stepper.Step>
    </Stepper>
  );
};
