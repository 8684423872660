import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { validation } from '../../utils/validation';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { FormLoader } from '../../shared/form-loader';
import { Company } from '../../types';
import { useCompany } from './use-company';
import { useBbbeeLevels } from '../../shared/use-bbbee-levels';
import { useTaxPeriods } from '../../shared/use-tax-periods';

export function EditCompanyInfoForm({
  company,
  onComplete,
  onCreate,
}: {
  company: Company | null;
  onComplete?: () => void;
  onCreate?: (CompanyInfo: {
    Name: string;
    TradeName?: string;
    OldCompanyName?: string;
    EntityStatus?: {
      Code: string;
      Description: string;
      ID: string;
    };
    EntityType?: {
      Code: string;
      Description: string;
      Suffix: string;
      ID: string;
    };
    FinancialYearEndMonth?: string;
    PublicInterestScore?: number;
    SDLExempt?: boolean;
    BBBEELevelID?: string;
    TaxPeriodID?: string;
    TaxPeriod?: {
      DisplayName: string;
      Description: string;
      ID: string;
    };
    Identifiers?: {
      ID: string | null;
      Type: string;
      System: string | null;
      Value: string;
    }[];
  }) => void;
}) {
  const { update } = useCompany(company?.ID);
  const isCreating = onCreate;
  const { data: bbbbeeLevels } = useBbbeeLevels();
  const { data: taxPeriods } = useTaxPeriods();
  // TODO: update these to use enums
  const enterpriseNumber = company?.Identifiers.find(
    (current) => current.System === 'regdirect.com/identifiers/enterprise-number'
  );
  const oldEnterpriseNumber = company?.Identifiers.find(
    (current) => current.System === 'regdirect.com/identifiers/old-enterprise-number'
  );
  const incomeTaxNumber = company?.Identifiers.find(
    (current) => current.System === 'regdirect.com/identifiers/tax-number'
  );
  const payeNumber = company?.Identifiers.find(
    (current) => current.System === 'regdirect.com/identifiers/paye-number'
  );
  const uifNumber = company?.Identifiers.find(
    (current) => current.System === 'regdirect.com/identifiers/uif-number'
  );
  const uifReferenceNumber = company?.Identifiers.find(
    (current) => current.System === 'regdirect.com/identifiers/uif-reference-number'
  );
  const vatNumber = company?.Identifiers.find(
    (current) => current.System === 'regdirect.com/identifiers/vat-number'
  );
  const compensationFundNumber = company?.Identifiers.find(
    (current) => current.System === 'regdirect.com/identifiers/compensation-fund-number'
  );

  const schema = useMemo(() => {
    if (!bbbbeeLevels) return null;
    if (!taxPeriods) return null;

    const schema: Schema = [
      {
        type: FieldType.Text,
        name: 'name',
        label: 'Name',
        initialValue: company?.Name ?? '',
        validate: validation.requiredCompanyName,
        disabled: true,
      },
      {
        type: FieldType.Text,
        name: 'tradeName',
        label: 'Trade Name',
        initialValue: company?.TradeName ?? '',
        validate: validation.companyName,
      },
      {
        type: FieldType.Text,
        name: 'oldCompanyName',
        label: 'Old Company Name',
        initialValue: company?.OldCompanyName ?? '',
        validate: validation.companyName,
      },
      {
        type: FieldType.Text,
        name: 'registrationNumber',
        label: 'Registration Number',
        initialValue: enterpriseNumber?.Value ?? '',
        validate: validation.required,
        disabled: !isCreating ? !!enterpriseNumber?.Value : false,
      },
      {
        type: FieldType.Text,
        name: 'oldRegistrationNumber',
        label: 'Old Registration Number',
        initialValue: oldEnterpriseNumber?.Value ?? '',
      },
      {
        type: FieldType.Text,
        name: 'incomeTaxNumber',
        label: 'Income Tax Number',
        initialValue: incomeTaxNumber?.Value ?? '',
      },
      {
        type: FieldType.Text,
        name: 'payeNumber',
        label: 'PAYE Number',
        initialValue: payeNumber?.Value ?? '',
      },
      {
        type: FieldType.Text,
        name: 'uifNumber',
        label: 'UIF Number',
        initialValue: uifNumber?.Value ?? '',
      },
      {
        type: FieldType.Text,
        name: 'uifReferenceNumber',
        label: 'UIF Reference Number',
        initialValue: uifReferenceNumber?.Value ?? '',
      },
      {
        type: FieldType.Checkbox,
        name: 'sdlExempt',
        label: 'SDL Exempt',
        initialValue: company?.SDLExempt ?? false,
      },
      {
        type: FieldType.Text,
        name: 'vatNumber',
        label: 'VAT Number',
        initialValue: vatNumber?.Value ?? '',
      },
      {
        type: FieldType.Select,
        name: 'taxPeriod',
        label: 'Category (Tax Period)',
        initialValue: company?.TaxPeriod.ID ?? '',
        options: taxPeriods?.data.map((current) => ({
          value: current.ID,
          label: `${current.DisplayName} - ${current.Description}`,
        })),
      },
      {
        type: FieldType.Text,
        name: 'compensationFundNumber',
        label: 'Compensation Fund Number',
        initialValue: compensationFundNumber?.Value ?? '',
      },
      {
        type: FieldType.Number,
        name: 'publicInterestScore',
        label: 'Public Interest Score',
        initialValue: company?.PublicInterestScore ?? '',
      },
      {
        type: FieldType.Select,
        name: 'bbbeeLevel',
        label: 'BBBEE Level',
        initialValue: company?.BBBEELevel.ID ?? '',
        options: bbbbeeLevels?.data.map((current) => ({
          value: current.ID,
          label: current.DisplayName,
        })),
      },
    ];
    return schema;
  }, [company, bbbbeeLevels, taxPeriods]);

  if (!schema) {
    return <FormLoader />;
  }

  return (
    <DynamicForm
      schema={schema}
      buttonText={!isCreating ? 'Save' : 'Next'}
      onSubmit={async (values) => {
        if (!isCreating && company) {
          try {
            const res = await update({
              TradeName: values?.tradeName,
              OldCompanyName: values?.oldCompanyName,
              Identifiers: [
                {
                  ID: oldEnterpriseNumber?.ID ?? null,
                  Type: 'Old Enterprise Number',
                  System: 'regdirect.com/identifiers/old-enterprise-number',
                  Value: values?.oldRegistrationNumber,
                },
                {
                  ID: incomeTaxNumber?.ID ?? null,
                  Type: 'Tax Number',
                  System: 'regdirect.com/identifiers/tax-number',
                  Value: values?.incomeTaxNumber,
                },
                {
                  ID: payeNumber?.ID ?? null,
                  Type: 'PAYE Number',
                  System: 'regdirect.com/identifiers/paye-number',
                  Value: values?.payeNumber,
                },
                {
                  ID: uifNumber?.ID ?? null,
                  Type: 'UIF Number',
                  System: 'regdirect.com/identifiers/uif-number',
                  Value: values?.uifNumber,
                },
                {
                  ID: uifReferenceNumber?.ID ?? null,
                  Type: 'UIF Reference Number',
                  System: 'regdirect.com/identifiers/uif-reference-number',
                  Value: values?.uifReferenceNumber,
                },
                {
                  ID: vatNumber?.ID ?? null,
                  Type: 'Vat Number',
                  System: 'regdirect.com/identifiers/vat-number',
                  Value: values?.vatNumber,
                },
                {
                  ID: compensationFundNumber?.ID ?? null,
                  Type: 'Compensation Fund Number',
                  System: 'regdirect.com/identifiers/compensation-fund-number',
                  Value: values?.compensationFundNumber,
                },
              ],
              SDLExempt: values?.sdlExempt,
              PublicInterestScore: values?.publicInterestScore,
              BBBEELevelID:
                values?.bbbeeLevel === '00000000-0000-0000-0000-000000000000'
                  ? null
                  : values?.bbbeeLevel,
              TaxPeriodID:
                values?.taxPeriod === '00000000-0000-0000-0000-000000000000'
                  ? null
                  : values?.taxPeriod,
            });
          } catch (error) {
            notifications.show({
              message: 'Failed to save changes',
              color: 'red',
            });
            return;
          }
          notifications.show({
            message: 'Company information updated successfully',
            color: 'green',
          });
          if (onComplete) onComplete();
        } else if (isCreating) {
          onCreate?.({
            Name: values?.name,
            TradeName: values?.tradeName,
            OldCompanyName: values?.oldCompanyName,
            Identifiers: [
              {
                ID: enterpriseNumber?.ID ?? null,
                Type: 'Enterprise Number',
                System: 'regdirect.com/identifiers/enterprise-number',
                Value: values?.registrationNumber,
              },
              {
                ID: oldEnterpriseNumber?.ID ?? null,
                Type: 'Old Enterprise Number',
                System: 'regdirect.com/identifiers/old-enterprise-number',
                Value: values?.oldRegistrationNumber,
              },
              {
                ID: incomeTaxNumber?.ID ?? null,
                Type: 'Tax Number',
                System: 'regdirect.com/identifiers/tax-number',
                Value: values?.incomeTaxNumber,
              },
              {
                ID: payeNumber?.ID ?? null,
                Type: 'PAYE Number',
                System: 'regdirect.com/identifiers/paye-number',
                Value: values?.payeNumber,
              },
              {
                ID: uifNumber?.ID ?? null,
                Type: 'UIF Number',
                System: 'regdirect.com/identifiers/uif-number',
                Value: values?.uifNumber,
              },
              {
                ID: uifReferenceNumber?.ID ?? null,
                Type: 'UIF Reference Number',
                System: 'regdirect.com/identifiers/uif-reference-number',
                Value: values?.uifReferenceNumber,
              },
              {
                ID: vatNumber?.ID ?? null,
                Type: 'Vat Number',
                System: 'regdirect.com/identifiers/vat-number',
                Value: values?.vatNumber,
              },
              {
                ID: compensationFundNumber?.ID ?? null,
                Type: 'Compensation Fund Number',
                System: 'regdirect.com/identifiers/compensation-fund-number',
                Value: values?.compensationFundNumber,
              },
            ],
            SDLExempt: values?.sdlExempt,
            PublicInterestScore: values?.publicInterestScore,
            BBBEELevelID:
              values?.bbbeeLevel === '00000000-0000-0000-0000-000000000000'
                ? null
                : values?.bbbeeLevel,
            TaxPeriodID:
              values?.taxPeriod === '00000000-0000-0000-0000-000000000000'
                ? null
                : values?.taxPeriod,
          });
        }
      }}
    />
  );
}
