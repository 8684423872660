import useSWR from 'swr';
import { post, patch } from '../../lib/api';
import { Company, Director } from '../../types';

export const useCompany = (id?: string) => {
  const {
    data: companyResult,
    isLoading,
    mutate: mutateCompany,
  } = useSWR<{
    data: Company;
  }>(id ? `/companies/${id}` : null);

  const {
    data: directorsResult,
    isLoading: isDirectorsLoading,
    mutate: mutateDirectors,
  } = useSWR<{
    data: Director[];
  }>(id ? `/companies/${id}/directors` : null);

  const update = async (body: {
    TradeName?: string;
    OldCompanyName?: string;
    EntityStatus?: {
      Code: string;
      Description: string;
      ID: string;
    };
    EntityType?: {
      Code: string;
      Description: string;
      Suffix: string;
      ID: string;
    };
    FinancialYearEndMonth?: string;
    PublicInterestScore?: number;
    SDLExempt?: boolean;
    BBBEELevelID?: string;
    TaxPeriodID?: string;
    TaxPeriod?: {
      DisplayName: string;
      Description: string;
      ID: string;
    };
    Identifiers?: {
      ID: string | null;
      Type: string;
      System: string | null;
      Value: string;
    }[];
  }) => patch(`/companies/${id}`, body);

  const register = async (body: {}) => post('/companies/changes', body);

  const mutate = () => {
    mutateCompany();
    mutateDirectors();
  };

  return {
    company: companyResult?.data,
    directors: directorsResult?.data,
    mutate,
    isLoading: isLoading || isDirectorsLoading,
    update,
    register,
  };
};
