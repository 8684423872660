import { Modal } from '@mantine/core';
import { Company } from '../../types';
import { UpdateCompanyAddressesForm } from './update-company-addresses-form';

export function UpdateCompanyAddressesModal({
  isOpen,
  onClose,
  onUpdate,
  company,
}: {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
  company: Company | null;
}) {
  return (
    <Modal size="lg" opened={isOpen} onClose={onClose} title="Company Information" centered>
      {isOpen && (
        <UpdateCompanyAddressesForm
          company={company}
          onComplete={() => {
            onUpdate();
            onClose();
          }}
        />
      )}
    </Modal>
  );
}
