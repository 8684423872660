import { useNavigate, useParams } from 'react-router-dom';
import { FormLayout } from '../../shared/form-layout';
import { AppointAuditorForm } from './appoint-auditor-form';

export const AppointAuditor = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  return (
    <FormLayout title="Appoint auditor">
      <AppointAuditorForm companyId={id} onComplete={() => navigate(`/companies/${id}`)} />
    </FormLayout>
  );
};
