import { Modal } from '@mantine/core';
import { AllotSharesForm } from './allot-shares-form';

export function AllotSharesModal({
  isOpen,
  onClose,
  onComplete,
  companyId,
}: {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  companyId?: string | null;
}) {
  if (!companyId) return null;
  return (
    <Modal size="md" opened={isOpen} onClose={onClose} title="Allot new shares" centered>
      {isOpen && <AllotSharesForm companyId={companyId} onComplete={onComplete} />}
    </Modal>
  );
}
