import useSWR from 'swr';

export const useEnquiries = (listingId?: string) =>
  useSWR<{
    data: {
      Email: string;
      ID: string;
      Message: string;
      Phone: string;
    }[];
  }>(listingId ? `/listings/${listingId}/enquiries` : null);
