import useSWR from 'swr';
import { Auditor } from '../../types';
import { useChangeTypes } from '../../shared/use-change-types';
import { ChangeType } from '../../types/enums';
import { post } from '../../lib/api';
import { useProfessionalCodes } from '../../shared/use-professional-codes';
import { useAuditStatuses } from '../../shared/use-audit-statuses';

interface AppointPayload {
  ChangeTypeID: string;
  ActionType: 'Appointment';
  ObjectType: 'company';
  CompanyID: string;
  DirectorID: string;
  Data: {
    Auditor: {
      UserID: string | null;
      Name: string;
      TypeID: string;
      StatusID: string;
      ProfessionID: string;
      ProfessionNumber: string;
      StartAt: string;
      ExpiresAt: string;
      FineLetter: 0;
      Addresses: {
        Type: string;
        Line1: string;
        Line2: string;
        CountryID: string;
        Postcode: string;
      }[];
      Identifiers: {
        System: string;
        Value: string;
        Type: string;
      }[];
    };
  };
}

export const useAuditors = (companyId?: string) => {
  const { data: changeTypes } = useChangeTypes();
  const { data: auditStatuses } = useAuditStatuses();
  const { data: professionalCodes } = useProfessionalCodes();

  const { data, isLoading, mutate } = useSWR<{
    data: Auditor[];
  }>(companyId ? `/companies/${companyId}/auditors` : null);

  const appoint = async (data: {
    UserID: string | null;
    CompanyID: string;
    DirectorID: string;
    Name: string;
    AuditTypeID: string;
    AuditStatusID: string;
    ProfessionID: string;
    StartAt: string;
    ExpiresAt: string;
    IDNumber: string;
    AddressLine1: string;
    AddressLine2: string;
    AddressCountryID: string;
    AddressPostcode: string;
  }) => {
    const changeType = changeTypes?.data.find(
      (current) => current.Code === ChangeType.AuditorAccountOfficer
    );
    const body: AppointPayload = {
      ActionType: 'Appointment',
      ObjectType: 'company',
      ChangeTypeID: changeType?.ID!,
      CompanyID: data.CompanyID,
      DirectorID: data.DirectorID,
      Data: {
        Auditor: {
          UserID: data.UserID,
          Name: data.Name,
          TypeID: data.AuditTypeID,
          StatusID: data.AuditStatusID,
          ProfessionID: data.ProfessionID,
          ProfessionNumber: professionalCodes?.data.find(
            (current) => current.ID === data.ProfessionID
          )?.Code!,
          StartAt: data.StartAt,
          ExpiresAt: data.ExpiresAt,
          FineLetter: 0,
          Addresses: [
            {
              Type: 'Residential',
              Line1: data.AddressLine1,
              Line2: data.AddressLine2,
              CountryID: data.AddressCountryID,
              Postcode: data.AddressPostcode,
            },
          ],
          Identifiers: [
            {
              System: 'regdirect.com/identifiers/id-number',
              Value: data.IDNumber,
              Type: 'ID Number',
            },
          ],
        },
      },
    };
    const result = await post('/companies/changes', body);
    await mutate();
    return result;
  };

  const resign = (data: {
    AuditorID: string;
    CompanyID: string;
    DirectorID: string;
    EndAt: string;
  }) => {
    const changeType = changeTypes?.data.find(
      (current) => current.Code === ChangeType.AuditorAccountOfficer
    );
    const auditorStatus = auditStatuses?.data.find((current) => current.Code === 'C')?.ID;
    const body = {
      ChangeTypeID: changeType?.ID!,
      ActionType: 'Resignation',
      CompanyID: data.CompanyID,
      DirectorID: data.DirectorID,
      Data: {
        Auditor: {
          StatusID: auditorStatus!,
          ID: data.AuditorID,
          EndAt: data.EndAt,
        },
      },
    };
    return post('/companies/changes', body);
  };

  return {
    mutate,
    appoint,
    resign,
    auditors: data?.data,
    isLoading,
  };
};
