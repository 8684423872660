import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { validation } from '../../utils/validation';
import { FormLoader } from '../../shared/form-loader';
import { useDirectors } from './use-directors';
import { useOfficers } from './use-officers';
import { formatDirectorOptions } from './format-director-options';

export const ResignOfficerForm = ({
  id,
  onComplete,
  companyId,
}: {
  onComplete?: () => void;
  id?: string;
  companyId?: string;
}) => {
  const { resign } = useOfficers();
  const { data: directors } = useDirectors(companyId);

  const schema = useMemo(() => {
    if (!directors) return null;

    const schema: Schema = [
      {
        name: 'DirectorID',
        type: FieldType.Select,
        label: 'Director submitting resignation request',
        initialValue: '',
        options: formatDirectorOptions(directors),
        validate: validation.required,
      },
      {
        type: FieldType.Date,
        name: 'EndAt',
        label: 'End at',
        initialValue: new Date(),
        validate: validation.required,
      },
    ];
    return schema;
  }, [directors]);

  if (!schema) {
    return <FormLoader />;
  }
  return (
    <DynamicForm
      schema={schema}
      onSubmit={async (values) => {
        await resign({
          CompanyID: companyId!,
          DirectorID: values.DirectorID,
          ID: id!,
          EndAt: values.EndAt,
        });
        notifications.show({
          message: 'Officer resignation request submitted',
          color: 'green',
        });
        onComplete && onComplete();
      }}
    />
  );
};
