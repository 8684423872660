import { Anchor, Breadcrumbs, Text, Title } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';
import { Table } from '../../shared/table';
import { useEnquiries } from './use-enquiries';
import { useListing } from './use-listing';

export function Enquiries() {
  const { id } = useParams<{ id: string }>();
  const { isLoading, data } = useEnquiries(id);
  const { listing } = useListing(id);

  return (
    <>
      <Breadcrumbs mb="xl">
        <Anchor component={Link} to="/marketplace/manage">
          Manage listings
        </Anchor>
        <Text>{listing?.Name ?? 'Listing'}</Text>
        <Text>Enquiries</Text>
      </Breadcrumbs>
      <Title order={3} mb="lg">
        Enquiries for {listing?.Name ?? 'Listing'}
      </Title>

      <Table
        isLoading={isLoading}
        isEmpty={data?.data.length === 0}
        data={data?.data}
        columns={[
          {
            key: 'Email',
            name: 'Email',
          },
          {
            key: 'Phone',
            name: 'Contact number',
          },
          {
            key: 'Message',
            name: 'Message',
          },
        ]}
      />
    </>
  );
}
