import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
  Title,
  rem,
} from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useCompany } from './use-company';
import { Table } from '../../shared/table';
import { Address, Director, Identifier, ShareClass } from '../../types';
import { AddShareClassModal } from './add-share-class-modal';
import { ReorganiseSharesModal } from './reorganise-shares-modal';
import { useShareholders } from './use-shareholders';
import { ShareholderModal } from './shareholder-modal';
import { useShareClasses } from './use-share-classes';
import { AllotSharesModal } from './allot-shares-modal';
import { TransferSharesModal } from './transfer-shares-modal';
import { RemoveShareholderModal } from './remove-shareholder-modal';
import { EditCompanyInfoModal } from './edit-company-info-modal';
import { ChangeCompanyNameModal } from './change-company-name-modal';
import { UpdateCompanyAddressesModal } from './update-company-addresses-modal';
import { useAuditors } from './use-auditors';
import { useDirectors } from './use-directors';
import { formatAddress } from '../../utils/format-address';
import { ShareCapitalSummary } from './share-capital-summary';

const directorColumns = [
  {
    key: 'Name',
    name: 'Name',
    render: (director: Director) => (
      <>
        {director.Name.Given} {director.Name.Family} ({director.Name.Use})
      </>
    ),
  },
  {
    key: 'Type',
    name: 'Type',
    render: (director: Director) => (
      <>
        {director.Type.Description} ({director.Type.CcCmInd})
      </>
    ),
  },
  {
    key: 'Status',
    name: 'Status',
    render: (director: Director) => director.Status.Description,
  },
  {
    key: 'AppointedAt',
    name: 'Appointed at',
    render: (director: Director) =>
      director.AppointedAt ? new Date(director.AppointedAt).toDateString() : '-',
  },
  {
    key: 'IDNumber',
    name: 'ID number',
    render: (director: Director) =>
      director.Identifiers.find((current: Identifier) => current.Type === 'ID Number')?.Value ??
      '-',
  },
  {
    key: 'BirthDate',
    name: 'Birth date',
    render: (director: Director) =>
      director.BirthDate ? new Date(director.BirthDate).toDateString() : '-',
  },
];

type PageDialog = 'transfer-shares' | 'edit-company' | 'change-company-name' | 'remove-shareholder';

export function Company() {
  const [activeDialog, setActiveDialog] = useState<PageDialog | null>(null);
  const [transferSharesFromShareholderId, setTransferSharesFromShareholderId] = useState<
    string | null
  >(null);
  const [showUpdateAddresses, setShowUpdateAddresses] = useState(false);
  const [showShareholderModal, setShowShareholderModal] = useState(false);
  const [showAddShareClass, setShowAddShareClass] = useState(false);
  const [showReorganiseShares, setShowReorganiseShares] = useState(false);
  const [activeShareClass, setActiveShareClass] = useState<ShareClass | null>(null);
  const [activeShareholderId, setActiveShareholderId] = useState<string | null>(null);
  const [activeShareholderType, setActiveShareholderType] = useState<string | null>(null);
  const [showAllotShares, setShowAllotShares] = useState(false);
  const { id } = useParams<{ id: string }>();
  const {
    shareholders,
    isLoading: isShareholdersLoading,
    mutate: mutateShareholders,
  } = useShareholders(id);
  const {
    shareClasses,
    isLoading: isShareClassesLoading,
    mutate: mutateShareClasses,
  } = useShareClasses(id);

  const navigate = useNavigate();
  const { isLoading, company, mutate } = useCompany(id);
  const { data: directors, isLoading: isDirectorsLoading } = useDirectors(id);
  const { data: officers, isLoading: isOfficersLoading } = useDirectors(id, 'officer');
  const { data: companySecretaries, isLoading: isCompanySecretariesLoading } = useDirectors(
    id,
    'companySecretary'
  );
  const { auditors, isLoading: isAuditorsLoading } = useAuditors(id);

  const enterpriseNumber = company?.Identifiers.find(
    (current) => current.Type === 'Enterprise Number'
  )?.Value;
  const taxNumber = company?.Identifiers.find((current) => current.Type === 'Tax Number')?.Value;

  const handleShareReorganisationClose = () => {
    setShowReorganiseShares(false);
    mutateShareClasses();
  };

  return (
    <>
      <Breadcrumbs mb="xl">
        <Anchor component={Link} to="/companies">
          Companies
        </Anchor>
        <Text>{company?.Name ?? '-'}</Text>
      </Breadcrumbs>
      <Group align="center" justify="space-between" mb="lg">
        <Title order={3} mb="lg">
          Company details
        </Title>
        <Group>
          <Button variant="light" component={Link} to={`/companies/${id}/requested-changes`}>
            View Change Requests
          </Button>
          <Button variant="light" component={Link} to={`/companies/${id}/documents`}>
            View documents
          </Button>
          <Button
            variant="light"
            disabled={isLoading}
            onClick={() => setActiveDialog('edit-company')}
          >
            Edit Company Information
          </Button>
          <Button
            variant="light"
            disabled={isLoading}
            onClick={() => {
              setActiveDialog('change-company-name');
            }}
          >
            Change Company Name
          </Button>
        </Group>
      </Group>
      <Box pos="relative">
        <LoadingOverlay visible={isLoading} overlayProps={{ radius: 'md', blur: 2 }} />
        <Stack gap="sm" maw={rem(360)}>
          <TextInput readOnly label="Name" value={company?.Name} />
          <TextInput readOnly label="Status" value={company?.EntityStatus.Description} />
          <TextInput readOnly label="Type" value={company?.EntityType.Description} />
          {enterpriseNumber && (
            <TextInput readOnly label="Enterprise Number" value={enterpriseNumber} />
          )}
          {taxNumber && <TextInput readOnly label="Tax number" value={taxNumber} />}
        </Stack>
      </Box>
      <Group align="center" justify="space-between" mb="sm" mt="xl">
        <Title order={4}>Company addresses</Title>
        <Button
          disabled={isLoading}
          variant="outline"
          onClick={() => {
            setShowUpdateAddresses(true);
          }}
        >
          Update Addresses
        </Button>
      </Group>
      <Table
        isLoading={!company?.Addresses}
        isEmpty={company?.Addresses?.length === 0}
        data={company?.Addresses}
        columns={[
          {
            key: 'Type',
            name: 'Type',
          },
          {
            key: 'Address',
            name: 'Address',
            render: (address: Address) => formatAddress(address),
          },
          {
            key: 'Country',
            name: 'Country',
            render: (address: Address) => address.Country.Name,
          },
        ]}
      />
      <Group align="center" justify="space-between" mb="sm" mt="xl">
        <Title order={4}>Directors</Title>
        <Group gap="xs">
          <Button
            variant="outline"
            component={Link}
            to={`/companies/${id}/appoint-director`}
            disabled={isAuditorsLoading}
          >
            Appoint Director
          </Button>
        </Group>
      </Group>
      <Table
        isLoading={isDirectorsLoading}
        isEmpty={directors?.length === 0}
        data={directors}
        columns={directorColumns}
        rowActions={(row) => [
          {
            label: 'Resign director',
            onClick: () => {
              navigate(`/companies/${id}/resign-director/${row.ID}`);
            },
          },
        ]}
      />
      <Group align="center" justify="space-between" mb="sm" mt="xl">
        <Title order={4}>Officers</Title>
        <Group gap="xs">
          <Button
            variant="outline"
            component={Link}
            to={`/companies/${id}/appoint-officer`}
            disabled={isAuditorsLoading}
          >
            Appoint Officer
          </Button>
        </Group>
      </Group>
      <Table
        isLoading={isOfficersLoading}
        isEmpty={officers?.length === 0}
        data={officers}
        columns={directorColumns}
        rowActions={(row) => [
          {
            label: 'Resign officer',
            onClick: () => {
              navigate(`/companies/${id}/resign-officer/${row.ID}`);
            },
          },
        ]}
      />
      <Group align="center" justify="space-between" mb="sm" mt="xl">
        <Title order={4}>Company secretaries</Title>
        <Group gap="xs">
          <Button
            variant="outline"
            component={Link}
            to={`/companies/${id}/appoint-secretary`}
            disabled={isCompanySecretariesLoading}
          >
            Appoint Secretary
          </Button>
        </Group>
      </Group>
      <Table
        isLoading={isCompanySecretariesLoading}
        isEmpty={companySecretaries?.length === 0}
        data={companySecretaries}
        columns={directorColumns}
        rowActions={(row) => [
          {
            label: 'Resign secretary',
            onClick: () => {
              navigate(`/companies/${id}/resign-secretary/${row.ID}`);
            },
          },
        ]}
      />

      <Group align="center" justify="space-between" mb="sm" mt="xl">
        <Title order={4}>Auditors</Title>
        <Group gap="xs">
          <Button
            variant="outline"
            component={Link}
            to={`/companies/${id}/appoint-auditor`}
            disabled={isAuditorsLoading}
          >
            Appoint Auditor
          </Button>
        </Group>
      </Group>
      <Table
        isLoading={isAuditorsLoading}
        isEmpty={auditors?.length === 0}
        data={auditors}
        columns={[
          {
            key: 'Name',
            name: 'Name',
          },
          {
            key: 'Type',
            name: 'Type',
            render: (current) => current.Type.Description,
          },
          {
            key: 'Status',
            name: 'Status',
            render: (current) => current.Status.Description,
          },
          {
            key: 'Profession',
            name: 'Profession code',
            render: (current) => current.Profession.Code,
          },
          {
            key: 'StartAt',
            name: 'Start at',
            render: (current) => new Date(current.StartAt).toDateString(),
          },
          {
            key: 'ExpiresAt',
            name: 'Expires at',
            render: (current) => new Date(current.ExpiresAt).toDateString(),
          },
        ]}
        rowActions={(row) => [
          {
            label: 'Resign auditor',
            onClick: () => {
              navigate(`/companies/${id}/resign-auditor/${row.ID}`);
            },
          },
        ]}
      />
      <Group align="center" justify="space-between" mb="sm" mt="xl">
        <Title order={4}>Share classes</Title>
        <Group gap="xs">
          <Button
            variant="outline"
            disabled={isShareClassesLoading}
            onClick={() => setShowReorganiseShares(true)}
          >
            Share Reorganisation
          </Button>
          <Button
            variant="outline"
            component={Link}
            to={`/companies/${id}/share-summary`}
            disabled={isShareClassesLoading}
            onClick={() => setShowAllotShares(true)}
          >
            Summary
          </Button>
          <Button
            variant="outline"
            disabled={isShareClassesLoading}
            onClick={() => setShowAddShareClass(true)}
          >
            Add
          </Button>
        </Group>
      </Group>
      <Table
        isEmpty={shareClasses?.length === 0}
        isLoading={isShareClassesLoading}
        data={shareClasses}
        columns={[
          {
            key: 'Name',
            name: 'Name',
          },
          {
            key: 'Description',
            name: 'Description',
          },
          {
            key: 'TotalShares',
            name: 'Shares Authorised',
          },
          {
            key: 'IssuedShares',
            name: 'Shares Issued',
            render: (row: ShareClass) =>
              row.ShareAllocations.reduce((acc, current) => acc + current.TotalIssued, 0),
          },
        ]}
        rowActions={(row) => [
          {
            label: 'Update',
            onClick: () => {
              setActiveShareClass(row);
              setShowAddShareClass(true);
            },
          },
        ]}
      />
      <Group align="center" justify="space-between" mb="sm" mt="xl">
        <Title order={4}>Shareholders</Title>
        <Group gap="xs">
          {shareholders && shareholders?.length > 0 && (
            <Button variant="outline" onClick={() => setShowAllotShares(true)}>
              Allot shares
            </Button>
          )}

          <Button
            variant="outline"
            disabled={isShareholdersLoading}
            onClick={() => setShowShareholderModal(true)}
          >
            Add
          </Button>
        </Group>
      </Group>
      <Table
        isLoading={isShareholdersLoading}
        isEmpty={!shareholders || shareholders?.length === 0}
        data={shareholders}
        rowActions={(row) => {
          return [
            {
              label: 'Update',
              onClick: () => {
                setActiveShareholderType(row.ShareholderType);
                setActiveShareholderId(row.ID);
                setShowShareholderModal(true);
              },
            },
            {
              label: 'Transfer shares from shareholder',
              onClick: () => {
                setActiveDialog('transfer-shares');
                setTransferSharesFromShareholderId(row.ID);
              },
            },
            {
              label: 'View share certificates',
              onClick: () => {
                navigate(`/companies/${id}/shareholder-share-allocations/${row.ID}`);
              },
            },
            {
              label: 'Remove shareholder',
              onClick: () => {
                setActiveDialog('remove-shareholder');
                setActiveShareholderId(row.ID);
              },
            },
          ];
        }}
        columns={[
          {
            key: 'Name',
            name: 'Name',
            render: (shareholder) =>
              shareholder.ShareholderType === 'individual_shareholder'
                ? `${shareholder.IndividualShareholderFields.Name.Given ?? ''} ${
                    shareholder.IndividualShareholderFields.Name.Family ?? ''
                  }`
                : shareholder.ShareholderType === 'trust_shareholder'
                  ? shareholder.TrustShareholderFields.Name
                  : shareholder.CompanyShareholderFields.Name,
          },
          {
            key: 'ContactNumber',
            name: 'Contact number',
            render: (shareholder) =>
              shareholder.Contacts.length > 0 ? shareholder.Contacts[0].Value : '-',
          },
          {
            key: 'ShareholderType',
            name: 'Shareholder type',
            render: (shareholder) =>
              shareholder.ShareholderType === 'individual_shareholder'
                ? 'Individual'
                : shareholder.ShareholderType === 'trust_shareholder'
                  ? 'Trust'
                  : 'Company',
          },
        ]}
      />
      <ShareCapitalSummary companyId={id} />
      <EditCompanyInfoModal
        company={company ?? null}
        isOpen={activeDialog === 'edit-company'}
        onUpdate={() => {
          mutate();
        }}
        onClose={() => {
          setActiveDialog(null);
        }}
      />
      <ChangeCompanyNameModal
        company={company ?? null}
        isOpen={activeDialog === 'change-company-name'}
        onAdd={() => {
          mutate();
        }}
        onClose={() => {
          setActiveDialog(null);
        }}
      />
      <UpdateCompanyAddressesModal
        company={company ?? null}
        isOpen={showUpdateAddresses}
        onUpdate={() => {
          mutate();
        }}
        onClose={() => {
          setShowUpdateAddresses(false);
        }}
      />
      <AddShareClassModal
        companyId={id}
        isOpen={showAddShareClass}
        onAdd={() => {
          mutateShareClasses();
        }}
        shareClass={activeShareClass}
        onClose={() => {
          setActiveShareClass(null);
          setShowAddShareClass(false);
        }}
      />
      <ReorganiseSharesModal
        companyId={id}
        isOpen={showReorganiseShares}
        onAdd={() => {
          mutateShareClasses();
        }}
        onClose={handleShareReorganisationClose}
      />
      <ShareholderModal
        companyId={id}
        overrideType={activeShareholderType}
        shareholderId={activeShareholderId}
        isOpen={showShareholderModal}
        onClose={() => {
          setShowShareholderModal(false);
          setActiveShareholderType(null);
          setActiveShareholderId(null);
        }}
        onComplete={() => {
          setShowShareholderModal(false);
          mutateShareholders();
          setActiveShareholderType(null);
          setActiveShareholderId(null);
        }}
      />
      <AllotSharesModal
        companyId={id}
        isOpen={showAllotShares}
        onClose={() => setShowAllotShares(false)}
        onComplete={() => {
          setShowAllotShares(false);
          mutateShareholders();
          mutateShareClasses();
        }}
      />
      <TransferSharesModal
        isOpen={activeDialog === 'transfer-shares'}
        onClose={() => {
          setTransferSharesFromShareholderId(null);
          setActiveDialog(null);
        }}
        companyId={id}
        fromShareholderId={transferSharesFromShareholderId}
        onComplete={() => {
          setTransferSharesFromShareholderId(null);
          setActiveDialog(null);
          mutateShareholders();
        }}
      />
      <RemoveShareholderModal
        isOpen={activeDialog === 'remove-shareholder'}
        onClose={() => {
          setActiveShareholderId(null);
          setActiveDialog(null);
        }}
        companyId={id}
        shareholderId={activeShareholderId}
        onComplete={() => {
          setActiveShareholderId(null);
          setActiveDialog(null);
          mutateShareholders();
        }}
      />
    </>
  );
}
