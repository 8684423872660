import { useNavigate, useParams } from 'react-router-dom';
import { FormLayout } from '../../shared/form-layout';
import { ResignOfficerForm } from './resign-officer-form';

export const ResignOfficer = () => {
  const navigate = useNavigate();
  const { id, directorId } = useParams<{ id: string; directorId: string }>();
  return (
    <FormLayout title="Resign officer">
      <ResignOfficerForm
        id={directorId}
        companyId={id}
        onComplete={() => navigate(`/companies/${id}`)}
      />
    </FormLayout>
  );
};
