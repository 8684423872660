import { useDisclosure } from '@mantine/hooks';
import {
  ActionIcon,
  Alert,
  AppShell,
  Box,
  Burger,
  Group,
  Indicator,
  NavLink,
  Stack,
  rem,
  useMantineColorScheme,
} from '@mantine/core';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  LuAlertCircle,
  LuArrowLeftRight,
  LuBuilding,
  LuHome,
  LuSearch,
  LuShoppingCart,
  LuUser,
} from 'react-icons/lu';
import { Spotlight, SpotlightActionData, spotlight } from '@mantine/spotlight';
import logoSrc from './logo.svg';
import logoDarkSrc from './logo-dark.svg';

import classes from './layout.module.css';
import { UserButton } from './user-button';
import { useActiveUser } from '../features/users/use-active-user';
import { useBasket } from './use-basket';
import { CompleteProfileModal } from '../features/users/complete-profile-modal';
import { useQuickLinks } from './use-quick-links';

type NavLink = {
  label: string;
  icon: React.ReactNode;
  to: string;
  sublinks?: { label: string; to: string }[];
  disabled?: boolean;
};

export function Layout() {
  const navigate = useNavigate();
  const { colorScheme } = useMantineColorScheme();
  const [opened, { toggle }] = useDisclosure();
  const location = useLocation();
  const quickLinks = useQuickLinks();
  const {
    claims,
    isInitialized,
    mutate,
    isIncompleteProfile,
    isAdmin,
    isActive,
    isInvaidAccount,
    error,
  } = useActiveUser();
  const { basket, isLoading, checkout } = useBasket();

  // Loop through the items in the basket, count the quanitity of each item and sum

  const links = [
    {
      label: 'Home',
      icon: <LuHome size={20} />,
      to: '/',
    },
    {
      label: 'Companies',
      icon: <LuBuilding size={20} />,
      to: '/companies',
    },
    {
      label: 'Marketplace',
      icon: <LuShoppingCart size={20} />,
      to: '/marketplace',
      sublinks: [
        { label: 'View Listings', to: '/marketplace' },
        { label: 'Manage Listings', to: '/marketplace/manage' },
      ].filter((current) => !!current),
    },
    isAdmin && {
      label: 'User management',
      icon: <LuUser size={20} />,
      to: '/users',
      sublinks: [
        { label: 'Active users', to: '/users' },
        { label: 'Unverified users', to: '/users/unverified' },
      ],
    },
    isAdmin && {
      label: 'Requested changes',
      icon: <LuArrowLeftRight size={20} />,
      to: '/requested-changes',
    },
  ].filter((current) => !!current) as NavLink[];

  const actions: SpotlightActionData[] = quickLinks.map((current) => ({
    id: current.to,
    label: current.title,
    disabled: !isInitialized,
    description: current.description,
    onClick: () => {
      navigate(current.to);
    },
    leftSection: current.icon,
  }));

  return (
    <>
      <AppShell
        header={{ height: 60 }}
        navbar={{ width: 270, breakpoint: 'sm', collapsed: { mobile: !opened } }}
        padding="md"
      >
        <AppShell.Header className={classes.header}>
          <Group h="100%" align="center" justify="space-between" px="md">
            <Group>
              <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
              <img src={colorScheme === 'dark' ? logoDarkSrc : logoSrc} alt="Logo" height={34} />
            </Group>
            <Group>
              <ActionIcon onClick={spotlight.open} variant="default" size="lg" aria-label="Search">
                <LuSearch size={20} />
              </ActionIcon>

              <Indicator inline label={basket?.Items ? basket?.Items.length : 0} size={16}>
                <ActionIcon
                  variant="default"
                  size="lg"
                  disabled={isLoading}
                  aria-label="Basket"
                  onClick={async () => {
                    const checkoutUrl = await checkout();
                    // redirect the user to the checkout page
                    window.location.href = checkoutUrl;
                  }}
                >
                  <LuShoppingCart size={20} />
                </ActionIcon>
              </Indicator>
            </Group>
            <Spotlight
              actions={actions}
              nothingFound="Nothing found..."
              highlightQuery
              searchProps={{
                leftSection: <LuSearch size={20} />,
                placeholder: 'Search...',
              }}
            />
          </Group>
        </AppShell.Header>
        <AppShell.Navbar className={classes.nav}>
          <Stack gap={rem(2)} className={classes.links}>
            {isInitialized &&
              isActive &&
              links.map((link) => {
                if (!link) return null;
                const hasSublinks = Array.isArray(link.sublinks);
                const isActiveLink =
                  location.pathname === link.to ||
                  (location.pathname.startsWith(link.to) && link.to !== '/');
                return (
                  <NavLink
                    childrenOffset={hasSublinks ? 12 : 0}
                    active={isActiveLink}
                    disabled={link.disabled}
                    noWrap
                    key={link.to}
                    component={Link}
                    to={link.to}
                    label={link.label}
                    leftSection={link.icon}
                  >
                    {hasSublinks &&
                      link.sublinks?.map((sublink) => {
                        const isSublinkActive = location.pathname === sublink.to;
                        return (
                          <NavLink
                            active={isSublinkActive}
                            noWrap
                            key={sublink.to}
                            component={Link}
                            to={sublink.to}
                            label={sublink.label}
                          />
                        );
                      })}
                  </NavLink>
                );
              })}
          </Stack>
          <UserButton userId={claims?.id} isAdmin={isAdmin} />
        </AppShell.Navbar>
        <AppShell.Main style={{ background: colorScheme === 'light' ? '#fdfdfd' : '#232222' }}>
          {isInvaidAccount ? (
            <Alert icon={<LuAlertCircle />} color="red" title="Invalid user account">
              There appears to be an issue with your account, please contact support.
            </Alert>
          ) : (
            <>
              {isInitialized && (
                <Box className={classes.container}>
                  {isActive ? (
                    <Outlet />
                  ) : (
                    <Alert
                      icon={<LuAlertCircle />}
                      color="red"
                      title="Your account is not active yet"
                    >
                      You should have received an email with a link to verify your email address. In
                      addition an administrator will need to approve your account before you can use
                      the platform.
                    </Alert>
                  )}
                </Box>
              )}
            </>
          )}
        </AppShell.Main>
      </AppShell>

      <CompleteProfileModal
        userId={claims?.id}
        isOpen={isInitialized && isIncompleteProfile}
        onComplete={() => mutate()}
      />
    </>
  );
}

export const ProtectedLayout = withAuthenticationRequired(Layout);
