import useSWR from 'swr';
import { ShareClass } from '../../types';

export const useCompanyShareSummary = (companyId?: string) => {
  const { data, isLoading, mutate, error } = useSWR<{
    data: ShareClass[];
  }>(companyId ? `/companies/${companyId}/share-classes/summary` : null);
  return {
    shareSummary: data?.data,
    isLoading,
    error,
    mutate,
  };
};
