import useSWR from 'swr';
import { Address } from '../../types';
import { put, post } from '../../lib/api';

export const useUserAddresses = (id?: string) => {
  const {
    data: userAddressesResult,
    isLoading: isUserAddressesLoading,
    mutate,
  } = useSWR<{
    data: Address[];
  }>(id ? `/users/${id}/addresses` : null);

  const update = async (addresses: Partial<Address>[]) => {
    await Promise.all(
      addresses.map(async (address) => {
        if (address.ID) {
          await put(`/users/${id}/addresses/${address.ID}`, address);
        } else {
          await post(`/users/${id}/addresses`, address);
        }
      })
    );
    mutate();
  };

  return {
    update,
    userAddresses: userAddressesResult?.data,
    isLoading: isUserAddressesLoading,
  };
};
