import { Anchor, Badge, Breadcrumbs, Loader, Text, Title } from '@mantine/core';
import { useState, useEffect } from 'react';
import { Table } from '../../shared/table';
import { useAdminRedirectGuard } from '../../shared/use-admin-redirect-guard';
import { useCompanyChanges } from './use-company-changes';
import { Link, useParams } from 'react-router-dom';
import { useCompany } from './use-company';

export function CompanyChanges() {
  const { id } = useParams<{ id: string }>();
  const [perPage, setPerPage] = useState('10');
  const [page, setPage] = useState(1);
  const { isLoading: companyLoading, company } = useCompany(id);

  useAdminRedirectGuard();
  const { data, isLoading, error } = useCompanyChanges({
    companyID: id || '',
    page,
    perPage,
  });

  const isEmpty = !isLoading && (data?.changes?.length === 0 || data?.changes === null);

  return (
    <>
      {companyLoading ? (
        <Loader />
      ) : (
        <Breadcrumbs mb="xl">
          <Anchor component={Link} to="/companies">
            Companies
          </Anchor>
          <Anchor component={Link} to={`/companies/${id}`}>
            {company?.Name ?? '-'}
          </Anchor>
          <Text>Requested Changes</Text>
        </Breadcrumbs>
      )}
      <>
        <Title order={2} mb="sm">
          Requested changes
        </Title>
        <Text mb="lg" c="dimmed">
          View and manage company changes requested by users.
        </Text>
        <Table
          error={error}
          isLoading={isLoading}
          isEmpty={isEmpty}
          columns={[
            {
              key: 'Code',
              name: 'Code',
              render: (row) => row.ChangeType.Code,
            },
            {
              key: 'description',
              name: 'Description',
              render: (row) => (
                <Anchor component={Link} to={`/companies/${id}/requested-changes/${row.ID}`}>
                  {row.ChangeType.Description}
                </Anchor>
              ),
            },
            {
              key: 'createdAt',
              name: 'Created at',
              render: (row) => new Date(row.CreatedAt).toDateString(),
            },
            {
              key: 'UpdatedAt',
              name: 'Last updated',
              render: (row) => new Date(row.UpdatedAt).toDateString(),
            },
            {
              key: 'status',
              name: 'Status',
              render: (row) => <Badge variant="default">{row.Status}</Badge>,
            },
          ]}
          data={data?.changes}
          page={page}
          onPageChange={setPage}
          pageSize={perPage}
          totalPages={data?.pagination.TotalPages}
          onPageSizeChange={setPerPage}
        />
      </>
    </>
  );
}
