import { Group, Table as MantineTable, Pagination, Select, Text, rem } from '@mantine/core';
import { ReactNode } from 'react';
import { TableWrapper } from './table-wrapper';
import { Th } from './th';
import { TableLoadingRows } from './table-loading-rows';
import { ActionCell, TableActionsCell } from './table-actions-cell';
import { ErrorAlert } from './error-alert';

export interface Column {
  key: string;
  name: string;
  render?: (row: any, index: number) => ReactNode;
}

export function Table({
  actions,
  isLoading = false,
  loadingRows = 5,
  isEmpty = false,
  emptyText = 'No data found.',
  error,
  columns,
  data,
  rowActions,
  page,
  onPageChange,
  totalPages,
  pageSize,
  onPageSizeChange,
}: {
  isLoading?: boolean;
  loadingRows?: number;
  actions?: ReactNode;
  isEmpty?: boolean;
  emptyText?: string;
  columns: Column[];
  error?: Error | null;
  data?: { [key: string]: any }[];
  rowActions?: (row: any) => ActionCell[];
  page?: number;
  onPageChange?: (page: number) => void;
  totalPages?: number;
  pageSize?: string;
  onPageSizeChange?: (pageSize: string) => void;
}) {
  const loadingColumns = columns.length + (rowActions ? 1 : 0);
  const hasPagination = page !== undefined && onPageChange && totalPages !== undefined;
  const hasPageSize = hasPagination && onPageSizeChange;

  if (error) {
    return <ErrorAlert error={error} />;
  }
  return (
    <>
      {actions && (
        <Group gap="sm" align="center" justify="space-between" mb="lg">
          {actions}
        </Group>
      )}

      <TableWrapper>
        <MantineTable.Thead>
          <MantineTable.Tr>
            {columns.map((column) => (
              <Th key={column.key}>{column.name}</Th>
            ))}
            {rowActions && <Th />}
          </MantineTable.Tr>
        </MantineTable.Thead>
        <MantineTable.Tbody>
          {isLoading ? (
            <TableLoadingRows columns={loadingColumns} rows={loadingRows} />
          ) : isEmpty ? (
            <MantineTable.Tr>
              <MantineTable.Td colSpan={loadingColumns} align="center" py="xl">
                <Text fw={500}>{emptyText}</Text>
              </MantineTable.Td>
            </MantineTable.Tr>
          ) : (
            data?.map((row, index) => (
              <MantineTable.Tr key={row.key}>
                {columns.map((column) => (
                  <MantineTable.Td key={column.key}>
                    {column.render ? column.render(row, index) : row[column.key] ?? '-'}
                  </MantineTable.Td>
                ))}
                {rowActions && <TableActionsCell actions={rowActions(row)} />}
              </MantineTable.Tr>
            ))
          )}
        </MantineTable.Tbody>
      </TableWrapper>
      <Group mt="sm" align="center" justify="space-between">
        {hasPageSize && (
          <Select
            maw={rem(90)}
            value={pageSize}
            onChange={(value) => {
              if (value) onPageSizeChange(value);
            }}
            aria-label="Page size"
            placeholder="Page size"
            data={['10', '20', '50', '100']}
          />
        )}
        {hasPagination && <Pagination total={totalPages} value={page} onChange={onPageChange} />}
      </Group>
    </>
  );
}
