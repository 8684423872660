import React, { useMemo, useState } from 'react';
import { Stack, Select, Fieldset, NumberInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { FormLoader } from '../../shared/form-loader';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { useShareClasses } from './use-share-classes';
import { useShareholders } from './use-shareholders';
import { AllocationStatus } from '../../types/enums';
import { ShareClass, ShareAllocation, Shareholder } from '../../types';

export function BuybackSharesForm({
                                   onComplete,
                                   companyId,
                                 } : {
  onComplete?: () => void;
  companyId?: string
}) {
  const { shareholders } = useShareholders(companyId);
  const { shareClasses, buyback, isLoading } = useShareClasses(companyId);
  const [activeShareClassAllocations, setActiveShareClassAllocations] =
    useState<ShareAllocation[]>();
  const [shareClassShareholders, setShareClassShareholders] = useState<Shareholder[] | undefined>();
  const [relevantCertificates, setRelevantCertificates] = useState<string[]>();
  const [selectedShareAllocation, setSelectedShareAllocation] = useState<ShareAllocation>();
  const [formError, setFormError] = useState<string>('');
  const [numberPurchasing, setNumberPurchasing] = useState<number>(0);

  const schema = useMemo(() => {
    if (isLoading || !shareholders || !shareClasses) return null;
    const schema : Schema = [
      {
        type: FieldType.Number,
        name: 'pricePerShare',
        label: 'Price Per Share',
        initialValue: 0,
        required: true,
        prefix: 'R',
        validate: (value: string) => {
          if (Number(value) > 0) {
            return null;
          }
          return 'Total purchasing must be greater than 0';
        },
      },
      {
        type: FieldType.Date,
        name: 'datePurchased',
        label: 'Date Purchased',
        initialValue: new Date(),
        required: true,
      },
    ];

    return schema;
  }, [isLoading]);

  // setShareholdersOptions sets the options for the shareholders dropdown based off of the selected share class
  const setShareholdersOptions = (event:string) => {
    const shareClassID = event;
    const currentShareClass : ShareClass | undefined =
      shareClasses?.find((s) => s.ID === shareClassID);
    const activeShareAllocations = currentShareClass?.ShareAllocations
      .filter(s => s.Status === AllocationStatus.AllocationActive);
    if (activeShareAllocations) {
      setActiveShareClassAllocations(activeShareAllocations);
    }
    const shareholderIds = activeShareAllocations?.map(s => s.ShareholderID) ?? [];
    const filteredShareholders = shareholders?.filter(
      (shareholder) => shareholderIds.includes(shareholder.ID)
    );
    setShareClassShareholders(filteredShareholders);
  };

  // setShareCertificateOptions sets the options for the share certificates dropdown based off of the selected share class and shareholder.
  const setShareCertificateOptions = (event:string) => {
    if (activeShareClassAllocations) {
      const shareholderAllocations =
        activeShareClassAllocations.filter(s => s.ShareholderID === event);
      const certificateNames = shareholderAllocations?.map(s => s.FileDisplayName) ?? [];
      setRelevantCertificates(certificateNames);
      return;
    }
    setRelevantCertificates(undefined);
  };

  const handleShareCertificateSelection = (event:string) => {
    if (activeShareClassAllocations) {
      const selected =
        activeShareClassAllocations.find(s => s.FileDisplayName === event);
      setSelectedShareAllocation(selected);
      return;
    }
    setSelectedShareAllocation(undefined);
  };

  const handleNumberInputChange = (newValue: number) => {
    if (newValue <= 0) {
      setFormError('Can only purchase more than 0 shares');
      return;
    }
    if (selectedShareAllocation) {
      if (newValue > selectedShareAllocation?.TotalIssued) {
        const e : string = `Can not purchase more than ${selectedShareAllocation?.TotalIssued} issued`;
        setFormError(e);
        return;
      }
    }
    setNumberPurchasing(newValue);
    setFormError('');
  };

  if (isLoading || !schema) {
    return <FormLoader />;
  }

  return (
    <div>
      <Stack gap="md">
        <Select
          label="Select Share Class"
          data={shareClasses?.map((c) => ({
            value: c.ID,
            label: c.Name,
          }))}
          // value={shareClasses && shareClasses.length > 0 ? shareClasses[0].ID : ''}
          onChange={(value) => value && setShareholdersOptions(value)}
        />
        <Fieldset legend="Select Shareholding" key="Select Shareholding">
          <Stack gap="sm">
            <Select
              placeholder="Shareholder"
              data={shareClassShareholders?.map((shareholder:Shareholder) => {
                const name =
                  shareholder.ShareholderType === 'individual_shareholder'
                    ? `${shareholder.IndividualShareholderFields.Name.Given} ${shareholder.IndividualShareholderFields.Name.Family}`
                    : shareholder.CompanyShareholderFields.Name ||
                    shareholder.TrustShareholderFields.Name;
                return {
                  value: shareholder.ID,
                  label: name,
                };
              })
              }
              onChange={(value) => value && setShareCertificateOptions(value)}
            />
            <Select
              placeholder="Share Certificate"
              data={relevantCertificates}
              onChange={handleShareCertificateSelection}
            />
          </Stack>
        </Fieldset>
        <NumberInput
          key="numberPurchasing"
          withAsterisk={!!true}
          label="No. of Shares"
          placeholder={selectedShareAllocation?.TotalIssued ? `${selectedShareAllocation.TotalIssued} Available` : 'No. of Shares'}
          onChange={handleNumberInputChange}
          disabled={!selectedShareAllocation}
          error={formError}
        />
        <DynamicForm
          schema={schema}
          onSubmit={async (values) => {
            if (!companyId) return;
            if (!selectedShareAllocation || formError) {
              throw new Error();
              return;
            }

            await buyback(
              selectedShareAllocation?.ID,
              {
                NumberPurchased: numberPurchasing,
                PurchaseDate: values.datePurchased.toISOString(),
                PricePerShare: values.pricePerShare,
              });
            notifications.show({
              message: 'Shares buyback successful',
              color: 'green',
            });
            if (onComplete) onComplete();
          }}
        />
      </Stack>
    </div>
  );
}
