import { useNavigate, useParams } from 'react-router-dom';
import { FormLayout } from '../../shared/form-layout';
import { AppointOfficerForm } from './appoint-officer-form';

export const AppointOfficer = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  return (
    <FormLayout title="Appoint officer">
      <AppointOfficerForm
        companyId={id}
        onComplete={(changeID: string) => navigate(`/requested-changes/${changeID}`)}
      />
    </FormLayout>
  );
};
