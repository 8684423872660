import { ActionIcon, Box, Group, Paper, Title, Text, Stack } from '@mantine/core';
import { ReactNode } from 'react';
import { LuArrowLeft } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

export const FormLayout = ({
  title,
  children,
  description,
  onBack,
}: {
  title: string;
  children: ReactNode;
  description?: string;
  onBack?: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <Paper maw={600} p="lg" withBorder mx="auto">
      <Group mb="xl">
        <ActionIcon
          variant="default"
          onClick={() => {
            if (onBack) {
              onBack();
              return;
            }
            navigate(-1);
          }}
        >
          <LuArrowLeft />
        </ActionIcon>
        <Stack gap={0}>
          <Title order={3}>{title}</Title>
          {description && <Text c="dimmed">{description}</Text>}
        </Stack>
      </Group>
      <Box>{children}</Box>
    </Paper>
  );
};
