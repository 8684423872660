import useSWR from 'swr';
import { Basket, BasketItem } from '../types';
import { post } from '../lib/api';
import { useActiveUser } from '../features/users/use-active-user';

export const useBasket = () => {
  const { claims } = useActiveUser();
  const userId = claims?.id;

  const {
    data: basket,
    isLoading,
    mutate: mutateBasket,
  } = useSWR<{ data: Basket }>(userId ? `/billing/baskets?userID=${userId}` : null);

  const create = async () => {
    const res = await post('/billing/baskets', { UserId: userId });
    return res;
  };

  const addProduct = async (data: BasketItem) => {
    let id: string;
    if (!basket) {
      const res = await create();
      id = res.ID;
    } else {
      id = basket.data.ID;
    }

    await post(`/billing/baskets/${id}/items`, {
      ChangeID: data.ChangeID,
    });
    mutateBasket();
  };

  const checkout = async () => {
    if (!basket) return;
    const res = await post(`/billing/baskets/${basket.data.ID}/checkout`);
    return res.data.url;
  };

  const mutate = () => {
    mutateBasket();
  };

  return {
    basket: basket?.data,
    isLoading,
    mutate,
    addProduct,
    create,
    checkout,
  };
};
