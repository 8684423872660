import useSWR from 'swr';
import { ChangeType, Shareholder } from '../../types';

export const useUserCompanyRegistrationRequests = (userId?: string) => {
  const { data, isLoading, mutate } = useSWR<{
    data: ChangeType[];
  }>(userId ? `/changes/users/${userId}` : null);

  return {
    companyRegistrationRequests: data?.data,
    isLoading,
    mutate,
  };
};
