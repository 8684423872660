import useSWR from 'swr';

export const useTaxPeriods = () =>
  useSWR<{
    data: {
      DisplayName: string;
      Description: string;
      ID: string;
    }[];
  }>('/tax-periods');
