import useSWR from 'swr';

export const useChanges = ({ page, perPage }: { page: number; perPage: string }) => {
  const { data, isLoading, mutate, error } = useSWR<{
    data: {
      changes: {
        CreatedAt: string;
        UpdatedAt: string;
        DeletedAt: string | null;
        ID: string;
        ChangeTypeID: string;
        ChangeType: {
          Code: string;
          Description: string;
          ID: string;
        };
        CompanyID: string | null;
        Status: string;
        DirectorID: string;
      }[];
      pagination: {
        Page: number;
        PerPage: number;
        TotalItems: number;
        TotalPages: number;
      };
    };
  }>(`/changes?Page=${page}&PerPage=${perPage}&order_by=created_at&order_direction=desc`);

  return {
    data: data?.data,
    isLoading,
    error,
    mutate,
  };
};
