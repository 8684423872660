import { useMemo, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { Button, Group, TextInput } from '@mantine/core';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { validation } from '../../utils/validation';
import { useShareholder } from './use-shareholder';
import { FormLoader } from '../../shared/form-loader';
import { Country, User } from '../../types';
import { useUsers } from '../users/use-users';
import { useCountries } from '../../shared/use-countries';

export const IndividualShareholderForm = ({
  shareholderId,
  onComplete,
  companyId,
}: {
  shareholderId?: string;
  onComplete?: () => void;
  companyId?: string;
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [userLoading, setUserLoading] = useState(false);
  // if shareholderId is not undefined, set step to 2
  const [step, setStep] = useState(() => (shareholderId !== undefined ? 2 : 1));
  const { searchUserByEmail } = useUsers();
  const { data: countries } = useCountries();
  const { create } = useShareholder(companyId);
  const [formData, setFormData] = useState({
    Email: '',
  });

  const { shareholder } = useShareholder(undefined, shareholderId);

  const phoneNumber =
    shareholderId !== undefined
      ? shareholder?.Contacts?.find(
          (current) => current.System === 'regdirect.com/contacts/phone-number'
        )
      : user?.Contacts.find((current) => current.System === 'regdirect.com/contacts/phone-number');
  const email =
    shareholderId !== undefined
      ? shareholder?.Contacts?.find((current) => current.System === 'regdirect.com/contacts/email')
      : user?.Contacts.find((current) => current.System === 'regdirect.com/contacts/email');
  const idNumber =
    shareholderId !== undefined
      ? shareholder?.Identifiers?.find(
          (current) => current.System === 'regdirect.com/identifiers/id-number'
        )
      : user?.Identifiers.find(
          (current) => current.System === 'regdirect.com/identifiers/id-number'
        );
  const name =
    shareholderId !== undefined ? shareholder?.IndividualShareholderFields.Name : user?.Name;

  const schema = useMemo(() => {
    if (shareholderId) {
      if (!countries || !shareholder) {
        return null;
      }
    } else if (!countries || userLoading) {
      return null;
    }

    const residentialAddress =
      shareholderId !== undefined
        ? shareholder?.Addresses?.find((current) => current.Type === 'Residential')
        : user?.Addresses?.find((current) => current.Type === 'Residential');

    const otherAddress =
      shareholderId !== undefined
        ? shareholder?.Addresses?.find((current) => current.Type === 'Postal')
        : user?.Addresses?.find((current) => current.Type === 'Postal');

    const isSame =
      residentialAddress &&
      otherAddress &&
      residentialAddress?.Line1 === otherAddress?.Line1 &&
      residentialAddress?.Line2 === otherAddress?.Line2 &&
      residentialAddress?.Line3 === otherAddress?.Line3 &&
      residentialAddress?.Line4 === otherAddress?.Line4 &&
      residentialAddress?.Postcode === otherAddress?.Postcode &&
      residentialAddress?.Country === otherAddress?.Country;

    const schema: Schema = [
      {
        type: 'fieldset',
        label: 'Personal Information',
        fields: [
          {
            name: 'firstName',
            type: FieldType.Text,
            label: 'First name',
            initialValue: name?.Given ?? '',
            validate: validation.required,
          },
          {
            name: 'lastName',
            type: FieldType.Text,
            label: 'Last name',
            initialValue: name?.Family ?? '',
            validate: validation.required,
          },
          {
            name: 'idNumber',
            type: FieldType.Text,
            label: 'ID number',
            initialValue: idNumber?.Value ?? '',
            // show: (values: any) => values.isSACitizen,
            validate: validation.requiredIdNumber,
          },
        ],
      },
      {
        type: 'fieldset',
        label: 'Contact Information',
        fields: [
          {
            name: 'emailAddress',
            type: FieldType.Email,
            label: 'Email address',
            initialValue: email?.Value ?? '',
            validate: validation.requiredEmail,
          },
          {
            name: 'phoneNumber',
            type: FieldType.Text,
            label: 'Phone number',
            placeholder: '+27744190175',
            initialValue: phoneNumber?.Value ?? '',
            validate: validation.requiredPhoneNumber,
          },
        ],
      },
      {
        type: 'fieldset',
        label: 'Physical Address',
        fields: [
          {
            name: 'physicalLine1',
            type: FieldType.Text,
            label: 'Street address line 1',
            initialValue: residentialAddress?.Line1 ?? '',
            validate: validation.required,
          },
          {
            name: 'physicalLine2',
            type: FieldType.Text,
            label: 'Street address line 2',
            initialValue: residentialAddress?.Line2 ?? '',
          },
          {
            name: 'physicalPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: residentialAddress?.Postcode ?? '',
            validate: validation.required,
          },
          {
            name: 'physicalCountry',
            type: FieldType.Select,
            label: 'Country',
            initialValue:
              (residentialAddress?.Country as Country)?.ID ??
              (
                countries?.data.find(
                  (country) => country.Name === residentialAddress?.Country
                ) as Country
              )?.ID ??
              '',
            options: countries?.data.map((current) => ({
              value: current.ID,
              label: current.Name,
            })),
            required: true,
            validate: validation.required,
          },
        ],
      },
      {
        name: 'isResidentialPostalSame',
        type: FieldType.Checkbox,
        label: 'Same as residential address?',
        initialValue: !!isSame,
      },
      {
        type: 'fieldset',
        label: 'Postal Address',
        show: (values: any) => !values.isResidentialPostalSame,
        fields: [
          {
            name: 'otherLine1',
            type: FieldType.Select,
            label: 'Street address line 1',
            initialValue: otherAddress?.Line1 ?? '',
            validate: validation.required,
          },
          {
            name: 'otherLine1',
            type: FieldType.Text,
            label: 'Street address line 2',
            initialValue: otherAddress?.Line2 ?? '',
          },
          {
            name: 'otherPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: otherAddress?.Postcode ?? '',
            validate: validation.required,
          },
          {
            name: 'otherCountry',
            type: FieldType.Select,
            label: 'Country',
            initialValue:
              (otherAddress?.Country as Country)?.ID ??
              (countries?.data.find((country) => country.Name === otherAddress?.Country) as Country)
                ?.ID ??
              '',
            options: countries?.data.map((current) => ({
              value: current.ID,
              label: current.Name,
            })),
            validate: validation.required,
          },
        ],
      },
    ];
    return schema;
  }, [user, userLoading, shareholder, countries, shareholder]);

  const fetchAndCheckUserExists = async (email: string) => {
    console.log('Fetching user');
    setUserLoading(true);
    // Fetch user details based on ID number and country of origin
    const user = await searchUserByEmail(email);
    // is the length of user.data > 0
    if (user.data.length > 0) {
      // Notificaiton to say user exists
      notifications.show({
        title: 'User Exists',
        message: 'A User with the desired email already exists',
        color: 'green',
      });
      setUser(user.data[0]);
    } else {
      setUser(null);
    }
    setUserLoading(false);
    setStep((prev) => prev + 1);
    console.log('Donee');
  };

  const handleNext = () => {
    const { Email } = formData;
    fetchAndCheckUserExists(Email);
  };

  const handleBack = () => setStep((prev) => prev - 1);

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <Group align="end" mb="lg">
            <TextInput
              autoComplete="email"
              type="email"
              label="Director Email"
              placeholder="john.smith@gmail.com"
              style={{ width: '50%' }}
              value={formData.Email}
              onChange={(event) => setFormData({ ...formData, Email: event.target.value })}
              required
            />
            <Button onClick={handleNext}>Next</Button>
          </Group>
        );
      case 2:
        // The actual form where you input director information
        // For simplicity, I'm directly calling DynamicForm as a placeholder
        // You should replace this with your actual form fields based on whether userExists

        if (!schema) {
          return <FormLoader />;
        }
        return (
          <DynamicForm
            schema={schema}
            onSubmit={async (values) => {
              const residentialAddress = {
                ID: values.ID ?? null,
                Type: 'Residential',
                Line1: values.physicalLine1,
                Line2: values.physicalLine2,
                Country: {
                  ID: values.physicalCountry,
                },
                Subdivision: null,
                Postcode: values.physicalPostcode,
              };

              const otherAddress = values.isResidentialPostalSame
                ? {
                    ID: values.ID ?? null,
                    Type: 'Postal',
                    Line1: residentialAddress.Line1,
                    Line2: residentialAddress.Line2,
                    Country: residentialAddress.Country,
                    Subdivision: residentialAddress.Subdivision,
                    Postcode: residentialAddress.Postcode,
                  }
                : {
                    ID: values.ID ?? null,
                    Type: 'Postal',
                    Line1: values.otherLine1,
                    Line2: values.otherLine2,
                    Country: {
                      ID: values.otherCountry,
                    },
                    Subdivision: null,
                    Postcode: values.otherPostcode,
                  };

              await create(values.idNumber, {
                ID: shareholder?.ID || null,
                ShareholderType: 'individual_shareholder',
                Identifiers: values.idNumber
                  ? [
                      {
                        ID: idNumber?.ID ?? null,
                        System: 'regdirect.com/identifiers/id-number',
                        Value: values.idNumber,
                        Type: 'ID Number',
                      },
                    ]
                  : [],
                Contacts: [
                  {
                    ID: phoneNumber?.ID ?? null,
                    System: 'regdirect.com/contacts/phone-number',
                    Value: values.phoneNumber,
                  },
                  {
                    ID: email?.ID ?? null,
                    System: 'regdirect.com/contacts/email',
                    Value: values.emailAddress,
                  },
                ],
                Addresses: [residentialAddress, otherAddress],
                IndividualShareholderFields: {
                  UserID:
                    shareholder !== undefined
                      ? shareholder.IndividualShareholderFields.UserID || null
                      : user?.ID || null,
                  Name: {
                    ID: name?.ID ?? null,
                    Use: 'usual',
                    Given: values.firstName,
                    Family: values.lastName,
                    Prefix: null,
                    Suffix: null,
                  },
                },
              });
              notifications.show({
                message: 'Individual shareholder saved successfully',
                color: 'green',
              });
              onComplete && onComplete();
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {step > 1 && shareholderId === undefined && (
        <Button justify="end" style={{ marginBottom: '20px' }} onClick={handleBack}>
          Back to email
        </Button>
      )}
      {renderStepContent()}
    </div>
  );
};
