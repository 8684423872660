import { Box, Title } from '@mantine/core';
import { ErrorAlert } from '../../shared/error-alert';
import { Table } from '../../shared/table';
import { useShareClasses } from './use-share-classes';

export const ShareCapitalSummary = ({ companyId }: { companyId?: string }) => {
  const { shareClasses, isLoading, error } = useShareClasses(companyId);

  const totalShares = shareClasses?.reduce((acc, shareClass) => acc + shareClass.TotalShares, 0);
  const totalIssued = shareClasses?.reduce(
    (acc, shareClass) =>
      acc +
      shareClass.ShareAllocations.reduce((acc, allocation, index) => {
        return acc + allocation.TotalIssued;
      }, 0),
    0
  );

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <Box my="lg">
      <Title order={4}>Shares capital</Title>
      <Table
        isLoading={isLoading}
        isEmpty={!shareClasses || !shareClasses || shareClasses?.length === 0}
        data={[
          {
            totalShares,
            totalIssued,
          },
        ]}
        columns={[
          {
            key: 'totalIssued',
            name: 'Issued',
          },
          {
            key: 'totalShares',
            name: 'Authorised',
          },
        ]}
      />
    </Box>
  );
};
