import { Modal } from '@mantine/core';
import { TransferSharesForm } from './transfer-shares-form';

export const TransferSharesModal = ({
  isOpen,
  companyId,
  onClose,
  onComplete,
  fromShareholderId,
}: {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  companyId?: string;
  fromShareholderId: string | null;
}) => {
  if (!companyId || !fromShareholderId || !isOpen) return null;
  return (
    <Modal size="lg" opened={isOpen} onClose={onClose} title="Transfer shares" centered>
      <TransferSharesForm
        companyId={companyId}
        fromShareholderId={fromShareholderId}
        onComplete={onComplete}
      />
    </Modal>
  );
};
