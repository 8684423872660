import { Card, Image, Text, Group, Badge, Button } from '@mantine/core';
import { useState } from 'react';
import classes from './listing-card.module.css';
import { Listing } from '../../types';
import { EnquiryModal } from './enquiry-modal';

export function ListingCard({ listing }: { listing: Listing }) {
  const [showEnquiryModal, setShowEnquiryModal] = useState(false);
  return (
    <>
      <Card withBorder radius="sm" p="sm">
        <Card.Section>
          <Image
            fallbackSrc="/android-chrome-512x512.png"
            src={`/countries/${listing.CountryCode.toLowerCase()}.png`}
            alt={listing.Name}
            height={180}
          />
        </Card.Section>
        <Card.Section className={classes.section} mt="md">
          <Text fz="lg" fw={500}>
            {listing.Name}
          </Text>
          <Text fz="sm" mt="xs">
            {listing.Description}
          </Text>
          <Text mt="md" size="xl" fw={700}>
            {listing.Currency} {listing.Price}
          </Text>
          <Group mt="sm" gap="xs">
            <Badge variant="default" tt="none" leftSection="Address:">
              {listing.Address}
            </Badge>
            {listing.Type && (
              <Badge variant="default" tt="none" leftSection="Type:">
                {listing.Type}
              </Badge>
            )}
            {listing.Industry && (
              <Badge variant="default" tt="none" leftSection="Industry:">
                {listing.Industry}
              </Badge>
            )}
            {listing.BusinessStatus && (
              <Badge variant="default" tt="none" leftSection="Status:">
                {listing.BusinessStatus}
              </Badge>
            )}
            {listing.Monitization && (
              <Badge variant="default" tt="none" leftSection="Monitization:">
                {listing.Monitization}
              </Badge>
            )}
          </Group>
        </Card.Section>
        <Group mt="sm">
          <Button radius="sm" style={{ flex: 1 }} onClick={() => setShowEnquiryModal(true)}>
            Enquire
          </Button>
        </Group>
      </Card>
      <EnquiryModal
        listingId={listing.ID}
        title={listing.Name}
        opened={showEnquiryModal}
        close={() => setShowEnquiryModal(false)}
        onComplete={() => {
          setShowEnquiryModal(false);
        }}
      />
    </>
  );
}
