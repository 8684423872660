import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { validation } from '../../utils/validation';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { useShareholders } from './use-shareholders';
import { FormLoader } from '../../shared/form-loader';
import { useShareholderAllocations } from './use-shareholder-allocations';
import { useShareClasses } from './use-share-classes';

export function TransferSharesForm({
  onComplete,
  companyId,
  fromShareholderId,
}: {
  onComplete?: () => void;
  companyId: string;
  fromShareholderId: string;
}) {
  const { shareholders } = useShareholders(companyId);
  const { shareClasses, transfer } = useShareClasses(companyId);
  const { shareAllocations } = useShareholderAllocations(companyId, fromShareholderId);

  const schema = useMemo(() => {
    if (!shareholders || !shareAllocations || !shareClasses) return null;
    const schema: Schema = [
      {
        type: FieldType.Select,
        name: 'originalShareAllocation',
        label: 'Original share allocation',
        initialValue: '',
        options: shareAllocations.map((shareAllocation) => ({
          value: shareAllocation.ID,
          label: `${shareAllocation.FileDisplayName} - (${shareAllocation.TotalIssued} shares)`,
        })),
        validate: validation.required,
      },
      {
        type: FieldType.Select,
        name: 'toShareholderID',
        label: 'To shareholder',
        initialValue: '',
        options: shareholders.map((shareholder) => {
          const name =
            shareholder.ShareholderType === 'individual_shareholder'
              ? `${shareholder.IndividualShareholderFields.Name.Given} ${shareholder.IndividualShareholderFields.Name.Family}`
              : shareholder.CompanyShareholderFields.Name ||
                shareholder.TrustShareholderFields.Name;
          return {
            value: shareholder.ID,
            label: name,
          };
        }),
        validate: validation.required,
      },
      {
        type: FieldType.Date,
        name: 'date',
        label: 'Date',
        initialValue: new Date(),
        validate: validation.required,
      },
      {
        type: FieldType.Number,
        name: 'price',
        label: 'Price',
        initialValue: 0,
        validate: validation.required,
        prefix: 'R',
      },
      {
        type: FieldType.Number,
        name: 'totalTransferring',
        label: 'Total transferring',
        initialValue: 0,
        validate: validation.required,
      },
    ];
    return schema;
  }, [shareholders, shareAllocations, shareClasses]);

  if (!schema) {
    return <FormLoader />;
  }

  return (
    <DynamicForm
      schema={schema}
      onSubmit={async (values) => {
        if (!companyId) return;
        await transfer(values);
        notifications.show({
          message: 'Shares tranferred successfully',
          color: 'green',
        });
        if (onComplete) onComplete();
      }}
    />
  );
}
