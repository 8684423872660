import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ProtectedLayout } from './shared/layout';
import { Marketplace } from './features/marketplace';
import { Home } from './features/home/home';
import { ManageListings } from './features/marketplace/manage-listings';
import { ActiveUsers } from './features/users/active-users';
import { UnverifiedUsers } from './features/users/unverified-users';
import { Companies } from './features/companies';
import { Company } from './features/companies/company';
import { ShareSummary } from './features/companies/share-summary';
import { Enquiries } from './features/marketplace/enquiries';
import { AppointAuditor } from './features/companies/appoint-auditor';
import { ResignAuditor } from './features/companies/resign-auditor';
import { Documents } from './features/companies/documents';
import { ShareholderShareAllocations } from './features/companies/shareholder-share-allocations';
import { CreateCompany } from './features/companies/create-company';
import { ResignOfficer } from './features/companies/resign-officer';
import { RequestedChanges } from './features/requested-changes';
import { AppointOfficer } from './features/companies/appoint-officer';
import { AppointDirector } from './features/companies/appoint-director';
import { ResignDirector } from './features/companies/resign-director';
import { RequestedChange } from './features/requested-changes/change';
import { CompanyChanges } from './features/companies/company-changes';
import { StripeSuccess } from './features/billing/stripe-success';
import { StripeCancel } from './features/billing/stripe-cancel';
import { AppointSecretary } from './features/companies/appoint-secretary';
import { ResignSecretary } from './features/companies/resign-secretary';

const router = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedLayout />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/marketplace', element: <Marketplace /> },
      { path: '/marketplace/manage', element: <ManageListings /> },
      { path: '/marketplace/manage/:id/enquiries', element: <Enquiries /> },
      { path: '/marketplace/:id', element: <Marketplace /> },

      { path: '/users', element: <ActiveUsers /> },
      { path: '/users/unverified', element: <UnverifiedUsers /> },

      { path: '/requested-changes', element: <RequestedChanges /> },
      { path: '/requested-changes/:id', element: <RequestedChange /> },

      { path: '/companies', element: <Companies /> },
      { path: '/companies/:id', element: <Company /> },
      { path: '/companies/create', element: <CreateCompany /> },
      { path: '/companies/:id/documents', element: <Documents /> },
      {
        path: '/companies/:id/shareholder-share-allocations/:shareholderId',
        element: <ShareholderShareAllocations />,
      },
      { path: '/companies/:id/appoint-director', element: <AppointDirector /> },
      { path: '/companies/:id/resign-director/:directorId', element: <ResignDirector /> },
      { path: '/companies/:id/appoint-auditor', element: <AppointAuditor /> },
      { path: '/companies/:id/resign-auditor/:auditorId', element: <ResignAuditor /> },
      { path: '/companies/:id/appoint-officer', element: <AppointOfficer /> },
      { path: '/companies/:id/resign-officer/:directorId', element: <ResignOfficer /> },
      { path: '/companies/:id/appoint-secretary', element: <AppointSecretary /> },
      { path: '/companies/:id/resign-secretary/:directorId', element: <ResignSecretary /> },
      { path: '/companies/:id/share-summary', element: <ShareSummary /> },
      { path: '/companies/:id/requested-changes', element: <CompanyChanges /> },
      { path: '/companies/:id/requested-changes/:changeId', element: <RequestedChange /> },
      { path: '/billing/stripe/success', element: <StripeSuccess /> },
      { path: '/billing/stripe/cancel', element: <StripeCancel /> },
    ],
  },
]);

export function Routes() {
  return <RouterProvider router={router} />;
}
