import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  Group,
  Menu,
  Text,
  UnstyledButton,
  useMantineColorScheme,
} from '@mantine/core';
import { LuChevronRight, LuLogOut, LuMoon, LuSunMedium, LuUserCog } from 'react-icons/lu';

import { useState } from 'react';
import classes from './user-button.module.css';
import { UserModal } from '../features/users/user-modal';

export function UserButton({ userId, isAdmin }: { userId?: string | null; isAdmin: boolean }) {
  const [showUserModal, setShowUserModal] = useState(false);
  const { user, logout } = useAuth0();
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  return (
    <>
      <Menu shadow="md" width={200} position="right">
        <Menu.Target>
          <UnstyledButton p="sm" className={classes.container}>
            <Group align="center">
              <Avatar radius="xl" size="md" src={user?.picture} />
              <Box>
                <Text size="sm" fw={500}>
                  {user?.nickname} {isAdmin && '(Admin)'}
                </Text>
                <Text size="xs" c="dimmed">
                  {user?.email}
                </Text>
              </Box>
            </Group>
            <LuChevronRight size={16} />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          {userId && (
            <Menu.Item leftSection={<LuUserCog size={16} />} onClick={() => setShowUserModal(true)}>
              Profile
            </Menu.Item>
          )}
          <Menu.Item
            leftSection={colorScheme === 'dark' ? <LuSunMedium size={16} /> : <LuMoon size={16} />}
            onClick={() => {
              setColorScheme(colorScheme === 'dark' ? 'light' : 'dark');
            }}
          >
            {colorScheme === 'dark' ? 'Switch to light theme' : 'Switch to dark theme'}
          </Menu.Item>
          <Menu.Item
            onClick={async () =>
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              })
            }
            leftSection={<LuLogOut size={16} />}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <UserModal isOpen={showUserModal} userId={userId} onClose={() => setShowUserModal(false)} />
    </>
  );
}
