import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { validation } from '../../utils/validation';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { useShareholders } from './use-shareholders';
import { FormLoader } from '../../shared/form-loader';
import { useShareClasses } from './use-share-classes';

export function AllotSharesForm({
  onComplete,
  companyId,
}: {
  onComplete?: () => void;
  companyId?: string;
}) {
  const { shareholders } = useShareholders(companyId);
  const { shareClasses, allot } = useShareClasses(companyId);

  type Value = string | boolean | number | Date;

  const schema = useMemo(() => {
    if (!shareholders || !shareClasses) return null;
    const schema: Schema = [
      {
        type: FieldType.Select,
        name: 'shareholderId',
        label: 'Shareholder',
        initialValue: '',
        options: shareholders.map((shareholder) => {
          const name =
            shareholder.ShareholderType === 'individual_shareholder'
              ? `${shareholder.IndividualShareholderFields.Name.Given} ${shareholder.IndividualShareholderFields.Name.Family}`
              : shareholder.CompanyShareholderFields.Name ||
                shareholder.TrustShareholderFields.Name;
          return {
            value: shareholder.ID,
            label: name,
          };
        }),
        required: true,
      },
      {
        type: FieldType.Date,
        name: 'dateCreated',
        label: 'Date Created',
        initialValue: new Date(),
        required: true,
      },
      {
        type: FieldType.Select,
        name: 'shareClassId',
        label: 'Share Class',
        initialValue: '',
        options: shareClasses.map((shareClass) => ({
          label: shareClass.Name,
          value: shareClass.ID,
        })),
        required: true,
      },
      {
        type: FieldType.Number,
        name: 'totalIssued',
        label: 'Total Issued',
        // disabled: true,
        initialValue: 0,
        validate: (value: string, allValues?: { [name: string]: Value }) => {
          if (Number(value) <= 0) return 'Total issued must be greater than 0';
          if (!allValues) return null;

          const shareClassID = allValues['shareClassId'];
          if (!shareClassID) return 'Please select a share class';

          const shareClass = shareClasses.find((sc) => sc.ID === shareClassID);
          if (!shareClass) return 'Invalid share class';
          const totalIssued = shareClass.ShareAllocations.reduce(
            (acc, allocation) => acc + allocation.TotalIssued,
            0
          );
          const totalAvaialble = shareClass.TotalShares - totalIssued;
          if (Number(value) > totalAvaialble)
            return `Total issued cannot be greater than ${totalAvaialble} available shares`;
          return null;
        },
      },
      {
        type: FieldType.Number,
        name: 'pricePerShare',
        label: 'Price Per Share',
        initialValue: 0,
        required: true,
        prefix: 'R',
      },
    ];
    return schema;
  }, [shareholders, shareClasses]);

  if (!schema) {
    return <FormLoader />;
  }

  return (
    <DynamicForm
      schema={schema}
      onSubmit={async (values) => {
        if (!companyId) return;
        await allot({
          ShareholderID: values.shareholderId,
          ShareClassID: values.shareClassId,
          CompanyID: companyId,
          TotalIssued: values.totalIssued,
          PricePerShare: values.pricePerShare,
          IssueDate: values.dateCreated.toISOString(),
        });
        notifications.show({
          message: 'Shares allotted successfully',
          color: 'green',
        });
        if (onComplete) onComplete();
      }}
    />
  );
}
