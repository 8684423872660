import useSWR from 'swr';

export const useCompanyTypes = () =>
  useSWR<{
    data: {
      Description: string;
      ID: string;
      Suffix: string;
    }[];
  }>('/company-types');
