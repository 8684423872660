import useSWR from 'swr';
import { Shareholder } from '../../types';
import { deleteRequest } from '../../lib/api';

export const useShareholders = (companyId?: string) => {
  const { data, isLoading, mutate } = useSWR<{
    data: Shareholder[];
  }>(companyId ? `/companies/${companyId}/shareholders` : null);

  const removeShareholder = async (shareholderId: string, companyId: string) => {
    if (!companyId) return;
    await deleteRequest(`/companies/${companyId}/shareholders/${shareholderId}`);
  };

  return {
    shareholders: data?.data,
    isLoading,
    mutate,
    removeShareholder,
  };
};
