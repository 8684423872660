export const BASE_URL = 'https://api.regdirect.dev:443/v1';

export const fetcher: typeof fetch = (resource, init) =>
  fetch(`${BASE_URL}${resource}`, {
    ...init,
    headers: {
      ...init?.headers,
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    if (res.status === 500) {
      throw new Error('Internal server error');
    }
    return res.json();
  });

export async function post(resource: string, body?: FormData | any) {
  const result = await fetch(`${BASE_URL}${resource}`, {
    method: 'POST',
    body: body instanceof FormData ? body : JSON.stringify(body),
  });

  if (!result.ok) {
    throw new Error('Something went wrong');
  }

  // If body is not FormData, return json, else just return result
  return body instanceof FormData ? result : result.json();
}

export async function patch(resource: string, body?: any) {
  const result = await fetch(`${BASE_URL}${resource}`, {
    method: 'PATCH',
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!result.ok) {
    throw new Error('Something went wrong');
  }

  return result.json();
}

export async function put(resource: string, body?: any) {
  const result = await fetch(`${BASE_URL}${resource}`, {
    method: 'PUT',
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!result.ok) {
    throw new Error('Something went wrong');
  }

  return result.json();
}

export async function deleteRequest(resource: string) {
  const result = await fetch(`${BASE_URL}${resource}`, {
    method: 'DELETE',
  });

  if (!result.ok) {
    throw new Error('Something went wrong');
  }

  return result;
}
