import useSWR from 'swr';

export const useDirectorTypes = () =>
  useSWR<{
    data: {
      Code: string;
      Description: string;
      CcCmInd: string;
      ID: string;
    }[];
  }>('/director-types');
