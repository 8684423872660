import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import Providers from './providers';

Sentry.init({
  dsn: 'https://88320077c7e60c3d9f59ffd80692bd95@o4506789477154816.ingest.sentry.io/4506789478531072',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.3,
});

ReactDOM.createRoot(document.getElementById('root')!).render(<Providers />);
