import { Anchor, Breadcrumbs, Text, Title } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { Table } from '../../shared/table';
import { useCompany } from './use-company';
import { ErrorAlert } from '../../shared/error-alert';
import { useShareholder } from './use-shareholder';
import { useShareholderAllocations } from './use-shareholder-allocations';
import { fetcher } from '../../lib/api';

const EMPTY_ID = '00000000-0000-0000-0000-000000000000';

export function ShareholderShareAllocations() {
  const { id, shareholderId } = useParams<{ id: string; shareholderId: string }>();
  const { company } = useCompany(id);
  const { name } = useShareholder(id, shareholderId);
  const { isLoading, shareAllocations, error } = useShareholderAllocations(id!, shareholderId!);

  return (
    <>
      <Breadcrumbs mb="xl">
        <Anchor component={Link} to="/companies">
          Companies
        </Anchor>
        <Anchor component={Link} to={`/companies/${company?.ID}`}>
          {company?.Name ?? '-'}
        </Anchor>
        <Text>{name}</Text>
        <Text>Share certificates</Text>
      </Breadcrumbs>
      <Title order={3} mb="lg">
        Share certificates
      </Title>
      {error ? (
        <ErrorAlert error={error} />
      ) : (
        <Table
          isLoading={isLoading}
          isEmpty={shareAllocations?.length === 0}
          data={shareAllocations}
          columns={[
            {
              key: 'FileDisplayName',
              name: 'Name',
            },
            {
              key: 'IssueDate',
              name: 'Issue date',
              render: (current) => new Date(current.IssueDate).toDateString(),
            },
            {
              key: 'TotalIssued',
              name: 'Total issued',
            },
            {
              key: 'PricePerShare',
              name: 'Price per share',
            },
          ]}
          rowActions={(row) =>
            row.CertificateID !== EMPTY_ID
              ? [
                  {
                    label: 'Download certificate',
                    onClick: async () => {
                      try {
                        notifications.show({
                          loading: true,
                          message: 'Preparing download...',
                          color: 'blue',
                          autoClose: true,
                        });
                        const result = (await fetcher(`/documents/${row.CertificateID}`)) as any;
                        const url = result.document.SignedURL;
                        window.open(url, '_blank');
                      } catch (error) {
                        notifications.show({
                          message: 'Failed to download certificate',
                          color: 'red',
                        });
                      }
                    },
                  },
                ]
              : []
          }
        />
      )}
    </>
  );
}
