import { TextInput, rem, Button, Title, Anchor, Badge } from '@mantine/core';
import { LuSearch } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { Table } from '../../shared/table';
import { useCompanies } from './use-companies';
import { useActiveUser } from '../users/use-active-user';
import {Address, Identifier} from '../../types';
import { useUserCompanyRegistrationRequests } from './use-user-company-registration-requests';
import { useUserCompanies } from './use-user-companies';

export function Companies() {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { user, isAdmin } = useActiveUser();
  const { companies, isLoading, linkUserToCompany } = useCompanies({ search });
  const { companyRegistrationRequests, isLoading: companyRegistrationRequestsLoading } =
    useUserCompanyRegistrationRequests(user?.ID);
  const { companies: userCompanies, isLoading: userCompaniesLoading } = useUserCompanies({
    companyIDs: user?.CompanyIDs || [],
  });
  const isEmpty = companies?.length === 0;

  return (
    <>

      <Title order={2} mb="lg">
        Companies
      </Title>

      <Table
        actions={
          <>
            <TextInput
              placeholder="Search"
              aria-label="search"
              leftSection={<LuSearch size={20} />}
              miw={rem(300)}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
            <Button onClick={() => navigate('/companies/create')}>Create Company</Button>
          </>
        }
        isLoading={isLoading}
        isEmpty={isEmpty}
        rowActions={(row) => [
          {
            label: 'Link to profile',
            onClick: async () => {
              if (!user?.ID) return;
              try {
                await linkUserToCompany(user.ID, row.ID);
                notifications.show({
                  title: 'Success',
                  message: 'User linked to company',
                  color: 'green',
                });
              } catch (error) {
                notifications.show({
                  title: 'Error',
                  message: 'Failed to link user to company',
                  color: 'red',
                });
              }
            },
          },
          {
            label: 'View',
            onClick: () => navigate(`/companies/${row.ID}`),
          },
        ]}
        data={isAdmin ? companies : userCompanies}
        columns={[
          {
            key: 'EnterpriseNumber',
            name: 'Enterprise number',
            render: (row) =>
                row.Identifiers?.find((current: Identifier) => current.Type === 'Enterprise Number')
                    ?.Value || '-',
          },
          {
            key: 'Name',
            name: 'Name',
            render: (row) => (
              <Anchor component={Link} to={`/companies/${row.ID}`}>
                {row.Name}
              </Anchor>
            ),
          },
          {
            key: 'Type',
            name: 'Type',
            render: (row) => row.EntityType.Description,
          },
          {
            key: 'Country',
            name: 'Country',
            render: (row) =>
                row.Addresses?.find((current: Address) => current.Type === 'Registered')
                    ?.Country.Name || '-',
          },
          {
            key: 'Status',
            name: 'Status',
            render: (row) => row.EntityStatus.Description,
          },
          {
            key: 'TaxNumber',
            name: 'Tax number',
            render: (row) =>
              row.Identifiers?.find((current: Identifier) => current.Type === 'Tax Number')?.Value ||
              '-',
          },
        ]}
      />
      {companyRegistrationRequests?.length !== 0 && (
        <>
          <Title order={2} mb="lg">
            Pending Company Registrations
          </Title>
          <Table
            isLoading={companyRegistrationRequestsLoading}
            columns={[
              {
                key: 'Code',
                name: 'Code',
                render: (row) => row.ChangeType.Code,
              },
              {
                key: 'description',
                name: 'Description',
                render: (row) => (
                  <Anchor component={Link} to={`/requested-changes/${row.ID}`}>
                    {row.ChangeType.Description}
                  </Anchor>
                ),
              },
              {
                key: 'createdAt',
                name: 'Created at',
                render: (row) => new Date(row.CreatedAt).toDateString(),
              },
              {
                key: 'UpdatedAt',
                name: 'Last updated',
                render: (row) => new Date(row.UpdatedAt).toDateString(),
              },
              {
                key: 'status',
                name: 'Status',
                render: (row) => <Badge variant="default">{row.Status}</Badge>,
              },
            ]}
            data={companyRegistrationRequests}
          />
        </>
      )}
    </>
  );
}
