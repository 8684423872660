import { Button, Group, SimpleGrid, Skeleton, TextInput, Title, rem } from '@mantine/core';
import { useState } from 'react';
import { LuSearch } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { ListingCard } from './listing-card';
import { useListings } from './use-listings';

const CARD_HEIGHT = 340;
const Loading = () => (
  <>
    <Skeleton height={CARD_HEIGHT} />
    <Skeleton height={CARD_HEIGHT} />
    <Skeleton height={CARD_HEIGHT} />
    <Skeleton height={CARD_HEIGHT} />
    <Skeleton height={CARD_HEIGHT} />
    <Skeleton height={CARD_HEIGHT} />
    <Skeleton height={CARD_HEIGHT} />
    <Skeleton height={CARD_HEIGHT} />
    <Skeleton height={CARD_HEIGHT} />
  </>
);

export function Marketplace() {
  const [search, setSearch] = useState('');
  const { listings, isLoading } = useListings(search, 'approved');
  return (
    <>
      <Title order={2} mb="lg">
        Marketplace
      </Title>
      <Group gap="sm" align="center" justify="space-between" mb="lg">
        <TextInput
          placeholder="Search"
          aria-label="search"
          leftSection={<LuSearch size={20} />}
          miw={rem(300)}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <Group gap="xs">
          <Button variant="default" component={Link} to="/marketplace/manage">
            Manage listings
          </Button>
        </Group>
      </Group>
      <SimpleGrid cols={{ base: 1, sm: 1, md: 2, lg: 3 }}>
        {isLoading ? (
          <Loading />
        ) : (
          listings?.map((listing) => <ListingCard listing={listing} key={listing.ID} />)
        )}
      </SimpleGrid>
    </>
  );
}
