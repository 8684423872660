import useSWR from 'swr';
import { post } from '../../lib/api';
import { User } from '../../types';

export const useUnverifiedUsers = () => {
  const { data, isLoading, mutate } = useSWR<{
    data: User[];
  }>('/users/unverified');

  async function activeUser(userId: string) {
    await post(`/users/${userId}/activate`);
    await mutate();
  }

  return {
    unverifiedUsers: data?.data,
    isLoading,
    mutate,
    activeUser,
  };
};
