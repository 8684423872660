import { Skeleton, Table } from '@mantine/core';

export function TableLoadingRows({ columns, rows }: { columns: number; rows: number }) {
  return (
    <>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <Table.Tr key={rowIndex}>
          {Array.from({ length: columns }).map((__, columnIndex) => (
            <Table.Td key={columnIndex}>
              <Skeleton height={18} width={48} />
            </Table.Td>
          ))}
        </Table.Tr>
      ))}
    </>
  );
}
