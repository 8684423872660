import { useMemo } from 'react';
import { notifications } from '@mantine/notifications';
import { v4 as uuidv4 } from 'uuid';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { validation } from '../../utils/validation';
import { FormLoader } from '../../shared/form-loader';
import { useShareholder } from './use-shareholder';
import { Country } from '../../types';
import { useCountries } from '../../shared/use-countries';

export const CompanyTrustForm = ({
  onComplete,
  companyId,
  shareholderId,
}: {
  onComplete?: () => void;
  companyId?: string;
  shareholderId?: string | null;
}) => {
  const { create, shareholder } = useShareholder(companyId, shareholderId);
  const { data: countries } = useCountries();

  const address = shareholder?.Addresses?.find((current) => current.Type === 'Business');
  const taxNumber = shareholder?.Identifiers.find(
    (current) => current.System === 'regdirect.com/identifiers/tax-number'
  );
  const phoneNumber = shareholder?.Contacts.find(
    (current) => current.System === 'regdirect.com/contacts/phone-number'
  );
  const trustNumber = shareholder?.Identifiers.find(
    (current) => current.System === 'regdirect.com/identifiers/trust-number'
  );

  const schema = useMemo(() => {
    if (shareholderId && !shareholder) return null;
    if (!countries) return null;
    const schema: Schema = [
      {
        name: 'trustName',
        type: FieldType.Text,
        label: 'Trust Name',
        initialValue: shareholder?.TrustShareholderFields.Name ?? '',
        validate: validation.required,
      },
      {
        name: 'trustNumber',
        type: FieldType.Text,
        label: 'Trust Number',
        initialValue: trustNumber?.Value ?? '',
        validate: validation.required,
      },
      {
        name: 'incomeTaxNumber',
        type: FieldType.Text,
        label: 'Income Tax Number',
        initialValue: taxNumber?.Value ?? '',
        validate: validation.required,
      },
      {
        type: 'fieldset',
        label: 'Contact Information',
        fields: [
          {
            name: 'phoneNumber',
            type: FieldType.Text,
            label: 'Phone number',
            placeholder: '+27744190174',
            initialValue: phoneNumber?.Value ?? '',
            validate: validation.requiredPhoneNumber,
          },
        ],
      },
      {
        type: 'fieldset',
        label: 'Address',
        fields: [
          {
            name: 'physicalLine1',
            type: FieldType.Text,
            label: 'Street address line 1',
            initialValue: address?.Line1 ?? '',
            validate: validation.required,
          },
          {
            name: 'physicalLine2',
            type: FieldType.Text,
            label: 'Street address line 2',
            initialValue: address?.Line2 ?? '',
          },
          {
            name: 'physicalPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: address?.Postcode ?? '',
            validate: validation.required,
          },
          {
            name: 'physicalCountry',
            type: FieldType.Select,
            label: 'Country',
            initialValue:
              (address?.Country as Country)?.ID ??
              (countries.data.find((country) => country.Name === address?.Country) as Country)
                ?.ID ??
              '',
            options: countries.data.map((current) => ({
              value: current.ID,
              label: current.Name,
            })),
            required: true,
            validate: validation.required,
          },
        ],
      },
    ];
    return schema;
  }, [shareholder, shareholderId]);

  if (!schema) {
    return <FormLoader />;
  }
  return (
    <DynamicForm
      schema={schema}
      onSubmit={async (values) => {
        await create(values.trustNumber, {
          ID: shareholder?.ID || uuidv4.Nil,
          ShareholderType: 'trust_shareholder',
          Identifiers: [
            {
              System: 'regdirect.com/identifiers/trust-number',
              Value: values.trustNumber,
              Type: 'Trust Number',
              ID: trustNumber?.ID ? trustNumber?.ID : uuidv4.Nil,
            },
            {
              System: 'regdirect.com/identifiers/tax-number',
              Value: values.incomeTaxNumber,
              Type: 'Tax Number',
              ID: taxNumber?.ID ? taxNumber?.ID : uuidv4.Nil,
            },
          ],
          Contacts: [
            {
              System: 'regdirect.com/contacts/phone-number',
              Value: values.phoneNumber,
              ID: phoneNumber?.ID ? phoneNumber?.ID : uuidv4.Nil,
            },
          ],
          Addresses: [
            {
              Type: 'Business',
              Line1: values.physicalLine1,
              Country: {
                ID: values.physicalCountry,
              },
              Subdivision: null,
              Postcode: values.physicalPostcode,
              ID: address?.ID ? address?.ID : uuidv4.Nil,
            },
          ],
          TrustShareholderFields: {
            Name: values.trustName,
          },
        });
        notifications.show({
          message: 'Trust shareholder saved successfully',
          color: 'green',
        });
        onComplete && onComplete();
      }}
    />
  );
};
