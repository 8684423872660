import useSWR from 'swr';

export const useAuditTypes = () =>
  useSWR<{
    data: {
      Code: string;
      Description: string;
      CcCmInd: string;
      ID: string;
    }[];
  }>('/audit-types');
