import { useMemo, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { Button, Stack, Stepper, TextInput } from '@mantine/core';
import { DynamicForm, FieldType, Schema } from '../../shared/dynamic-form';
import { validation } from '../../utils/validation';
import { FormLoader } from '../../shared/form-loader';
import { useDirectors } from './use-directors';
import { useAuditStatuses } from '../../shared/use-audit-statuses';
import { useAuditTypes } from '../../shared/use-audit-types';
import { useProfessionalCodes } from '../../shared/use-professional-codes';
import { useAuditors } from './use-auditors';
import { Country, User } from '../../types';
import { useUsers } from '../users/use-users';
import { useBasket } from '../../shared/use-basket';
import { useCountries } from '../../shared/use-countries';
import { formatDirectorOptions } from './format-director-options';

export const AppointAuditorForm = ({
  onComplete,
  companyId,
}: {
  onComplete?: () => void;
  companyId?: string;
}) => {
  const [user, setUser] = useState<User | null>(null);
  const { appoint } = useAuditors();
  const { data: directors } = useDirectors(companyId);
  const { data: auditStatuses } = useAuditStatuses();
  const { data: auditTypes } = useAuditTypes();
  const { data: countries } = useCountries();
  const { addProduct, mutate: mutateBasket } = useBasket();
  const { data: professionalCodes } = useProfessionalCodes();
  const { searchUserByEmail } = useUsers();

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    Email: '',
    AuditorInfo: {}, // Placeholder for director information
  });

  const schema = useMemo(() => {
    if (!directors || !professionalCodes || !auditTypes || !auditStatuses || !user || !countries)
      return null;

    const idNumber = user.Identifiers?.find(
      (identifier) => identifier.System === 'regdirect.com/identifiers/id-number'
    );
    const address = user.Addresses?.find((address) => address.Type === 'Residential');

    const schema: Schema = [
      {
        name: 'DirectorID',
        type: FieldType.Select,
        label: 'Director actioning the appointment',
        initialValue: '',
        options: formatDirectorOptions(directors),
        required: true,
      },
      {
        type: 'fieldset',
        label: 'Auditor details',
        fields: [
          {
            name: 'GivenName',
            type: FieldType.Text,
            label: 'First name',
            initialValue: user?.Name?.Given ?? '',
            required: true,
          },
          {
            name: 'FamilyName',
            type: FieldType.Text,
            label: 'Second name',
            initialValue: user?.Name?.Family ?? '',
            required: true,
          },
          {
            name: 'IDNumber',
            type: FieldType.Text,
            label: 'ID number',
            initialValue: idNumber?.Value ?? '',
            validate: validation.requiredIdNumber,
            required: true,
          },
          {
            name: 'AuditTypeID',
            type: FieldType.Select,
            label: 'Auditor type',
            initialValue: '',
            options: auditTypes.data.map((current) => ({
              value: current.ID,
              label: `${current.Description} (${current.Code})`,
            })),
            required: true,
          },

          {
            name: 'AuditStatusID',
            type: FieldType.Select,
            label: 'Auditor status',
            initialValue: '',
            options: auditStatuses.data.map((current) => ({
              value: current.ID,
              label: `${current.Description} (${current.Code})`,
            })),
            required: true,
          },
          {
            name: 'ProfessionID',
            type: FieldType.Select,
            label: 'Profession',
            initialValue: '',
            options: professionalCodes.data.map((current) => ({
              value: current.ID,
              label: `${current.Description} (${current.Code})`,
            })),
            required: true,
          },
          {
            type: FieldType.Date,
            name: 'StartAt',
            label: 'Start at',
            initialValue: new Date(),
            required: true,
          },
          {
            type: FieldType.Date,
            name: 'ExpiresAt',
            label: 'Expires at',
            initialValue: new Date(),
            required: true,
          },
        ],
      },
      {
        type: 'fieldset',
        label: 'Address',
        fields: [
          {
            name: 'AddressLine1',
            type: FieldType.Text,
            label: 'Street address line 1',
            initialValue: address?.Line1 ?? '',
            required: true,
          },
          {
            name: 'AddressLine2',
            type: FieldType.Text,
            label: 'Street address line 2',
            initialValue: address?.Line2 ?? '',
          },
          {
            name: 'AddressPostcode',
            type: FieldType.Text,
            label: 'Postal code',
            initialValue: address?.Postcode ?? '',
            required: true,
          },
          {
            name: 'AddressCountryID',
            type: FieldType.Select,
            label: 'Country of origin',
            initialValue:
              (address?.Country as Country)?.ID ??
              (countries.data.find((country) => country.Name === address?.Country) as Country)?.ID,
            options: countries.data.map((current) => ({
              value: current.ID,
              label: current.Name,
            })),
            required: true,
          },
        ],
      },
    ];
    return schema;
  }, [directors, professionalCodes, countries, auditTypes, auditStatuses, user]);

  const fetchAndCheckUserExists = async (email: string) => {
    // Fetch user details based on ID number and country of origin
    const user = await searchUserByEmail(email);
    if (user.data.length > 0) {
      // Notificaiton to say user exists
      notifications.show({
        title: 'User Exists',
        message: 'A User with the desired email already exists',
        color: 'green',
      });
      setUser(user.data[0]);
    } else {
      setUser({} as User);
    }
    setStep((prev) => prev + 1);
  };

  const handleNext = () => {
    const { Email } = formData;
    fetchAndCheckUserExists(Email);
  };

  return (
    <Stepper active={step} onStepClick={setStep}>
      <Stepper.Step label="Email">
        <Stack gap="md">
          <TextInput
            label="Auditor Email"
            placeholder="john.smith@gmail.com"
            value={formData.Email}
            onChange={(event) => setFormData({ ...formData, Email: event.target.value })}
            required
          />
          <Button disabled={!formData.Email} maw={300} w="100%" mx="auto" onClick={handleNext}>
            Next
          </Button>
        </Stack>
      </Stepper.Step>
      <Stepper.Step label="Details" disabled={!user?.ID}>
        {!schema ? (
          <FormLoader />
        ) : (
          <DynamicForm
            schema={schema}
            onSubmit={async (values) => {
              try {
                const res = await appoint({
                  UserID: user?.ID || null,
                  CompanyID: companyId!,
                  DirectorID: values.DirectorID,
                  Name: values.GivenName + ' ' + values.FamilyName,
                  AuditTypeID: values.AuditTypeID,
                  AuditStatusID: values.AuditStatusID,
                  ProfessionID: values.ProfessionID,
                  StartAt: values.StartAt,
                  ExpiresAt: values.ExpiresAt,
                  IDNumber: values.IDNumber,
                  AddressLine1: values.AddressLine1,
                  AddressLine2: values.AddressLine2,
                  AddressCountryID: values.AddressCountryID,
                  AddressPostcode: values.AddressPostcode,
                });

                try {
                  addProduct({
                    ChangeID: res.data.ID,
                    ProductType: 4,
                    Quantity: 1,
                  });
                } catch (error) {
                  notifications.show({
                    title: 'Error',
                    message: 'Failed to add auditor appointment to basket',
                    color: 'red',
                  });
                  return;
                } finally {
                  mutateBasket();
                }
              } catch (error) {
                notifications.show({
                  title: 'Error',
                  message: 'Failed to submit auditor appointment request',
                  color: 'red',
                });
                return;
              }
              notifications.show({
                Title: 'Success',
                message: 'Auditor appointment request created successfully',
                color: 'green',
              });
              onComplete && onComplete();
            }}
          />
        )}
      </Stepper.Step>
    </Stepper>
  );
};
